import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Maybe } from 'types';


var getStatusMsg = function (code: number) {
  if (code === undefined) return
  switch (code) {
  case 1: return 'Minder dan' // 'Less than'
  case 2: return 'Ongeveer' // 'Approximately'
  case 3: return 'Langer dan' // 'Longer than'
  case 6: return 'Onbekende reden' // 'Unknown reason'
  case 7: return 'Onvoldoende geheugen' // 'Insufficient memory'
  case 8: return 'Geen servicemedewerkers' // 'No satisfying agents'
  case 9: return 'Ongeldige wachtrij' // 'Invalid queue'
  case 10: return 'Geen primair verbindingsserveradres' // 'No primary connection server address'
  case 11: return 'Geen gespreks-ID' //'No call id'
  case 12: return 'Ongeldige externe wachtrij' // 'Invalid external queue'
  case 13: return 'Unknown skill'
  case 14: return 'Stream already exists'
  case 15: return 'Call reference not found'
  case 16: return 'Call reference cannot be accepted by agent'
  case 17: return 'Geen verbinding beschikbaar' // 'No connection available'
  case 18: return  'Servicemedewerker geweigerd' //'Agent refused'
  case 104: return 'Callcenter bellen' // 'Calling call center'
  case 105: return 'Wachten op service medewerker' // 'Waiting for agent'
  case 106: return 'In call'
  case 107: return 'Niet verbonden' // 'Not connected'
  case 133: return 'Verbonden met callcenter' // 'Connected to call center'
  case 157: return 'Oproep in de wacht gezet' // 'Call placed on hold'
  case 165: return 'Wachten in de wachtrij' // 'Waiting in queue'
  default: return 'unknown'
  }
}

export const useEvolveIP = (
  connectionId: string,
  readOnly: boolean,
  saveMessage: (data:any) => void,
  restartConversation: () => void,
  onErrorOrClose: (event?: Event) => void,
) => {
  const [ep, setEp] = useState<any>();
  const [lastevent, setLastevent] = useState<any>(0);
  const [refetchInterval, setRefetchInterval] = useState<Maybe<number>>(10000);
  
  const getEvolveIPChatMessages = async (
    connectionId: string,
  ) => {
    var data = {
      command: 'getevents',
      Connection_ID: connectionId,
      Last_Event_ID: lastevent,
    }

    return await axios({
      url: 'https://chat.occ7.mtel.eu/scripts/ChatExtension.dll?',
      method: 'get',
      withCredentials: false,
      params: data,
    }).then(res => {return res.data}).catch((e) => {
      saveMessage({ response: { text:  'Er is iets fout gegaan bij het doorverbinden met een operator. Uw gesprek met Carmen wordt nu voortgezet.' }, source: 'api' });
      onErrorOrClose()
      //onErrorOrClose()
    });
  };

  const { data } = useQuery('evole_ip_messages', () => getEvolveIPChatMessages(connectionId),
    {
      enabled: ep ? true : false,
      refetchInterval,
    });

    
  useEffect(() => {

    function processEvent(data: any) {

      if (!data.Events || data.Events.length === 0) { // nothing new
        //console.log('processEvent', 'no new updates')
        //scheduleCheck(self, lastevent)
        return
      }
  
      for (var i in data.Events) {
        var event = data.Events[i]
        // console.log('processEvent', 'Processing ' + event.Command)
        // console.log('processEvent', 'Processing ' + event.Command)
        // console.log('processEvent', 'event: ', event)
  
        switch (event.Command.toLowerCase()) {
        case 'status':
          const status: any = {};
          // status.type = 'status'
          // status.timestamp = Date.now()
          // status.code = event.Status_Code
          // status.message = getStatusMsg(event.Status_Code)
          // status.infoCode = event.Additional_Information
          // status.infoMessage = getStatusMsg(status.infoCode)
          // status.estimatedTime = event.Estimated_Time
  
          setLastevent(event.Event_ID)
          if (status.code === 107) { /* not connected */
            restartConversation()
            onErrorOrClose()
            // chatEnded({
            //   enumerable: false,
            //   writable: false,
            //   configurable: false,
            //   value: true,
            // })
          }
          if(getStatusMsg(event.Status_Code) !== 'unknown'){
            saveMessage({ response: { separator: true, separator_text: getStatusMsg(event.Status_Code) }, source: 'evolveip' });
          }
          break
        case 'read':
          setLastevent(event.Event_ID)
          // msgCallback({
          //   type: 'received',
          //   timestamp: Date.now(),
          //   name:  event.Participant_Name,
          //   url:  event.Pushed_Url,
          //   message:  event.Message_Text,
          // }, self.history.length - 1)
          saveMessage({ response: { text:  event.Message_Text }, source: 'evolveip' });
          break
        case 'participantdisconnected': 
          setLastevent(event.Event_ID)
          restartConversation()
          onErrorOrClose()
          // self.disconnectHandler({
          //   type: 'disconnect',
          //   timestamp: Date.now(),
          //   name:  event.Participant_Name,
          // }, self.history.length - 1)
          break
        case 'error':
          // setLastevent(event.Event_ID)
          // restartConversation()
          // onErrorOrClose()
          
          //self.errorHandler(event.Status_Code, getStatusMsg(event.Status_Code))
          break
        case 'typingstatus':
          setLastevent(event.Event_ID)
          
          //self.agentTypingCallback(event.Message_Text === '1', event.Participant_Name)
          break
        default:
          //console.log('processEvent', 'Unhandled event: ', event)
          if (event.Event_ID !== undefined) setLastevent(event.Event_ID)
          break
        }
      }
      setRefetchInterval(1000)
    }

    
    if(data){
      processEvent(data)
    }
  },[data, onErrorOrClose, restartConversation, saveMessage]);



  useEffect(() => {

    if (connectionId && !readOnly) {
      setEp(true);
    }else{
      setEp(false);
    }
  }, [connectionId, readOnly]);

  return {
    ep,
  };
};
