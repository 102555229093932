import React from 'react';
import { Card } from '@mui/material';

export const Tooltip = (props: any) => {
  const { active, payload, dimensionsMap = {}, metricsMap = {}, xAxisLabelFormatter = (v: any) => v } = props;
  const dateDimensionKey = Object.keys(dimensionsMap).find((key: any) => dimensionsMap?.[key]?.type === 'date');
  const [firstPayload] = payload || [{}];
  if (active) {
    if (!!payload) {
      return (
        <Card>
          <div style={{ padding: 10 }}>
            <div style={{ textTransform: 'capitalize' }}>{dimensionsMap?.[dateDimensionKey || '']?.label}: {xAxisLabelFormatter(firstPayload?.payload?.[dateDimensionKey || ''])}</div>
            {payload.map((props: any, i: number) => {
              const { dataKey, value, color } = props;
              const dimension = dimensionsMap[dataKey];
              const metric = metricsMap[dataKey];
              if (dimension) {
                return <div key={i} style={{ color }}>{dimension.label}: {value}</div>
              }
              if (metric) {
                //FIXME: allow formatting dynamically
                if(metric?.label === 'Savings') {
                  return <div key={i} style={{ color }}>{metric.label}: {value} €</div>
                }
                return <div key={i} style={{ color }}>{metric.label}: {value}</div>
              }
              return null;
            })}
          </div>
        </Card>
      )
    }
  }
  return null;
}
