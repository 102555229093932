import React, { FC, useContext } from 'react';
import { Box, Grid, IconButton, Paper, Tooltip, useTheme } from '@mui/material';

import { LayoutContext, LocalizationContext } from 'contexts';
import coupleHello from 'shared/img/illustrations/couplehello.jpeg'
import { LanguageSelect } from 'shared/components/form/LanguageSelect/LanguageSelect';

export const LoggedOutLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {

  const theme = useTheme();
  const { ToggleModeIcon, toggleMode } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Grid
      container
      sx={theme => ({
        position: 'relative',
        overflow: 'visible',
        padding: theme.spacing(10),
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...(theme.palette.mode === 'light' && {
          backgroundColor: '#fff',
          backgroundImage: `url(${coupleHello})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundAttachment: 'fixed',
        }),
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          padding: theme.spacing(2),
          paddingTop: theme.spacing(10),
          backgroundPosition: 'bottom center',
        },
      })}
    >
      <Grid
        item 
        sx={theme => ({
          minWidth: 500,
          [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
          },
        })}  
      >
        <Paper
          sx={theme => ({
            ...(theme.palette.mode === 'light' && {
              background: 'rgba(252, 252, 255, 0.9)',
            }),
          })}
        >
          <Box p={4}>
            {children}
          </Box>
        </Paper>
      </Grid>
      <Box position="absolute" top={theme.spacing(2)} right={theme.spacing(2)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <LanguageSelect/>
          </Grid>
          <Grid item>
            <Tooltip title={dictionary.toggleLighDarkMode}>
              <IconButton onClick={toggleMode}>
                <ToggleModeIcon color="primary"/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
