import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const SavingsIcon = ({ color, className, ...props }: any) => {

  const { iconColor } = useIcons({ color });

  return (
    <span className={className} style={{ 'color': iconColor }}>€</span>
  )
}
