import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { Build } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useDigitalEmployees } from 'shared/hooks/useDigitalEmployees';
import { GradientBox } from 'shared/components/layout/GradientBox/GradientBox';
import { EmployeeCard } from './components/EmployeeCard';

export const Home = () => {

  useScrollTop();
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { partner } = useContext(UserContext);
  const { digitalEmployees } = useDigitalEmployees();


  return (
    <Box m={3}>
      <Grid container spacing={3}>
        {digitalEmployees.map((employee) => (
          <Grid item key={employee.slug} xs={12} sm={4}>
            <EmployeeCard {...employee} />
          </Grid>
        ))}
        {!partner && (
          <Grid item xs={12} sm={4}>
            <GradientBox
              p={4}
              component={Paper}
              onClick={() => navigate(routes.requestEmployee)}
              sx={{
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '0 0 10px 0px #00000080',
                  transitionDuration: '200ms',
                },
              }}
            >
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} style={{ overflow: 'hidden' }}>
                  <Typography variant="h4" color="primary"><b>{dictionary.buildYourOwnDigitalEmployee}</b></Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">{dictionary.buildYourOwnDigitalEmployeeDescription}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: '#ececec' }}/>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 'auto' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => navigate(routes.requestEmployee)}
                    endIcon={<Build/>}
                    fullWidth
                  >
                    {dictionary.buildYourOwn}
                  </Button>
                </Grid>
              </Grid>
            </GradientBox>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
