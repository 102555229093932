import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';

type Options = {
  enabled: boolean;
}

type Params = {
  slug: string;
  agent: string;
}

export const useDigitalEmployeeVariables = (params: Params, options?: Options) => {

  const { data: { data: { results: variables = [] } = {} } = {}, status: variablesStatus } = useQuery(
    [cacheKeys.getDigitalEmployeeResponseVariable, { slug: params.slug, agent: params.agent }],
    () => digitalEmployeeClient.getResponseVariables(params.slug, params.agent),
    {
      enabled: options?.enabled,
    },
  )

  return {
    variables,
    variablesStatus,
  }
}
