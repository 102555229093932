import { useCallback, useContext } from 'react';
import { trackLogIn } from 'tracking/tracking';
import { UserContext } from 'contexts';


export const useSignin = () => {

  const { login: userLogin, ssoLogin, logout } = useContext(UserContext);

  const signIn = useCallback(async (email: string, password: string) => {
    try {
      const result = await userLogin(email, password);
      trackLogIn();
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }, [userLogin]);

  const signInWithSSO = useCallback(async (provider: string) => {
    const result = await ssoLogin(provider);
    if(result.success !== true) {
      logout();
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      trackLogIn();
    }
  }, [ssoLogin, logout]);

  return {
    signIn,
    signInWithSSO,
  };
};
