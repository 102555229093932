export const pieChartData = {
  'type': 'pie',
  'dimensions': [
    {
      'key': 'group',
      'label': 'Category',
    },
  ],
  'metrics': [
    {
      'chart': 'pie',
      'key': 'count',
      'label': 'Category',
      'stack_id': 1,
    },
  ],
  'values': [
    {
      'dimensions': {
        'group': 5,
      },
      'metrics': {
        'count': 186540,
        'transaction_count': 133,
      },
    },
    {
      'dimensions': {
        'group': 4,
      },
      'metrics': {
        'count': 38940,
        'transaction_count': 40,
      },
    },
    {
      'dimensions': {
        'group': 3,
      },
      'metrics': {
        'count': 378940,
        'transaction_count': 588,
      },
    },
    {
      'dimensions': {
        'group': 2,
      },
      'metrics': {
        'count': 74474,
        'transaction_count': 30,
      },
    },
    {
      'dimensions': {
        'group': 1,
      },
      'metrics': {
        'count': 15553,
        'transaction_count': 31,
      },
    },
  ],
  'meta': {
    'count': 652,
    'count_previous_period': 441,
    'percentile_diff_periods_count': 32,
    'total_co2_kg': 468968.33,
    'total_co2_kg_previous_period': 317172,
    'percentile_diff_periods_total_co2_kg': 32,
    'avg_per_location': 78161.39,
    'avg_per_employee': 2131.67,
    'avg_per_transaction': 719.28,
  },
}

export const barChartData = {
  'type': 'bar',
  'dimensions': [
    {
      'key': 'group',
      'label': 'Category',
    },
    {
      'key': 'date_month',
      'type': 'date',
      'label': 'month',
      'axis': 'x',
      'aggregation': {
        'chart': 'bar',
        'key': 'group',
      },
    },
  ],
  'metrics': [
    {
      'chart': 'bar',
      'key': 'count',
      'label': 'Scope',
      'stack_id': 1,
    },
  ],
  'values': [
    {
      'dimensions': {
        'date_month': '2019-11',
        'group': 3,
      },
      'metrics': {
        'count': 5621.17,
        'transaction_count': 5,
      },
    },
    {
      'dimensions': {
        'date_month': '2019-12',
        'group': 3,
      },
      'metrics': {
        'count': 7487.86,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'date_month': '2019-12',
        'group': 2,
      },
      'metrics': {
        'count': 3405.66,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2019-12',
        'group': 1,
      },
      'metrics': {
        'count': 289.44,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-01',
        'group': 3,
      },
      'metrics': {
        'count': 9353.31,
        'transaction_count': 14,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-01',
        'group': 2,
      },
      'metrics': {
        'count': 3396.36,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-01',
        'group': 1,
      },
      'metrics': {
        'count': 621.09,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-02',
        'group': 3,
      },
      'metrics': {
        'count': 14034.45,
        'transaction_count': 26,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-02',
        'group': 2,
      },
      'metrics': {
        'count': 3420.54,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-02',
        'group': 1,
      },
      'metrics': {
        'count': 819.41,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-03',
        'group': 3,
      },
      'metrics': {
        'count': 6769.47,
        'transaction_count': 14,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-03',
        'group': 2,
      },
      'metrics': {
        'count': 2888.58,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-03',
        'group': 1,
      },
      'metrics': {
        'count': 412.72,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-04',
        'group': 3,
      },
      'metrics': {
        'count': 12716.13,
        'transaction_count': 20,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-04',
        'group': 2,
      },
      'metrics': {
        'count': 2178.06,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-04',
        'group': 1,
      },
      'metrics': {
        'count': 253.26,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-05',
        'group': 3,
      },
      'metrics': {
        'count': 14185.96,
        'transaction_count': 21,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-05',
        'group': 2,
      },
      'metrics': {
        'count': 2845.8,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-05',
        'group': 1,
      },
      'metrics': {
        'count': 668.66,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-06',
        'group': 3,
      },
      'metrics': {
        'count': 8541.71,
        'transaction_count': 17,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-06',
        'group': 2,
      },
      'metrics': {
        'count': 2085.06,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-06',
        'group': 1,
      },
      'metrics': {
        'count': 343.04,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-07',
        'group': 3,
      },
      'metrics': {
        'count': 10220.3,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-07',
        'group': 2,
      },
      'metrics': {
        'count': 2406.84,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-07',
        'group': 1,
      },
      'metrics': {
        'count': 52.26,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-08',
        'group': 3,
      },
      'metrics': {
        'count': 14544.8,
        'transaction_count': 23,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-08',
        'group': 2,
      },
      'metrics': {
        'count': 3509.82,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-08',
        'group': 1,
      },
      'metrics': {
        'count': 1591.3,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-09',
        'group': 3,
      },
      'metrics': {
        'count': 13437.86,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-09',
        'group': 2,
      },
      'metrics': {
        'count': 2321.28,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-09',
        'group': 1,
      },
      'metrics': {
        'count': 686.08,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-10',
        'group': 3,
      },
      'metrics': {
        'count': 15069.15,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-10',
        'group': 2,
      },
      'metrics': {
        'count': 2710.02,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-10',
        'group': 1,
      },
      'metrics': {
        'count': 332.32,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-11',
        'group': 3,
      },
      'metrics': {
        'count': 13813.83,
        'transaction_count': 23,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-11',
        'group': 2,
      },
      'metrics': {
        'count': 3308.94,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-11',
        'group': 1,
      },
      'metrics': {
        'count': 42.88,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-12',
        'group': 3,
      },
      'metrics': {
        'count': 7311.66,
        'transaction_count': 14,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-12',
        'group': 2,
      },
      'metrics': {
        'count': 2116.68,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2020-12',
        'group': 1,
      },
      'metrics': {
        'count': 266.66,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-01',
        'group': 3,
      },
      'metrics': {
        'count': 19793,
        'transaction_count': 31,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-01',
        'group': 2,
      },
      'metrics': {
        'count': 2207.82,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-01',
        'group': 1,
      },
      'metrics': {
        'count': 436.84,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-02',
        'group': 3,
      },
      'metrics': {
        'count': 13786.97,
        'transaction_count': 17,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-02',
        'group': 2,
      },
      'metrics': {
        'count': 4311.48,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-02',
        'group': 1,
      },
      'metrics': {
        'count': 813.38,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-03',
        'group': 3,
      },
      'metrics': {
        'count': 14323.87,
        'transaction_count': 23,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-03',
        'group': 2,
      },
      'metrics': {
        'count': 2289.66,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-03',
        'group': 1,
      },
      'metrics': {
        'count': 1524.92,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-04',
        'group': 3,
      },
      'metrics': {
        'count': 31389.64,
        'transaction_count': 34,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-04',
        'group': 2,
      },
      'metrics': {
        'count': 2698.86,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-04',
        'group': 1,
      },
      'metrics': {
        'count': 391.95,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-05',
        'group': 3,
      },
      'metrics': {
        'count': 25368.04,
        'transaction_count': 40,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-05',
        'group': 2,
      },
      'metrics': {
        'count': 3476.34,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-05',
        'group': 1,
      },
      'metrics': {
        'count': 194.3,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-06',
        'group': 3,
      },
      'metrics': {
        'count': 23522.65,
        'transaction_count': 36,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-06',
        'group': 2,
      },
      'metrics': {
        'count': 3463.32,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-06',
        'group': 1,
      },
      'metrics': {
        'count': 1108.18,
        'transaction_count': 3,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-07',
        'group': 3,
      },
      'metrics': {
        'count': 16969.03,
        'transaction_count': 21,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-07',
        'group': 2,
      },
      'metrics': {
        'count': 2395.68,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-07',
        'group': 1,
      },
      'metrics': {
        'count': 318.25,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-08',
        'group': 3,
      },
      'metrics': {
        'count': 23376.42,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-08',
        'group': 2,
      },
      'metrics': {
        'count': 2310.12,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-08',
        'group': 1,
      },
      'metrics': {
        'count': 819.41,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-09',
        'group': 3,
      },
      'metrics': {
        'count': 18537.81,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-09',
        'group': 2,
      },
      'metrics': {
        'count': 4125.48,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-09',
        'group': 1,
      },
      'metrics': {
        'count': 699.48,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-10',
        'group': 3,
      },
      'metrics': {
        'count': 13336.65,
        'transaction_count': 21,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-10',
        'group': 2,
      },
      'metrics': {
        'count': 3199.2,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-10',
        'group': 1,
      },
      'metrics': {
        'count': 1247.54,
        'transaction_count': 2,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-11',
        'group': 3,
      },
      'metrics': {
        'count': 7213.86,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-11',
        'group': 2,
      },
      'metrics': {
        'count': 2706.3,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-11',
        'group': 1,
      },
      'metrics': {
        'count': 213.06,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-12',
        'group': 3,
      },
      'metrics': {
        'count': 15584.13,
        'transaction_count': 30,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-12',
        'group': 2,
      },
      'metrics': {
        'count': 2027.4,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2021-12',
        'group': 1,
      },
      'metrics': {
        'count': 257.28,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2022-01',
        'group': 2,
      },
      'metrics': {
        'count': 2668.79,
        'transaction_count': 1,
      },
    },
    {
      'dimensions': {
        'date_month': '2022-01',
        'group': 3,
      },
      'metrics': {
        'count': 2630.75,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'date_month': '2022-01',
        'group': 1,
      },
      'metrics': {
        'count': 1150.05,
        'transaction_count': 1,
      },
    },
  ],
  'meta': {
    'count': 652,
    'count_previous_period': 441,
    'percentile_diff_periods_count': 32,
    'total_co2_kg': 468968.33,
    'total_co2_kg_previous_period': 317172,
    'percentile_diff_periods_total_co2_kg': 32,
    'avg_per_location': 78161.39,
    'avg_per_employee': 2131.67,
    'avg_per_transaction': 719.28,
  },
}

export const interactionsChartData = {
  'type': 'area',
  'dimensions': [
    {
      'key': 'group',
      'label': 'Category',
    },
    {
      'key': 'date_month',
      'type': 'date',
      'label': 'month',
      'axis': 'x',
      'aggregation': {
        'chart': 'area',
        'key': 'group',
      },
    },
  ],
  'metrics': [
    {
      'chart': 'area',
      'key': 'count',
      'label': 'Transactions',
      'stack_id': 1,
    },
  ],
  'values': [
    {
      'dimensions': {
        'group': 1,
        'date_month': '2019-11',
      },
      'metrics': {
        'count': 5621.17,
        'transaction_count': 5,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2019-11',
      },
      'metrics': {
        'count': 3621.17,
        'transaction_count': 5,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2019-12',
      },
      'metrics': {
        'count': 11182.96,
        'transaction_count': 19,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2019-12',
      },
      'metrics': {
        'count': 9182.96,
        'transaction_count': 19,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-01',
      },
      'metrics': {
        'count': 13370.76,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-01',
      },
      'metrics': {
        'count': 15370.76,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-02',
      },
      'metrics': {
        'count': 18274.4,
        'transaction_count': 28,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-02',
      },
      'metrics': {
        'count': 11274.4,
        'transaction_count': 28,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-03',
      },
      'metrics': {
        'count': 10070.77,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-03',
      },
      'metrics': {
        'count': 5070.77,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-04',
      },
      'metrics': {
        'count': 15147.45,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-04',
      },
      'metrics': {
        'count': 13147.45,
        'transaction_count': 22,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-05',
      },
      'metrics': {
        'count': 17700.42,
        'transaction_count': 23,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-05',
      },
      'metrics': {
        'count': 16700.42,
        'transaction_count': 23,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-06',
      },
      'metrics': {
        'count': 10969.81,
        'transaction_count': 19,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-06',
      },
      'metrics': {
        'count': 8969.81,
        'transaction_count': 19,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-07',
      },
      'metrics': {
        'count': 12679.4,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-07',
      },
      'metrics': {
        'count': 14679.4,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-08',
      },
      'metrics': {
        'count': 19645.92,
        'transaction_count': 26,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-08',
      },
      'metrics': {
        'count': 17645.92,
        'transaction_count': 26,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-09',
      },
      'metrics': {
        'count': 16445.22,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-09',
      },
      'metrics': {
        'count': 11445.22,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-10',
      },
      'metrics': {
        'count': 18111.49,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-10',
      },
      'metrics': {
        'count': 22111.49,
        'transaction_count': 24,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-11',
      },
      'metrics': {
        'count': 17165.66,
        'transaction_count': 26,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-11',
      },
      'metrics': {
        'count': 19165.66,
        'transaction_count': 26,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2020-12',
      },
      'metrics': {
        'count': 9695,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2020-12',
      },
      'metrics': {
        'count': 10695,
        'transaction_count': 16,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2021-01',
      },
      'metrics': {
        'count': 22437.66,
        'transaction_count': 33,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2021-01',
      },
      'metrics': {
        'count': 24437.66,
        'transaction_count': 33,
      },
    },
    {
      'dimensions': {
        'group': 1,
        'date_month': '2021-02',
      },
      'metrics': {
        'count': 18911.83,
        'transaction_count': 20,
      },
    },
    {
      'dimensions': {
        'group': 2,
        'date_month': '2021-02',
      },
      'metrics': {
        'count': 14911.83,
        'transaction_count': 20,
      },
    },
  ],
  'meta': {
    'count': 652,
    'count_previous_period': 441,
    'percentile_diff_periods_count': 32,
    'total_co2_kg': 468968.33,
    'total_co2_kg_previous_period': 317172,
    'percentile_diff_periods_total_co2_kg': 32,
    'avg_per_location': 78161.39,
    'avg_per_employee': 2131.67,
    'avg_per_transaction': 719.28,
  },
}

export const categoryNames = ['Sick leave', 'Paid leave', 'Payroll', 'Bonus', 'Administrative']
export const categoryDropNames = ['Unable to understand', 'Able to understand/ unable to answer', 'Wrong Language', 'Unable to identify user', 'Other']
