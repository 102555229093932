import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Menu,
  Avatar,
  useTheme,
} from '@mui/material';
import {
  Lock,
  Person,
} from '@mui/icons-material';

import { routes } from 'config';
import { LayoutContext, UserContext, LocalizationContext } from 'contexts';
import { LanguageSelect } from 'shared/components/form/LanguageSelect/LanguageSelect';


export const UserMenu = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const { user, logout, isLoggedIn } = useContext(UserContext);
  const { email = null, displayName = null, photoURL = null } = user || {} as any;
  const { dictionary } = useContext(LocalizationContext);
  const { mode, toggleMode, ToggleModeIcon } = useContext(LayoutContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItem = {
    padding: theme.spacing(2),
    minWidth: 280,
    '& svg': {
      fill: theme.palette.primary.light,
    },
  };

  return (
    <Fragment>
      <IconButton onClick={handleClick} edge="end">
        <Avatar alt={displayName} src={photoURL}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={theme => ({ marginTop: theme.spacing(5) })}
      >
        {isLoggedIn ? [(
          <div key="avatar" style={{ ...menuItem, textAlign: 'center' }}>
            <Avatar alt={displayName} src={photoURL} style={{ margin: 'auto', width: 60, height: 60, marginBottom: -8 }}/>
          </div>
        ), (
          <div key="main" style={menuItem}>
            <Typography variant="h6" color="primary" align="center">{displayName}</Typography>
            <Typography variant="body2" color="primary" align="center">{email}</Typography>
          </div>
        ), (
          <MenuItem key="my-account" sx={menuItem} onClick={() => [navigate(routes.myAccount), handleClose()]}>
            <ListItemIcon><Person/></ListItemIcon>
            <ListItemText color="primary">{dictionary.myAccount}</ListItemText>
          </MenuItem>
        ),(
          <MenuItem key="logout" sx={menuItem} onClick={() => [logout(), handleClose()]}>
            <ListItemIcon><Lock/></ListItemIcon>
            <ListItemText color="primary">{dictionary.logOut}</ListItemText>
          </MenuItem>
        ),(
          <MenuItem  sx={menuItem} onClick={() => [toggleMode(), handleClose()]}>
            <ListItemIcon><ToggleModeIcon/></ListItemIcon>
            <ListItemText color="primary">{mode === 'light' ? dictionary.switchToDarkMode : dictionary.switchToLightMode}</ListItemText>
          </MenuItem>
        ),(
          <div key="language" style={menuItem}>
            <LanguageSelect/>
          </div>
        )] : (
          <MenuItem sx={menuItem} onClick={() => [navigate(routes.login), handleClose()]}>
            <ListItemIcon><Lock/></ListItemIcon>
            <ListItemText color="primary">{dictionary.login}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
}
