import React, { FC, useContext } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

import { UserContext } from 'contexts';

const gradientPalette = {
  from: '#f5ae52',
  to: '#FC7869',
  fromDark: '#3b3b3b',
  toDark: '#222222',
}

export const GradientBox: FC<BoxProps> = ({ children, ...props }) => {

  const { partner } = useContext(UserContext);

  const theme = useTheme();

  return (
    <Box
      style={{
        background: `linear-gradient(90deg, ${gradientPalette.from} 0%, ${gradientPalette.to} 100%)`,
        ...(theme.palette.mode === 'dark' && {
          background: `linear-gradient(90deg, ${gradientPalette.fromDark} 0%, ${gradientPalette.toDark} 100%)`,
        }),
        ...partner && {
          background: `linear-gradient(90deg, ${partner.primaryColor} 0%, ${partner.secondaryColor} 100%)`,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
