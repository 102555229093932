import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const OutsideIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="14" width="6" height="1" rx="0.5" fill={iconColor}/>
      <rect x="20" width="6" height="1" rx="0.5" transform="rotate(90 20 0)" fill={iconColor}/>
      <rect x="19.9497" y="0.707108" width="17.1071" height="1" rx="0.5" transform="rotate(135 19.9497 0.707108)" fill={iconColor}/>
      <rect y="19" width="20" height="1" rx="0.5" fill={iconColor}/>
      <rect x="1" width="20" height="1" rx="0.5" transform="rotate(90 1 0)" fill={iconColor}/>
      <rect x="10" y="1" width="10" height="0.999999" rx="0.5" transform="rotate(-180 10 1)" fill={iconColor}/>
      <rect x="19" y="20" width="10" height="1" rx="0.5" transform="rotate(-90 19 20)" fill={iconColor}/>
    </svg>
  )
}
