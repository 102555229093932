import React, { FC, ReactNode, createContext, useContext, useState, useCallback, useMemo } from 'react';

import { LocalizationContext } from 'contexts';
import { useQueryState } from 'shared/hooks/useQueryState';
import { AsyncConfirmationDialog } from 'shared/components/dialog/AsyncConfirmationDialog/AsyncConfirmationDialog';

import { ContextProps, Dialog, DialogOptions, AsyncDialogProps, AsyncConfirmation } from './types';

export const defaultContext: ContextProps = {
  openDialog: () => {},
  closeDialog: () => {},
  asyncConfirmation: () => Promise.resolve(false),
};

const defaultAsyncConfirmationDialog = {
  title: null,
  content: null,
  confirmLabel: null,
  cancelLabel: null,
  show: false,
};

export const DialogContext = createContext(defaultContext);

export const DialogContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [queryDialogs = [], setDialogs] = useQueryState<Array<Dialog>>('dialog');
  const [dialogOptions, setDialogOptions] = useState<Partial<Record<Dialog, DialogOptions>>>();

  const dialogs: Array<Dialog> = useMemo(() => {
    return typeof queryDialogs === 'string' ? [queryDialogs] : queryDialogs;
  }, [queryDialogs]);

  // eslint-disable-next-line
  const openedDialogs: Record<string, boolean> = useMemo(() => {
    return Object.values(Dialog)
      .filter(k => typeof k === 'string')
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: dialogs.includes(curr as unknown as Dialog),
        };
      }, {});
  }, [dialogs]);

  const openDialog = useCallback((dialog: Dialog, options?: DialogOptions) => {
    setDialogs([...dialogs, dialog]);
    setDialogOptions({
      ...dialogOptions,
      [dialog]: options,
    });
  }, [dialogs, setDialogs, dialogOptions]);

  const closeDialog = useCallback((dialog: Dialog) => {
    setDialogs(dialogs.filter(d => d !== dialog));

    const options = { ...dialogOptions };

    delete options[dialog];

    setDialogOptions(options);
  }, [dialogs, setDialogs, dialogOptions]);

  const [popup, setPopup] = useState<AsyncDialogProps>(defaultAsyncConfirmationDialog);
  const [onConfirmPopup, setOnConfirmPopup] = useState<any>(() => null);
  const [onCancelPopup, setOnCancelPopup] = useState<any>(() => null);
  const [onClosePopup, setOnClosePopup] = useState<any>(() => null);

  const asyncConfirmation: AsyncConfirmation = useCallback(({ title, content, confirmLabel, cancelLabel }) => {
    setPopup({
      show: true,
      title: title || dictionary.dialogs.defaultTitle,
      content: content || dictionary.dialogs.defaultContent,
      confirmLabel: confirmLabel || dictionary.dialogs.yes,
      cancelLabel: cancelLabel || dictionary.dialogs.no,
    });
    return new Promise((resolve) => {
      setOnConfirmPopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(true)]);
      setOnCancelPopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(false)]);
      setOnClosePopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(false)]);
    });
  }, [dictionary]);

  return (
    <DialogContext.Provider
      value={{
        openDialog,
        closeDialog,
        asyncConfirmation,
      }}
    >
      {children}

      <AsyncConfirmationDialog
        open={popup.show}
        onClose={onClosePopup}
        onConfirm={onConfirmPopup}
        onCancel={onCancelPopup}
        title={popup.title}
        content={popup.content}
        confirmLabel={popup.confirmLabel}
        cancelLabel={popup.cancelLabel}
      />
    </DialogContext.Provider>
  );
};
