import React, { useCallback, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Lock } from '@mui/icons-material';

import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';

export const BrowserSessionsForm = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { changesSaved, genericError } = useContext(LayoutContext);
  const [isSaving, setIsSaving] = useState(false);

  const onClick = useCallback(async () => {
    setIsSaving(true);
    try {
      const userConfirmed = await asyncConfirmation({ title: dictionary.auth.session.logoutConfirmation, content: dictionary.auth.session.logoutConfirmationMessage });
      if (userConfirmed) {
        await usersClient.logoutAllSessions();
        changesSaved()
      }
    } catch(e) {
      console.error(e);
      genericError();
    }
    setIsSaving(false);
  }, [dictionary, asyncConfirmation, genericError, changesSaved])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 300 }}>
          {dictionary.logoutFromAllSessionsDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<Lock/>}
          onClick={onClick}
          loading={isSaving}
        >
          {dictionary.logoutFromAllSessions}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
