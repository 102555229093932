import React, { useCallback, useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { UserCrud } from 'clients/users/userClient.types';

export const ProfileForm = () => {

  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { user } = useContext(UserContext);
  const { genericError, changesSaved } = useContext(LayoutContext);

  const { handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm<UserCrud>({
    defaultValues: user,
  })

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  const onSubmit = useCallback(async (data: UserCrud) => {
    try {
      if (!user?.id) {
        return;
      }
      await usersClient.updateUser({
        id: user.id,
        ...data,
        company: {
          ...data.company,
          // API does not accept nullable partner
          partner: undefined,
        },
      });
      await queryClient.invalidateQueries(cacheKeys.users.getMe)
      changesSaved();
    } catch(e) {
      console.error(e);
      genericError();
    }
  }, [queryClient, changesSaved, genericError, user])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        {[[
          ['firstName', dictionary.firstName, { required: dictionary.thisFieldIsRequired }],
          ['lastName', dictionary.lastName, { required: dictionary.thisFieldIsRequired }],
          ['jobTitle', dictionary.jobTitle, {}],
          ['phone', dictionary.phone, {}],
          ['company.name', dictionary.company, {}],
        ].map(([name, label, rules], i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Controller
              name={name as keyof typeof errors}
              // @ts-ignore
              rules={rules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  // @ts-ignore
                  label={label}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors?.[name as keyof typeof errors]}
                  // @ts-ignore
                  helperText={errors?.[name as keyof typeof errors]?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
        ))]}
        <Grid item xs={12} sm={6}>
          <TextField
            value={user?.email}
            label={dictionary.email}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            endIcon={<Save/>}
            loading={isSubmitting}
          >
            {dictionary.submit}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
