import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const FallIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="22.3857" y="20.4579" width="2.75541" height="28.9318" rx="1" transform="rotate(135 22.3857 20.4579)" fill={iconColor}/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2896 23C12.2896 23.5522 12.7373 24 13.2896 24L22.0632 24C22.0663 24 22.0693 24 22.0724 24L23 24C23.2762 24 23.5262 23.8881 23.7071 23.7071C23.7531 23.6611 23.7946 23.6107 23.831 23.5565C23.9377 23.3974 24 23.206 24 23L24 22.0723C24 22.0695 24 22.0666 24 22.0637L24 13.2895C24 12.7372 23.5523 12.2895 23 12.2895L22.0724 12.2895C21.5201 12.2895 21.0724 12.7372 21.0724 13.2895L21.0724 21.0723L13.2896 21.0723C12.7373 21.0723 12.2896 21.5201 12.2896 22.0723L12.2896 23Z"
        fill={iconColor}
      />
    </svg>
  )
}
