import React from 'react';
import {
  Box,
  ImageListItem,
  Link,
  Typography,
} from '@mui/material';

interface ChatImageProps {
  item: {
    name: string;
    description?: string;
    websiteLink: string;
    imageUrl?: string;
    price: string;
    quantity?: number;
    homeDeliveryStatus?: string;
    homeDeliveryText?: string;
  };
  key: number;
}

enum colorDelivery {
  HIGH_IN_STOCK = '#81c784',
  MEDIUM_IN_STOCK = '#ffb74d',
  OUT_OF_STOCK = '#e57373',
};

export const ChatImages = ({ item, key }: ChatImageProps) => {

  const { name, description, websiteLink, imageUrl, price, homeDeliveryStatus, homeDeliveryText } = item

  return (
    <ImageListItem
      key={key}
      sx={{
        padding: '8px',
      }}
    >
      <Link href={websiteLink} target="_blank">
        <img
          src={imageUrl}
          style={{
            minWidth: '200px',
            maxWidth: '250px',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          loading="lazy"
          alt={name}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography sx={{
            fontWeight: 700,
            textTransform: 'uppercase',
            textDecoration: 'underline',
          }}
          >
            {name}
          </Typography>
          <Typography sx={{
            fontWeight: 700,
          }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: '.6875rem',
                verticalAlign: 'text-top',
                lineHeight: .9,
                fontWeight: 700,
                marginLeft: 2,
              }}>
              €
            </Typography>
            {price}
          </Typography>
        </Box>
        <Typography>{description}</Typography>
        {homeDeliveryStatus && homeDeliveryText &&
          <Box display="flex" flexDirection="row" alignItems="center">
            <span style={{
              height: '10px',
              width: '10px',
              backgroundColor: `${colorDelivery[homeDeliveryStatus as keyof typeof colorDelivery]}`,
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 2,
            }}
            />
            <Typography>{homeDeliveryText}</Typography>
          </Box>
        }


      </Link>
    </ImageListItem>
  )
}