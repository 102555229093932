import { FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Tab,
  Grid,
} from '@mui/material';

import {
  TabContext,
  TabList,
  TabPanel,
} from '@mui/lab';

import { useTheme } from '@mui/material/styles';
import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { DigitalEmployee as DigitalEmployeeType } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import { useScrollTop } from 'shared/hooks/useScrollTop';

//Global Employee components
import { EmployeeResponseVariables } from 'views/LoggedIn/Employee/components/EmployeeResponseVariables';
import { EmployeeIntegrations } from 'views/LoggedIn/Employee/components/EmployeeIntegrations';

//Bot specific components
import { ConversationDashboard } from './components/ConversationDashboard';
import { MessagesDashboard } from './components/MessagesDashboard';

interface IDigitalEmployeeDashboard {
  digitalEmployee: DigitalEmployeeType;
}

const ChatBotEmployee: FC<IDigitalEmployeeDashboard> = ({ digitalEmployee }) => {

  useScrollTop();
  const theme = useTheme();
  const { slug = '', tab = 'conversations', conversationId = null } = useParams();
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  useEffect(() => {
    if (digitalEmployee?.webIntegration) {
      const script = document.createElement('script');
      script.src = `${window.location.origin}/chat-widget.js?employee=${slug}`;
      script.async = true;
      script.id = 'freeday-script';
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [slug, digitalEmployee])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(routes.employeeWithTab(slug, newValue))
  };

  return (
    <TabContext value={tab}>
      <Grid container direction="column" spacing={4} >
        <Grid item>
          <TabList value={tab} textColor="secondary" indicatorColor="secondary" aria-label="basic tabs example" onChange={handleTabChange}
            sx={{
              '& button': { fontSize: 'inherit', color: theme.palette.secondary.main, textTransform: 'inherit', fontWeight: '300' },
              '& button.Mui-selected': { fontWeight: '500' },
            }}
          >
            <Tab label={dictionary.conversations} value="conversations" />
            <Tab label={dictionary.messages} color="secondary" value="messages" />
            <Tab label={dictionary.integrations} color="secondary" value="integrations" />
            <Tab label={dictionary.settings} value="settings" />
          </TabList>
        </Grid>
        <Grid item>
          <TabPanel sx={{ p: 0 }} value="messages">
            <MessagesDashboard slug={slug} />
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="conversations">
            <ConversationDashboard slug={slug} conversationId={conversationId} />
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="settings">
            <EmployeeResponseVariables slug={slug} agent={digitalEmployee.dialogflowCxAgent?.id} />
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="integrations">
            <EmployeeIntegrations slug={slug} />
          </TabPanel>
        </Grid>
      </Grid>
    </TabContext>
  )
}

export default ChatBotEmployee
