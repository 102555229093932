import React, { useContext } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CardHeader,
  Container,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseOutlined, RotateLeft } from '@mui/icons-material';
import { chatFont } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';

import avatar from 'shared/img/avatars/jessy-full.png';

type Props = {
  digitalEmployee: DigitalEmployee;
  onRestartRequest: () => void;
};

export const ChatHeader: React.FC<Props> = ({ digitalEmployee, onRestartRequest }: any) => {
  const { dictionary } = useContext(LocalizationContext);
  const { partner } = useContext(UserContext);
  const  theme = useTheme();

  const onClose = (e:any) => {
    e.preventDefault();
    //const event = new CustomEvent('chat-widget.close', { detail: true });
    //window.parent.document.dispatchEvent(event);
    window.parent.postMessage('chat-widget.close', '*');
    
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth={false} sx={{ backgroundColor: partner?.headerBackgroundColor }}>
        <Toolbar disableGutters>
          <CardHeader
            sx={{
              flex: 1,
              padding: 0,
              color: partner?.headerTextColor,
            }}
            disableTypography
            avatar={
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
                color="success"
              >
                <Avatar
                  alt={`${digitalEmployee.firstName} ${digitalEmployee.lastName}`}
                  src={digitalEmployee.profilePicture || avatar}
                  sx={{ backgroundColor: partner?.widgetBackgroundColor }}
                />
              </Badge>
            }
            action={

              <Box mt={0.5}>
                <IconButton color="inherit" aria-label="restart" onClick={onRestartRequest}>
                  <RotateLeft />
                </IconButton>
                <IconButton color="inherit" aria-label="restart" onClick={onClose}>
                  <CloseOutlined />
                </IconButton>
              </Box>
            }
            title={
              <Typography
                variant="body1"
                sx={{ fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily }}
              >
                {digitalEmployee.firstName}
              </Typography>
            }
            subheader={
              <Typography
                variant="body2"
                sx={{ fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily }}
              >
                {dictionary.chat.header.online}
              </Typography>
            }
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
