import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { GetGenericChartsParams } from 'clients/digitalEmployee/digitalEmployeeClient.types';

type Options = {
  enabled: boolean;
}

export const useDigitalEmployeeCharts = (params: GetGenericChartsParams, options?: Options) => {

  const { status, data: { data: charts } = {}, error, refetch } = useQuery(
    [cacheKeys.getCharts, params],
    () => digitalEmployeeClient.getCharts(params),
    {
      enabled: options?.enabled,
    },
  )

  return {
    status,
    charts,
    error,
    refetch,
  }
}

export const useDigitalEmployeeCallsCharts = (params: GetGenericChartsParams, options?: Options) => {
 
  const { status, data: { data: charts } = {}, error, refetch } = useQuery(
    [cacheKeys.getCharts, params],
    () => digitalEmployeeClient.getCallsCharts(params),
    {
      enabled: options?.enabled,
    },
  )
  return {
    status,
    charts,
    error,
    refetch,
  }
}