import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const GrowthIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="20.4404" y="1.6127" width="2.75308" height="28.9073" rx="1" transform="rotate(45 20.4404 1.6127)" fill={iconColor}/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9795 11.7006C23.5318 11.7006 23.9795 11.2529 23.9795 10.7006L23.9795 1.93545C23.9795 1.93204 23.9795 1.92862 23.9795 1.92519L23.9795 1.00004C23.9795 0.657001 23.8068 0.354301 23.5436 0.174176C23.383 0.0642862 23.1888 7.61211e-06 22.9795 7.60754e-06L22.0543 7.58732e-06C22.0515 7.58726e-06 22.0487 1.90313e-05 22.0459 4.28731e-05L13.279 4.17278e-05C12.7267 4.17157e-05 12.279 0.447757 12.279 1.00004L12.279 1.92519C12.279 2.47747 12.7267 2.92519 13.279 2.92519L21.0543 2.92519L21.0543 10.7006C21.0543 11.2529 21.5021 11.7006 22.0543 11.7006L22.9795 11.7006Z"
        fill={iconColor}
      />
    </svg>
  )
}
