import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const DropoutIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="path-1-inside-1_221_1687" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.03296 0C0.480674 0 0.032959 0.447715 0.032959 1V9C0.032959 9.55229 0.480674 10 1.03296 10H9.02993L10.4428 11L13.0001 13L13.0001 9.25534C13.0215 9.17383 13.033 9.08825 13.033 9V1C13.033 0.447715 12.5852 0 12.033 0H1.03296Z"/>
      </mask>
      <path
        d="M9.02993 10L9.60765 9.18377L9.34802 9H9.02993V10ZM10.4428 11L11.0588 10.2123L11.04 10.1976L11.0205 10.1838L10.4428 11ZM13.0001 13L12.384 13.7877L14.0001 15.0516L14.0001 13L13.0001 13ZM13.0001 9.25534L12.033 9.00071L12.0001 9.12589L12.0001 9.25534L13.0001 9.25534ZM1.03296 1V1V-1C-0.0716107 -1 -0.967041 -0.104569 -0.967041 1H1.03296ZM1.03296 9V1H-0.967041V9H1.03296ZM1.03296 9H1.03296H-0.967041C-0.967041 10.1046 -0.0716104 11 1.03296 11V9ZM9.02993 9H1.03296V11H9.02993V9ZM8.45221 10.8162L9.86506 11.8162L11.0205 10.1838L9.60765 9.18377L8.45221 10.8162ZM9.82673 11.7877L12.384 13.7877L13.6161 12.2123L11.0588 10.2123L9.82673 11.7877ZM14.0001 13L14.0001 9.25534L12.0001 9.25534L12.0001 13L14.0001 13ZM12.033 9L12.033 9.00071L13.9671 9.50997C14.0103 9.34608 14.033 9.17494 14.033 9H12.033ZM12.033 1V9H14.033V1H12.033ZM12.033 1H14.033C14.033 -0.104569 13.1375 -1 12.033 -1V1ZM1.03296 1H12.033V-1H1.03296V1Z"
        fill={iconColor}
        mask="url(#path-1-inside-1_221_1687)"
      />
      <path d="M7.82837 4L4.99994 6.82843" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.82837 6.82843L4.99994 4" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
