import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const ReportsIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="1.18309" height="24" rx="0.591543" fill={iconColor}/>
      <rect x="23.6616" y="22.8" width="1.2" height="23.6617" rx="0.6" transform="rotate(90 23.6616 22.8)" fill={iconColor}/>
      <path
        d="M22.4331 3.23076L16.0998 18.6481L9.55798 8.99677L4.0833 20.658L3.01514 20.142L9.37132 6.60322L15.8435 16.1519L21.341 2.76923L22.4331 3.23076Z"
        fill-rule="evenodd"
        fill={iconColor}
      />
      <path
        d="M23.3614 10.1223L9.43122 18.1085L3.21021 13.6915L3.88866 12.7085L9.49852 16.6915L22.7793 9.07767L23.3614 10.1223Z"
        fill-rule="evenodd"
        fill={iconColor}
      />
      <path
        d="M22.4283 1.60795L19.4673 2.62073L19.8001 3.62184L21.7742 2.94661L22.4399 4.94883L23.4269 4.61124L22.4284 1.60791L22.4283 1.60795L22.4283 1.60795Z"
        fill-rule="evenodd"
        fill={iconColor}
      />
      <path
        d="M24 9.30603L21.0056 8.39999L20.7079 9.41241L22.7041 10.0164L22.1085 12.0413L23.1067 12.3433L23.7022 10.3184L23.7023 10.3184L24 9.30603Z"
        fill-rule="evenodd"
        fill={iconColor}
      />
    </svg>
  )
}
