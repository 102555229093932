import React, { useCallback, useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Alert } from '@mui/lab';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { firebaseClient } from 'clients/firebaseClient';
import { Logo } from 'shared/components/layout/Logo/Logo';


export const ResetPassword = () => {

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { dictionary } = useContext(LocalizationContext);
  const { control, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback(async ({ email }: { email: string }) => {
    try {
      await firebaseClient.auth.sendPasswordResetEmail(email)
      setSuccess(true);
    } catch(e: any) {
      const { code } = e;
      if(code === 'auth/user-not-found') {
        setError('email', { type: 'error', message: dictionary.notFound })
      }
      console.error(e);
      setSuccess(false);
    }
  }, [setError, dictionary]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Box m={2}>
            <Typography align="center"><Logo width={160} height={50}/></Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">{dictionary.resetPassword}</Typography>
        </Grid>
        {success && (
          <Grid item>
            <Alert severity="success">{dictionary.passwordResetEmailSent}</Alert>
          </Grid>
        )}
        <Grid item>
          <Controller
            name="email"
            control={control}
            rules={{ required: dictionary.thisFieldIsRequired }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label={dictionary.email}
                error={!!errors?.email}
                helperText={errors?.email?.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <br/><br/>
        <Grid item>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button size="large" type="button" variant="outlined" color="primary" onClick={() => navigate(routes.login)}>
                {dictionary.login}
              </Button>
            </Grid>
            <div style={{ marginLeft: 'auto' }}/>
            <Grid item>
              <Button size="large" type="submit" variant="contained" color="primary">
                {isSubmitting ? <ClipLoader color="#fff" size={24}/> : dictionary.reset}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
