import React, { Fragment, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { useSignin } from 'shared/hooks/auth/useSignin';

import { Logo } from 'shared/components/layout/Logo/Logo';


export const Login = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { control, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { signIn, signInWithSSO } = useSignin();

  const onSSOLogin = useCallback(async (provider: string) => {
    try {
      await signInWithSSO(provider);
    } catch (e: any) {
      // @ts-ignore
      setError('email', { type: 'error', message: dictionary.firebase.errors[e.error] || e.message });
    }
  }, [signInWithSSO, setError, dictionary]);

  const onSubmit = useCallback (async ({ email, password }: { email: string; password: string }) => {
    try {
      await signIn(email, password);
    } catch (e: any) {
      if(['auth/invalid-email','auth/user-not-found', 'auth/wrong-password'].includes(e.error)) {
        setError('email', { type: 'error', message: dictionary.auth.validations.emailOrPasswordWrong });
        setError('password', { type: 'error', message: dictionary.auth.validations.emailOrPasswordWrong });
      } else {
        setError('email', { type: 'error', message: dictionary.somethingWentWrong });
      }
    }
  }, [signIn, setError, dictionary]);

  return (
    <Fragment>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box m={2}>
                <Typography align="center"><Logo width={160} height={50}/></Typography>
              </Box>
            </Grid>
            <Grid item>
              <Controller
                name="email"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.email}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    name="email"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="password"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.password}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    name="password"
                    type="password"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Typography align="right">
                <Link to={routes.resetPassword}><u>{dictionary.resetPassword}</u></Link>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button size="large" variant="outlined" onClick={() => navigate(routes.signup)}>
                    {dictionary.signUp}
                  </Button>
                </Grid>
                <Box ml="auto"/>
                <Grid item>
                  <Button size="large" type="submit" variant="contained" color="primary">
                    {isSubmitting ? <ClipLoader color="#fff" size={24}/> : dictionary.login}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box
        sx={theme => ({
          marginTop: theme.spacing(4),
          textAlign: 'center',
          '& svg': {
            width: 40,
            height: 40,
          },
        })}
      >
        <Tooltip title={dictionary.loginWithGoogle}>
          <IconButton onClick={() => onSSOLogin('google')}>
            <img src="/google.svg" alt="Google" width={32} height={32} />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary.loginWithMicrosoft}>
          <IconButton onClick={() => onSSOLogin('microsoft')}>
            <img src="/microsoft.svg" alt="Microsoft" width={32} height={32} />
          </IconButton>
        </Tooltip>
      </Box>
    </Fragment>
  )
}
