import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const NotificationsIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 16.1905V17.1429H0V16.1905L2 14.2857V8.57143C2 5.61905 4.03 3.01905 7 2.18095C7 2.08571 7 2 7 1.90476C7 1.39959 7.21071 0.915104 7.58579 0.557892C7.96086 0.20068 8.46957 0 9 0C9.53043 0 10.0391 0.20068 10.4142 0.557892C10.7893 0.915104 11 1.39959 11 1.90476C11 2 11 2.08571 11 2.18095C13.97 3.01905 16 5.61905 16 8.57143V14.2857L18 16.1905ZM11 18.0952C11 18.6004 10.7893 19.0849 10.4142 19.4421C10.0391 19.7993 9.53043 20 9 20C8.46957 20 7.96086 19.7993 7.58579 19.4421C7.21071 19.0849 7 18.6004 7 18.0952H11Z"
        fill={iconColor}
      />
    </svg>
  )
}
