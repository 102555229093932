import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocalStorage } from 'react-use';
import { Box, Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { cacheKeys } from 'config';
import { Maybe } from 'types';
import { camelizeObject } from 'helpers/object';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { ChatHistory, DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import { integrationsClient } from 'clients/integrations/integrationsClient';
import { useWebSocket } from 'shared/hooks/useWebsocket';
import { useEvolveIP } from 'shared/hooks/useEvolveIP';
import { ChatMessage } from 'shared/components/chat/ChatMessage/ChatMessage';

import { ActionButtons } from './ActionButtons';


type Props = {
  userIdentifier: string;
  digitalEmployee: DigitalEmployee;
  conversation: any;
  readOnly: boolean;
  freedayParams: Maybe<string>;
  restartConversation: () => void;
  children: (values: {
    canReply: boolean;
    actions: any[];
    sendMessage: (data: { message: string }) => void;
  }) => React.ReactNode;
}


export enum ExternalType {
  DEFAULT = 'api',
  GENESYS = 'genesys',
  EVOLVEIP = 'evolveip',
}


export const ChatMessages: React.FC<Props> = ({
  userIdentifier,
  digitalEmployee,
  conversation,
  readOnly,
  restartConversation,
  children,
  freedayParams,
}) => {
  const queryClient = useQueryClient();
  const [externalMeta = {}, setExternalMeta, removeExternalMeta] = useLocalStorage<any>(`external-chat-${userIdentifier}`);
  const isExternal = useMemo<boolean>(() => !!(externalMeta.eventStreamUri || externalMeta.connectionId), [externalMeta.connectionId, externalMeta.eventStreamUri])
  const [externalType, setExternalType] = useState<ExternalType>(ExternalType.DEFAULT)
  const [initializeMessageSend, setInitializeMessageSend] = useState<Boolean>(false);

  const messagesFilters = useMemo(() => ({
    parent: conversation?.id,
    digital_employee: digitalEmployee.slug,
    limit: 100,
  }), [digitalEmployee.slug, conversation])

  const { data: { data: messages = [] } = {} } = useQuery(
    [cacheKeys.getChatMessages, messagesFilters],
    () => digitalEmployeeClient.getChatMessages(messagesFilters, conversation?.id),
    {
      enabled: !!conversation?.id,
    },
  );


  const { mutateAsync: sendMessage } = useMutation((data: any) => integrationsClient.sendWebChatMessage(digitalEmployee.webIntegration, freedayParams, {
    messages: [{
      timestamp: new Date().getTime(),
      from: userIdentifier,
      text: {
        body: data.userMessage,
      },
      type: 'text',
      is_external: isExternal,
      external_type: externalType, //genesys
      external_meta: externalMeta,
    }],
  }), {
    mutationKey: cacheKeys.mutations.chat.sendMessage,
    onMutate: async (data: any) => {

      if (externalType === ExternalType.EVOLVEIP)
        return

      // Cancel current queries
      await queryClient.cancelQueries(cacheKeys.getChatMessages)

      // Create optimistic todo
      const optimisticMessage = {
        id: uuid(),
        isTemp: true,
        userMessage: data.userMessage,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Add optimistic message to list
      queryClient.setQueryData([cacheKeys.getChatMessages, messagesFilters], (old: any) => ({
        ...old,
        data: [optimisticMessage, ...(old?.data || [])],
      }));

      // Return context with the optimistic message
      return { optimisticMessage }
    },
  });

  const { mutateAsync: saveMessage } = useMutation((data: any) => digitalEmployeeClient.saveWebChatMessage(conversation.id, data), {
    mutationKey: cacheKeys.mutations.chat.sendMessage,
    onMutate: async (data: any) => {

      // Cancel current queries
      await queryClient.cancelQueries(cacheKeys.getChatMessages)

      // Create optimistic todo
      const optimisticMessage = {
        id: uuid(),
        response: data.response,
        source: data.source,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Add optimistic message to list
      queryClient.setQueryData([cacheKeys.getChatMessages, messagesFilters], (old: any) => {
        const [lastMessage, ...oldData] = old?.data || [];
        return {
          ...old,
          data: [optimisticMessage, { ...lastMessage, isTemp: false }, ...(oldData || [])],
        }
      });

      // Return context with the optimistic message
      return { optimisticMessage }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getChatMessages, messagesFilters]);
    },
  });

  const parsedFreedayParams = useMemo(() => {
    return freedayParams ? JSON.parse(freedayParams) : null;
  }, [freedayParams]);

  const displayMessageDelay = useMemo(() => {
    const displayDelayRaw = parsedFreedayParams && parsedFreedayParams['message_display_delay'];
    return (displayDelayRaw && !isNaN(displayDelayRaw) && displayDelayRaw) || 1500;
  }, [parsedFreedayParams]);

  const onOpen = useCallback(() => {
    if (parsedFreedayParams) {
      if (parsedFreedayParams && parsedFreedayParams['initialize'] !== undefined && parsedFreedayParams.initialize) {

        if (!conversation?.id && !initializeMessageSend) {

          setInitializeMessageSend(true);
          //restartConversation();
          sendMessage({
            userMessage: 'Hoi',
          });
        }

      }
    }
  }, [
    parsedFreedayParams,
    conversation,
    setInitializeMessageSend,
    sendMessage,
    initializeMessageSend,
  ]);

  const onExternalAgentMessage = useCallback(async (event: MessageEvent<any>) => {
    const messageData = JSON.parse(event.data);

    if (
      messageData.metadata?.type === 'message' &&
      messageData.eventBody?.bodyType === 'standard' &&
      externalMeta?.member?.id &&
      messageData.eventBody?.sender?.id !== externalMeta?.member?.id
    ) {
      saveMessage({ response: { text: messageData.eventBody.body }, source: 'genesys' });
    }

    if (
      messageData.metadata?.type === 'member-change' &&
      messageData.eventBody?.member?.state === 'DISCONNECTED' &&
      messageData.eventBody?.member?.id !== externalMeta?.member?.id
    ) {
      const memberInfo = await integrationsClient.getMemberInfo(externalMeta, messageData.eventBody?.member?.id)

      if (memberInfo.data?.role === 'AGENT') {
        saveMessage({
          response: { separator: true, separator_text: 'Gesprek met medewerker beëindigd' },
          source: 'genesys',
        });

        removeExternalMeta();
        restartConversation();
      }
    }
  }, [externalMeta, removeExternalMeta, saveMessage, restartConversation]);

  const onWebsocketOpen = useCallback(async (event?: Event) => {
    await queryClient.invalidateQueries(cacheKeys.getChatMessages);
  }, [queryClient]);

  const onErrorOrClose = useCallback((event?: Event) => {
    setExternalType(ExternalType.DEFAULT)
    removeExternalMeta();
  }, [removeExternalMeta]);

  const [receivedMessagesDebouce, setReceivedMessagesDebouce] = useState<ChatHistory[]>([]);
  const [lastDisplayedMessageAt, setLastDisplayedMessageAt] = useState<number>(0);

  const addMessageToQueryData = useCallback((receivedMessage: ChatHistory) => {
    queryClient.setQueryData([cacheKeys.getChatMessages, messagesFilters], (old: any) => {
      let newData = [...(old?.data || [])];
      let matchIndex = newData.findIndex(message => message.id === receivedMessage.id);
      if (matchIndex >= 0) {
        newData[matchIndex] = receivedMessage;
      } else {
        newData.unshift(receivedMessage);
      }
      return {
        ...old,
        data: newData.filter(message => !message.isTemp),
      }
    });
  }, [queryClient, messagesFilters]);

  const onApiMessage = useCallback(async (event: MessageEvent<any>) => {
    try {
      const parsed = JSON.parse(event.data);
      if (!Array.isArray(parsed)) {
        return;
      }
      const receivedMessages: ChatHistory[] = parsed.map(it => camelizeObject<ChatHistory>(it));
      setReceivedMessagesDebouce(prev => [...prev, ...receivedMessages]);
    } catch (e) {}
  }, []);

  useWebSocket(externalMeta.eventStreamUri, readOnly, false, onExternalAgentMessage, onErrorOrClose, onErrorOrClose, () => {});

  const cxWsUri = useMemo(() => {
    return `${process.env.REACT_APP_WEBSOCKETS_URL}/digital-employee/${digitalEmployee.slug}/session/${userIdentifier}/`
  }, [digitalEmployee.slug, userIdentifier]);

  useWebSocket(cxWsUri, readOnly, true, onApiMessage, onErrorOrClose, onErrorOrClose, onWebsocketOpen);

  useEvolveIP(externalMeta.connectionId, readOnly, saveMessage, restartConversation, onErrorOrClose);

  const replyActions = useMemo(() => {
    const [lastLoadedMessage = undefined] = messages || [];
    return lastLoadedMessage && lastLoadedMessage?.response?.type === 'actions_list' && lastLoadedMessage.response.actions;
  }, [messages]);

  // Can't reply if it's a user message and doesn't have a response yet
  const canReply = useMemo(() => {
    const hasResponse = !messages[0]?.userMessage || messages[0]?.response
    return (hasResponse && receivedMessagesDebouce.length === 0) || isExternal;
  }, [messages, receivedMessagesDebouce, isExternal]);

  const onSubmit = useCallback(({ message }: any) => {
    sendMessage({
      userMessage: message,
    });
  }, [sendMessage]);

  useEffect(() => {
    if (externalMeta.eventStreamUri) {
      setExternalType(ExternalType.GENESYS)
    }
    if (externalMeta.connectionId) {
      setExternalType(ExternalType.EVOLVEIP)
    }
  }, [externalMeta]);

  useEffect(() => {
    const handleEvent = (event: any) => {
      switch (event.data) {
      case 'chat-widget.open':
        onOpen()
        break;
      case 'chat-widget.close':
        break;
      default:
        break;
      }
    }

    window.addEventListener('message', handleEvent);

    // cleanup this component
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [onOpen]);

  useEffect(() => {
    let meta: any;

    if (messages?.length > 0 && externalType === ExternalType.DEFAULT) {

      if (messages[0].response?.command?.name === 'REDIRECT_TO_GENESYS_CHAT') {
        setExternalType(ExternalType.GENESYS)

        meta = messages[0].response.command.result.genesysChatSession;

        if (!meta.hasOwnProperty('provider')) {
          meta.provider = messages[0].response.command.parameters.provider;
        }

        setExternalMeta(meta);
        saveMessage({
          response: { separator: true, separator_text: 'Gesprek met medewerker gestart' },
          source: ExternalType.GENESYS,
        });
      }

      if (messages[0].response?.command?.name === 'REDIRECT_TO_EVOLVEIP') {
        setExternalType(ExternalType.EVOLVEIP);
        meta = messages[0].response.command?.result?.envolveipChatSession;

        if (!meta.hasOwnProperty('provider')) {
          meta.provider = messages[0].response.command.parameters.provider;
        }

        setExternalMeta(meta);
        saveMessage({
          response: { separator: true, separator_text: 'Gesprek met medewerker gestart' },
          source: ExternalType.EVOLVEIP,
        });
      }

    }
  }, [messages, isExternal, setExternalMeta, saveMessage, externalType]);

  useEffect(() => {
    const diff = Date.now() - lastDisplayedMessageAt;
    const effectiveDelay = diff > displayMessageDelay ? 0 : displayMessageDelay - diff;
    const timeout = setInterval(() => {
      setReceivedMessagesDebouce(prev => {
        if (!prev || prev.length < 1) {
          return prev;
        }
        let newReceived = [...prev];
        const receivedMessage = newReceived.shift()!;
        setLastDisplayedMessageAt(Date.now());
        addMessageToQueryData(receivedMessage);
        return newReceived;
      });
    }, effectiveDelay);

    return () => clearInterval(timeout)

  }, [lastDisplayedMessageAt, displayMessageDelay, addMessageToQueryData]);

  useEffect(() => {
    const container = document.getElementById('chat-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages, receivedMessagesDebouce]);

  return (
    <Fragment>
      <Box overflow="auto" height="100%" flexGrow={0} px={2} pt={2} id="chat-container">
        <Grid container direction="column-reverse" style={{ minHeight: '100%', flexWrap: 'nowrap' }}>
          {messages.map((message: any, i: number, messages: any) => (
            <Grid item key={message?.id || i}>
              <ChatMessage
                {...message}
                sendMessage={onSubmit}
                readOnly={readOnly}
                previousMessage={messages[i + 1] ? messages[i + 1] : null}
                nextMessage={messages[i - 1] ? messages[i - 1] : null}
                digitalEmployee={digitalEmployee}
                hasMessagesDebouced={receivedMessagesDebouce.length > 0 && i === 0}
              />
            </Grid>
          ))}
        </Grid>
        {replyActions && <ActionButtons actions={replyActions} sendMessage={onSubmit} canReply={canReply}/>}
      </Box>
      {!readOnly && (
        children({
          canReply,
          actions: replyActions,
          sendMessage: onSubmit,
        })
      )}
    </Fragment>
  );
}
