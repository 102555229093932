import React, { Fragment, useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { initializeTracking, trackPageview } from 'tracking/tracking';
import { routes } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Authenticated, NotAuthenticated } from 'shared/components/auth/Authenticated';
import { LoggedInLayout } from 'shared/components/layout/LoggedInLayout/LoggedInLayout';
import { LoggedOutLayout } from 'shared/components/layout/LoggedOutLayout/LoggedOutLayout';
import { ComingSoon } from 'shared/components/layout/ComingSoon/ComingSoon';

import { Login } from 'views/LoggedOut/Login';
import { SignUp } from 'views/LoggedOut/SignUp';
import { ResetPassword } from 'views/LoggedOut/ResetPassword';

import { Home } from 'views/LoggedIn/Home/Home';
import { Employee } from 'views/LoggedIn/Employee/Employee';
import { MyAccount } from 'views/LoggedIn/MyAccount/MyAccount';
import { Employees } from 'views/LoggedIn/Employees/Employees';
import { RequestEmployee } from 'views/LoggedIn/RequestEmployee/RequestEmployee';
import { RequestEmployeeConfirmation } from 'views/LoggedIn/RequestEmployeeConfirmation/RequestEmployeeConfirmation';
import { Impersonate } from './LoggedOut/Impersonate';
import { Chat } from './Public/Chat/Chat';


initializeTracking();

export const Root = () => {

  useScrollTop();
  const { isLoading } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);

  const location = useLocation();

  useEffect(() => {
    trackPageview();
  }, [location]);

  if(isLoading) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Fragment>
      <NotAuthenticated>
        <Routes>
          <Route path={routes.digitalEmployeeChat()} element={<Chat/>}/>
          <Route path={routes.digitalEmployeeChatExternalId()} element={<Chat/>}/>
          <Route path={routes.impersonateUser} element={<Impersonate/>}/>
          <Route path={routes.login} element={<LoggedOutLayout><Login/></LoggedOutLayout>}/>
          <Route path={routes.signup} element={<LoggedOutLayout><SignUp/></LoggedOutLayout>}/>
          <Route path={routes.resetPassword} element={<LoggedOutLayout><ResetPassword/></LoggedOutLayout>}/>
          <Route path="*" element={<Navigate to={routes.login}/>} />
        </Routes>
      </NotAuthenticated>
      <Authenticated>
        <Routes>
          <Route path={routes.home} element={<LoggedInLayout><Home/></LoggedInLayout>}/>
          <Route path={routes.digitalEmployeeChat()} element={<Chat/>}/>
          <Route path={routes.digitalEmployeeChatExternalId()} element={<Chat/>}/>
          <Route path={routes.invoices} element={<LoggedInLayout><ComingSoon title={dictionary.invoices}/></LoggedInLayout>}/>
          <Route path={routes.impersonateUser} element={<Impersonate/>}/>
          <Route path={routes.reports} element={<LoggedInLayout><ComingSoon title={dictionary.reports}/></LoggedInLayout>}/>
          <Route path={routes.notifications} element={<LoggedInLayout><ComingSoon title={dictionary.notifications}/></LoggedInLayout>}/>
          <Route path={routes.myAccount} element={<LoggedInLayout><MyAccount/></LoggedInLayout>}/>
          <Route path={routes.requestEmployeeType()} element={<LoggedInLayout><RequestEmployee/></LoggedInLayout>}/>
          <Route path={routes.requestEmployee} element={<LoggedInLayout><RequestEmployee/></LoggedInLayout>}/>
          <Route path={routes.requestEmployeeConfirmation} element={<LoggedInLayout><RequestEmployeeConfirmation/></LoggedInLayout>}/>
          <Route path={routes.employees} element={<LoggedInLayout><Employees/></LoggedInLayout>}/>
          <Route path={routes.employee()} element={<LoggedInLayout><Employee/></LoggedInLayout>}/>
          <Route path={routes.employeeWithTab()} element={<LoggedInLayout><Employee/></LoggedInLayout>}/>
          <Route path={routes.employeeConversationTabWithId()} element={<LoggedInLayout><Employee/></LoggedInLayout>}/>
          <Route path="*" element={<Navigate to={routes.home}/>} />
        </Routes>
      </Authenticated>
    </Fragment>
  );
};
