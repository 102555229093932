import React, { Fragment, useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { UserContext, LocalizationContext } from 'contexts';

type Props = {
  actions: any[];
  sendMessage: (data: { message: any }) => void;
  canReply: boolean;
}

const maxVisibleActions = 5;

export const ActionButtons: React.FC<Props> = ({ actions, sendMessage, canReply }) => {

  const [collapsed, setCollapsed] = useState(actions.length > maxVisibleActions);
  const { partner } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);

  const [visibleItems, hiddenItems] = useMemo(() => {
    return [actions.slice(0, maxVisibleActions), actions.slice(maxVisibleActions)];
  }, [actions]);

  const toggleHiddenItems = useCallback(() => {
    setCollapsed(prev => !prev);
  }, [])

  const renderButton = useCallback(({ text, description, size, variant }: any) => (
    <Box ml={1} mb={1} key={text} display="inline-block">
      <Button
        size={ size || 'small' }
        variant={ variant || 'outlined' }
        onClick={() => sendMessage({
          message: description || text,
        })}
        disabled={!canReply}
        sx={{ borderColor: partner?.replyColor || 'inherit' }}
      >
        <Typography
          sx={{
            color: partner?.replyColor,
            fontSize: 12,
            textTransform: 'none',
          }}
          variant="subtitle2"
        >
          {text}
        </Typography>
      </Button>
    </Box>
  ), [canReply, sendMessage, partner?.replyColor]);

  useEffect(() => {
    if (actions.length > maxVisibleActions) {
      setCollapsed(true);
    }
  }, [actions.length]);

  useEffect(() => {
    const container = document.getElementById('chat-container');
    if(container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [collapsed]);

  if (!actions) {
    return null;
  }


  return (
    <Box textAlign="right" style={{ maxWidth: '600px', float: 'right' }}>
      {visibleItems.map(renderButton)}
      {hiddenItems.length > 0 && (
        <Fragment>
          {!collapsed && hiddenItems.map(renderButton)}
          <Box>
            <Button size="small" variant="text" onClick={toggleHiddenItems}>
              <Typography
                sx={{
                  color: partner?.replyColor,
                  fontSize: 12,
                  textTransform: 'none',
                }}
                variant="subtitle2"
              >
                {collapsed ? dictionary.showMore : dictionary.showLess}
              </Typography>
            </Button>
          </Box>
        </Fragment>
      )}
    </Box>
  )
}
