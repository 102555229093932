import React, { FC } from 'react';

export const FlagNL: FC<any> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icon-css-nl"
      viewBox="0 0 640 480"
      width={30}
      height={20}
      {...props}
    >
      <path fill="#21468b" d="M0 0h640v480H0z"/>
      <path fill="#fff" d="M0 0h640v320H0z"/>
      <path fill="#ae1c28" d="M0 0h640v160H0z"/>
    </svg>
  )
}
