import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  Typography,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Card,
  CardHeader,
  CardContent,
  useTheme,
} from '@mui/material';
import {
  chartPalette,
} from 'shared/styles/muiTheme';
import { format, isValid, startOfMonth, formatDistanceToNow } from 'date-fns';
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FiberManualRecord } from '@mui/icons-material';
import addDays from 'date-fns/addDays';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { routes } from 'config';
import { integrationsClient } from 'clients/integrations/integrationsClient';
import { ChartFilters } from 'clients/digitalEmployee/digitalEmployeeClient';
import {
  CompletedInteractionsIcon,
  DropoutIcon,
  NotificationsIcon,
  ReportsIcon,  WorkforceIcon } from 'shared/icons';
import { useDigitalEmployeeTasks } from 'shared/hooks/digitalEmployee/useDigitalEmployeeTasks';
import {
  useDigitalEmployeeCallsCharts,
} from 'shared/hooks/useDigitalEmployeeCharts';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Chat } from 'shared/components/chat/Chat/Chat';
import EmployeeConversationsExport from 'views/LoggedIn/Employee/components/EmployeeConversationsExport';
import EmployeeScorecards from 'views/LoggedIn/Employee/components/EmployeeScorecards';

export const ConversationDashboard = ({
  slug,
  conversationId,
  chartType = 'chart',
}: any) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [isLoadingExport, setIsLoadingExport] = useState<any>(false);
  const [dateAggregation, setDateAggregation] =
    useState<ChartFilters['date_aggregation']>('day');
  const { digitalEmployee, digitalEmployeeStatus } = useDigitalEmployee(slug);

  const navigate = useNavigate();

  const filters = useMemo(
    () => ({
      digital_employee: slug,
      external_id__isnull: false,
      limit: 20,
    }),
    [slug],
  );

  const { status, conversations } = useDigitalEmployeeTasks(filters);

  useEffect(() => {
    if (status === 'success' && conversations.length > 0 && !conversationId) {
      navigate(
        routes.employeeConversationTabWithId(
          slug,
          'calls',
          conversations?.[0]?.external_id,
        ),
      );
    }
  }, [navigate, status, conversations, conversationId, slug, digitalEmployee?.digitalEmployeeType]);

  const chartFilters = useMemo(
    () => ({
      date_gte: isValid(startDate) ? format(startDate, 'yyyy-MM-dd') : '',
      date_lte: isValid(endDate) ? format(endDate, 'yyyy-MM-dd') : '',
      date_aggregation: dateAggregation,
      chart_type: chartType,
    }),
    [startDate, endDate, dateAggregation, chartType],
  );

  const { charts: chartData, refetch } =
    useDigitalEmployeeCallsCharts({
      slug,
      ...chartFilters,
    });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },  [])

  const statusAccumulativeInteractions = useMemo(
    () => ({
      ...chartData?.charts?.statusAccumulativeInteractions?.chart,
      values: (
        chartData?.charts?.statusAccumulativeInteractions?.chart?.values || []
      ).map((value: any) => ({
        dateCreatedAt: value.dimensions.dateCreatedAt,
        Success: value.dimensions.group === 'completed' ? value.metrics.value : 0,
        Unsuccessful: (value.dimensions.group === 'failed') ? value.metrics.value : 0,
        Escalated: value.dimensions.group === 'escalated' ? value.metrics.value : 0,
      })).reduce((r: any, o: any) => {
        r[o.dateCreatedAt] = r[o.dateCreatedAt] || { dateCreatedAt: o.dateCreatedAt, Success: 0, Unsuccessful: 0, Escalated: 0 };
        r[o.dateCreatedAt].Success += +o.Success;
        r[o.dateCreatedAt].Unsuccessful += +o.Unsuccessful;
        r[o.dateCreatedAt].Escalated += +o.Escalated;
        return r
      },  []),
    }),
    [chartData?.charts?.statusAccumulativeInteractions?.chart],
  );

  const accumulativeConversations = useMemo(() => {
    return {
      ...chartData?.charts?.accumulativeConversations?.chart,
      values: (
        chartData?.charts?.accumulativeConversations?.chart?.values || []
      ).map((value: any) => ({
        ...value,
      })),
    };
  }, [chartData?.charts?.accumulativeConversations?.chart]);


  const onTasksExport = useCallback(
    async (e: any) => {
      setIsLoadingExport(true);
      const result = await integrationsClient.getCallsExport({
        slug,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      });
      const fileURL = URL.createObjectURL(result.data);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = `freeday_calls_${slug}_${format(
        Date.now(),
        'yyyy-MM-dd',
      )}.xlsx`;
      fileLink.click();
      setIsLoadingExport(false);
      fileLink.remove();
    },
    [slug, startDate, endDate],
  );

  if (digitalEmployeeStatus === 'loading') {
    return <BodyLoading/>;
  }

  if (status === 'loading') {
    return <BodyLoading/>;
  }

  if (conversations.length === 0) {
    return null;
  }

  return (
    <>
      <EmployeeConversationsExport dateAggregation={dateAggregation} endDate={endDate} setDateAggregation={setDateAggregation} setEndDate={setEndDate} setStartDate={setStartDate} slug={slug} startDate={startDate} isLoadingExport={isLoadingExport} onTasksExport={onTasksExport} setIsLoadingExport={setIsLoadingExport} />
      <Grid item xs={12}>
        <Grid container spacing={2} columns={12}>
          {[
            {
              title: 'Total Unsuccessful Conversations',
              key: 'averageTimeConversations',
              Icon: WorkforceIcon,
              props: { xs: 12, md: 4 },
            },
            {
              title: 'Total Unsuccessful Calls',
              key: 'errorInteractions',
              Icon: NotificationsIcon,
              props: { xs: 12, md: 4 },
            },
            {
              title: 'Total Calls',
              key: 'totalInteractions',
              Icon: ReportsIcon,
              props: { xs: 12, md: 4 },
            },
            {
              title: 'Total Successful Calls',
              key: 'completedInteractions',
              Icon: CompletedInteractionsIcon,
              props: { xs: 12, md: 4 },
            },
            {
              title: 'Total Escalated Calls',
              key: 'escalationsInteractions',
              Icon: DropoutIcon,
              props: { xs: 12, md: 4 },
            },
            {
              title: 'CSAT',
              key: 'csat',
              Icon: WorkspacePremiumIcon,
              percentage: true,
              props: { xs: 12, md: 4 },
            }, 
          ].map(({ title, key, Icon, props, percentage }, i) => {
            // @ts-ignore
            const variationValue = chartData?.scorecards?.[key]?.currentPeriodValue || 0;
            // @ts-ignore
            const variationPercentileValue = chartData?.scorecards?.[key]?.changePercentage || 0;
            const isGrowth = variationValue >= 0;

            return <EmployeeScorecards percentage={percentage}  digitalEmployee={digitalEmployee} Icon={Icon} key={i} props={props} i={i} title={title} isGrowth={isGrowth} theme={theme} variationPercentileValue={variationPercentileValue} variationValue={variationValue} />;
          })}
          <Grid item xs={12} sm={6} mb={2}>
            <Card
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader title="Accumulative Calls" />
              <CardContent
                style={{
                  width: '100%',
                  minHeight: 350,
                  height: '100%',
                  minWidth: 200,
                  marginTop: 'auto',
                  marginBottom: -14,
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={accumulativeConversations['values']}>
                    <XAxis dataKey="dimensions.dateCreatedAt" />
                    <YAxis />
                    <Tooltip formatter={(value: any, name: any) => [value, name = 'Calls']} />
                    <Line type="monotone" dataKey="metrics.value" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} mb={2}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                title={
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                      <Typography>Accumulative Calls by status</Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        wrap="nowrap"
                      >
                        {['Success', 'Unsuccessful', 'Escalated'].map((_type, idx) => (
                          <Grid item>
                            <Grid
                              container
                              wrap="nowrap"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <FiberManualRecord
                                  style={{
                                    color: chartPalette[idx],
                                    width: 12,
                                    height: 12,
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                style={{ fontSize: 12, fontWeight: 400 }}
                              >
                                {_type}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent
                style={{
                  width: '100%',
                  minHeight: 350,
                  height: '100%',
                  minWidth: 200,
                  marginTop: 'auto',
                  marginBottom: -14,
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={Object.values(statusAccumulativeInteractions['values'])}
                    id="InteractionsChart"
                  >
                    <XAxis dataKey="dateCreatedAt" />
                    <YAxis  />
                    <Tooltip />
                    <Bar dataKey="Success" fill="rgb(159, 166, 237)" />
                    <Bar dataKey="Unsuccessful" fill="rgb(235, 176, 98)" />
                    <Bar dataKey="Escalated" fill="rgb(236, 127, 111)" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap">
        <Grid
          item
          xs={12}
          sm={4}
          style={{ height: 'calc(100vh - 340px)', overflow: 'auto' }}
        >
          <Paper>
            <List>
              {conversations.map((conversation: any) => (
                <ListItemButton
                  selected={conversation.external_id === conversationId}
                  onClick={() =>
                    navigate(
                      routes.employeeConversationTabWithId(
                        slug,
                        'calls',
                        conversation.external_id,
                      ),
                    )
                  }
                >
                  <ListItemText
                    primary={conversation.user_id}
                    secondary={`${formatDistanceToNow(
                      new Date(conversation.created_at),
                    )} - ${format(
                      new Date(conversation.created_at),
                      'EEE dd MMM yyyy HH:ii:ss',
                    )}`}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm={8}>
          <Box style={{ height: 'calc(100vh - 340px)', overflow: 'auto' }}>
            {conversationId && (
              <Chat
                key={conversationId}
                digitalEmployee={slug}
                externalId={conversationId}
                readOnly
                showHeader={false}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
