import React, { createContext, useCallback, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useAuthState } from 'react-firebase-hooks/auth';

import { firebaseClient } from 'clients/firebaseClient';
import { useMe } from 'shared/hooks/auth/useMe';

import { ContextProps } from './types';

export const defaultContext: ContextProps = {
  user: undefined,
  partner: null,
  login: () => {},
  ssoLogin: () => {},
  impersonateUser: () => {},
  logout: () => {},
  isLoggedIn: false,
  isLoading: false,
  error: undefined,
};

export const UserContext = createContext(defaultContext);
export const UserContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();

  const [firebaseUser, firebaseLoading, error] = useAuthState(firebaseClient.getAuth());

  const isLoggedIn = useMemo(() => !!firebaseUser && !firebaseUser.isAnonymous, [firebaseUser]);

  useEffect(() => {
    if (!isLoggedIn && !firebaseUser?.isAnonymous && !firebaseLoading) {
      firebaseClient.auth.signInAnonymously();
    }
  }, [firebaseUser, isLoggedIn, firebaseLoading]);

  const { user: apiUser, status: userStatus } = useMe({
    enabled: !!firebaseUser && !firebaseUser.isAnonymous,
  });

  const isLoading = firebaseLoading || userStatus === 'loading';

  const user = useMemo(() => ({
    ...apiUser,
    firebaseUid: firebaseUser?.uid,
    displayName: apiUser?.firstName ? `${apiUser.firstName} ${apiUser.lastName}` : firebaseUser?.displayName,
  }), [apiUser, firebaseUser]);

  const partner = useMemo(() => {
    return user?.company?.partner || null;
  }, [user]);

  const isPWA = useMemo(() => {
    return window.hasOwnProperty('matchMedia') && window?.matchMedia('(display-mode: standalone)').matches;
  }, []);

  const ssoLogin = async (provider: string) => {
    try {
      let ssoProvider: any = new firebaseClient.auth.GoogleAuthProvider();
      if(provider === 'twitter') {
        ssoProvider = new firebaseClient.auth.TwitterAuthProvider();
      }
      if(provider === 'microsoft') {
        ssoProvider = new firebaseClient.auth.OAuthProvider('microsoft.com');
      }
      if(provider === 'facebook') {
        ssoProvider = new firebaseClient.auth.FacebookAuthProvider();
      }
      if(provider === 'google') {
        ssoProvider.addScope('email');
        ssoProvider.addScope('profile');
        ssoProvider.setCustomParameters({ prompt: 'select_account' });
      }
      try {
        if(isPWA) {
          firebaseClient.auth.signInWithRedirect(ssoProvider);
          return Promise.resolve({
            success: true,
          });
        } else {
          const response = await firebaseClient.auth.signInWithPopup(ssoProvider);

          if (response.user) {
            return Promise.resolve({
              success: true,
            });
          } else {
            return Promise.reject({
              success: false,
              message: 'Wrong Email',
            });
          }
        }
      } catch(error: any) {
        return Promise.reject({
          success: false,
          error: error.code,
          message: error.message,
        });
      }
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await firebaseClient.auth.signInWithEmailAndPassword(email, password);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  };

  const logout = useCallback(async () => {
    await firebaseClient.auth.signOut();
    await queryClient.invalidateQueries();
  }, [queryClient]);

  const impersonateUser = useCallback(async (token: string) => {
    try {
      const response = await firebaseClient.auth.signInWithCustomToken(token);
      await queryClient.invalidateQueries();
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, [queryClient]);

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        login,
        ssoLogin,
        isLoggedIn,
        error,
        impersonateUser,
        partner,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
