import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, AutocompleteProps, InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import { useDigitalEmployees } from 'shared/hooks/useDigitalEmployees';


export const NavSearch: FC<Partial<AutocompleteProps<any, any, any, any> & { TextFieldProps?: TextFieldProps }>> = ({ TextFieldProps, ...props }) => {

  const navigate = useNavigate();
  const [value, setValue] = useState<any>({});
  const { dictionary } = useContext(LocalizationContext);
  const { digitalEmployees } = useDigitalEmployees();

  return (
    <Autocomplete
      value={value}
      {...props}
      renderInput={props => (
        <TextField
          {...props}
          sx={theme => ({
            width: '100%',
            maxWidth: 300,
            '& .MuiOutlinedInput-root': {
              '& input': {
                color: theme.palette.primary.main,
              },
              '&.Mui-focused input::placeholder': {
                color: theme.palette.primary.light,
                opacity: 1,
              },
              '& fieldset': {
                border: '1px solid #E7E8F9',
              },
              '&:hover fieldset': {
                border: `1px solid ${theme.palette.primary.main}`,
              },
              '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.primary.main}`,
              },
            },
          })}
          variant="outlined"
          size="small"
          placeholder={dictionary.search}
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <InputAdornment position="start" style={{ marginLeft: 4 }}>
                <Tooltip title={dictionary.search}>
                  <SearchIcon color="primary"/>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      popupIcon={<SearchIcon/>}
      forcePopupIcon={false}
      options={digitalEmployees}
      getOptionLabel={({ firstName, lastName, jobTitle }: DigitalEmployee) => [firstName, lastName, jobTitle].filter(v => !!v).join(' ')}
      onChange={(e, { slug }: DigitalEmployee) => {
        navigate(routes.employee(slug));
        setValue({});
      }}
      disableClearable
      clearOnBlur
      clearOnEscape
    />
  )
}
