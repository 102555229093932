import * as locales from '@mui/material/locale';
import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

export const chartPalette = [
  '#9FA6ED',
  '#EBB062',
  '#EC7F6F',
  '#313133',
  '#0C165E',
  '#c66a00',
];

export const chartPaletteDarkMode = [
  '#78e58f',
  '#4AA7F8',
  '#de6a74',
  '#8161e1',
  '#FCFB6F',
  '#f8b36a',
];

export const chartPaletteLightMode = [
  '#32ad4c',
  '#2f71ad',
  '#9d2631',
  '#4e2daf',
  '#d37003',
];

export const primaryColor = '#0D1262';
export const primaryColorLight = '#9EA5ED';
export const secondaryColor = '#FC7869';
export const secondaryColorLight = '#FC786950';

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: primaryColor,
      light: primaryColorLight,
    },
    secondary: {
      main: secondaryColor,
      light: secondaryColorLight,
    },
    success: {
      main: '#44b700',
    },
  },
};

export const statusColors = {
  active: '#00ac28',
  requested: '#c66a00',
  disabled: '#444444',
  success: '#44b700',
  error: '#c40012',
}

export const defaultTheme = responsiveFontSizes(createTheme({}));

export const muiTheme = (mode: 'light' | 'dark', locale = 'enUS', themeFromPartner: any = {}) => createTheme(deepmerge({}, {
  ...themeFromPartner,
  typography: {
    fontSize: 13,
    ...['body1', 'subtitle1', 'subtitle2'].reduce((prev, next) => {
      prev[next] = {
        fontWeight: 300,
      }
      return prev;
    }, {} as any),
    ...themeFromPartner?.typography,
    fontFamily: 'Graphik, sans-serif',
    fontWeight: 300,
  },
  palette: {
    mode,
    ...theme.palette,
    ...themeFromPartner?.palette,
    ...mode === 'light' && ({
      background: {
        default: '#FAFAFF',
        paper: '#FFFFFF',
      },
    }),
    ...mode === 'dark' && ({
      primary: {
        main: '#ececec',
        light: primaryColorLight,
      },
    }),
    text: {
      primary: mode === 'light' ? primaryColor : '#FFF',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: mode === 'light' ? '#ececec' : '#333333',
          padding: 10,
          fontSize: 14,
          color: mode === 'light' ? '#303030' : '#ececec',
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: mode === 'light' ? primaryColorLight : '#222222',
        },
        img: {
          objectFit: 'contain',
        },
        colorDefault: {
          backgroundColor: primaryColorLight,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          boxShadow: '0 0 0 1px #FFF',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
          marginLeft: 8,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: mode === 'dark' ? primaryColorLight : primaryColor,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' ? '#FAFAFF' : '#222222',
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
        },
        title: {
          fontWeight: 700,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
}),
// @ts-ignore
locales[locale]);
