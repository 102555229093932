import React, { FC, useContext } from 'react';
import { Grid, Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';

export const ComingSoon: FC<{ title: string }> = ({ title }) => {

  const { dictionary } = useContext(LocalizationContext)

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: 'calc(100vh - 81px)' }}>
      <Grid item style={{ textAlign: 'center' }}>
        <Typography variant="h3" color="primary">{title}</Typography>
        <Typography variant="h6">{dictionary.comingSoon}</Typography>
      </Grid>
    </Grid>
  )
}
