import React, { useContext } from 'react';
import { Box, Grid, Paper, MenuItem, TextField } from '@mui/material';
import { LoadingButton, DatePicker } from '@mui/lab';
import { GetApp } from '@mui/icons-material';
import { LocalizationContext } from 'contexts';
import { ChartFilters } from 'clients/digitalEmployee/digitalEmployeeClient';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';

interface IEmployeeConversationsExport {
  slug: string;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  isLoadingExport?: boolean;
  setIsLoadingExport?: React.Dispatch<React.SetStateAction<boolean>>;
  dateAggregation: string;
  setDateAggregation: React.Dispatch<React.SetStateAction<'month' | 'week' | 'day'>>;
  onTasksExport?: (e: any) => Promise<void>;
  fromTab?: string;
}

const EmployeeConversationsExport: React.FC<IEmployeeConversationsExport> = (
  props,
) => {
  const {
    dateAggregation,
    endDate,
    isLoadingExport,
    setDateAggregation,
    setEndDate,
    setStartDate,
    startDate,
    onTasksExport,
    slug,
    fromTab,
  } = props;
  
  const { digitalEmployee } = useDigitalEmployee(slug);

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Grid item xs={12} mb={2}>
      <Box component={Paper} p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm="auto" style={{ flexGrow: 1, maxWidth: 180 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              maxDate={endDate}
              onChange={(date : any) => date && setStartDate(date)}
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto" style={{ flexGrow: 1, maxWidth: 180 }}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date : any) => date && setEndDate(date)}
              minDate={startDate}
              inputFormat="dd/MM/yyyy"
              mask="__/__/____"
              renderInput={(params : any) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  {...params}
                />
              )}
            />
          </Grid>

          <Box ml="auto" />
          {digitalEmployee?.digitalEmployeeType === 'voice_bot' && fromTab === 'messages' ? 
            null : 
            <Grid item xs={12} sm="auto">
              <LoadingButton
                color="primary"
                variant="outlined"
                onClick={onTasksExport}
                loading={isLoadingExport}
                endIcon={<GetApp />}
                size="medium"
              >
                {dictionary.exportTasks}
              </LoadingButton>
            </Grid>
          }

          <Grid item xs={12} sm="auto" style={{ flexGrow: 1, maxWidth: 170 }}>
            <TextField
              size="small"
              variant="outlined"
              label={dictionary.dateAgregation}
              value={dateAggregation}
              onChange={(e) =>
                setDateAggregation(
                    e.target.value as ChartFilters['date_aggregation'],
                )
              }
              fullWidth
              select
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default EmployeeConversationsExport;
