import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { GetEmployeeListParams } from 'clients/digitalEmployee/digitalEmployeeClient.types';

type Options = {
  enabled: boolean;
}

export const useDigitalEmployees = (params: GetEmployeeListParams = {}, options?: Options) => {

  const { status, data: { data: { results: digitalEmployees = [] } = {} } = {}, error } = useQuery(
    [cacheKeys.getDigitalEmployees, params],
    () => digitalEmployeeClient.getAll(params),
    {
      enabled: options?.enabled,
    },
  )

  return {
    status,
    digitalEmployees,
    error,
  }
}
