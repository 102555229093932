import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  useTheme,
} from '@mui/material';
import { Send } from '@mui/icons-material';

import { Autocomplete } from '@mui/material';
import { chatFont } from 'config';
import { UserContext } from 'contexts';

type Props = {
  canReply: boolean;
  actions: any;
  onSubmit: (data: { message: any }) => void;
};

export const ChatReply: React.FC<Props> = ({ canReply, actions, onSubmit }: any) => {

  const theme = useTheme();
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<any>('');
  const { partner } = useContext(UserContext);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const _onSubmit = useCallback((data: any) => {
    onSubmit(data);
    reset();
  }, [onSubmit, reset]);

  return (
    <Box p={2}
      sx={{ position: 'sticky',
        right: 0,
        bottom: 0,
        left: 0,
        boxShadow: partner?.replyBoxShadow,
        backgroundColor: partner?.replyBackgroundColor,
        '& .MuiOutlinedInput-root': {
          backgroundColor: partner?.chatBackgroundColor,
          '&:before fieldset': {
            border: partner?.replyAutocompleteBorder,
          },
          '&:hover fieldset': {
            border: partner?.replyAutocompleteBorder,
            filter: partner?.replyAutocompleteBorder ? 'brightness(90%)' : 'brightness(100%)',
          },
        },
      }}
    >
      <form onSubmit={handleSubmit(_onSubmit)} autoComplete="off">
        {(actions) ?
          (
            <Controller
              control={control}
              name="message"
              render={() => (
                <Autocomplete
                  inputValue={autocompleteInputValue}
                  options={actions}
                  PopperComponent={(props) =>
                    <Popper
                      {...props}
                      placement="top"
                      sx={{
                        boxShadow: partner?.replyBoxShadow,
                      }}
                    />}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      sx={{
                        '& .MuiAutocomplete-noOptions': {
                          color: partner?.replyColor,
                        },
                        color: partner?.replyColor,
                        fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily,
                        backgroundColor: partner?.chatBackgroundColor,
                        'ul': {
                          backgroundColor: partner?.chatBackgroundColor,
                          'li': {
                            backgroundColor: partner?.chatBackgroundColor,
                          },
                        },
                      }}
                    />
                  )}
                  sx = {{
                    '.MuiInputBase-root': {
                      backgroundColor: partner?.chatBackgroundColor,
                    },
                    'svg': {
                      fill: partner?.replyColor,
                    },
                  }}
                  getOptionLabel={({ text }: any) => text || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder={partner?.placeholderReply || ''}
                      autoFocus
                      fullWidth
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAutocompleteInputValue(e.target.value)
                      }}
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          color: partner?.replyColor,
                          fontFamily: partner?.chatFont,
                        },
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            const submittedValue = autocompleteInputValue.slice();
                            setAutocompleteInputValue('');
                            onSubmit({
                              message: submittedValue,
                            });
                          }
                        },
                      }}
                    />
                  )}
                  onChange={(e, action: any) => {
                    onSubmit({
                      message: action.text,
                    });
                  }}
                  blurOnSelect
                  clearOnBlur
                />
              )}/>
          ) :
          (
            <Controller
              control={control}
              name="message"
              render={({ field }) => (
                <TextField
                  id="message"
                  {...field}
                  size="small"
                  variant="outlined"
                  disabled={!canReply}
                  placeholder={partner?.placeholderReply || ''}
                  InputProps={{
                    sx: {
                      color: partner?.replyColor,
                      fontFamily: partner?.chatFont,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Grid container p={1}>
                          <IconButton type="submit" disabled={!canReply} edge="end">
                            <Send sx={{ color: partner?.replyColor }}/>
                          </IconButton>
                        </Grid>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                  fullWidth
                />
              )}
            />
          )
        }
      </form>
    </Box>
  );
}
