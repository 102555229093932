import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PulseLoader } from 'react-spinners'
import { Avatar, Box, Grid, IconButton, ImageList, Paper, Typography, useTheme } from '@mui/material';
import { ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import { nl, enUS } from 'date-fns/locale'
import { chatFont } from 'config';
import { UserContext, LocalizationContext } from 'contexts';

import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import avatar from 'shared/img/avatars/jessy-full.png';
import { ChatImages } from './ChatImages';

interface ChatImageProps {
  name: string;
  description?: string;
  websiteLink: string;
  imageUrl?: string;
  price: string;
  quantity?: number;
  homeDeliveryStatus?: string;
  homeDeliveryText?: string;
}

export const ChatMessage = (message: any) => {

  const { digitalEmployee, hasMessagesDebouced } = message;
  const theme = useTheme();
  const { partner } = useContext(UserContext);
  const { language } = useContext(LocalizationContext);

  const [isSuccessful, setIsSuccessful] = useState(message.task?.successful)
  useEffect(() => { setIsSuccessful(message.task?.successful) }, [message.task?.successful])

  const onRateMessage = useCallback((message: any, value: any) => {
    if (message.task?.id) {
      digitalEmployeeClient.rateTask(message.task.id, value);
      setIsSuccessful(value)
    }
  }, []);

  const userMessage = useMemo(() => {
    const text = message?.userMessage;
    if(typeof text === 'string') {
      return text;
    }
    return;
  }, [message?.userMessage]);

  const chatBotMessage = useMemo(() => {
    const text = message?.response;

    if (text?.command?.result?.itemsList) {
      const items = text?.command.result.itemsList;

      return (
        <ImageList
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            '&::-webkit-scrollbar, -ms-overflow-style, scrollbar-width': {
              display: 'none',
            },
          }}
        >
          {items.map((item: ChatImageProps, key: number) => (
            <ChatImages item={item} key={key}/>
          ))}
        </ImageList>
      )
    }

    if(text?.type === 'actions_list' && (text?.actions || [])?.length > 0) {
      return (
        <Fragment>
          <Typography
            variant="subtitle2"
            dangerouslySetInnerHTML={{ __html: text?.text }}
          />
        </Fragment>
      )
    }
    if(typeof text === 'string') {
      // TODO use sanitize-html
      return <span dangerouslySetInnerHTML={{ __html: text }}/>;
    }
    if(typeof text?.text === 'string') {
      // TODO use sanitize-html
      return <span dangerouslySetInnerHTML={{ __html: text?.text }}/>;
    }
    return;
  }, [message]);

  const separator = useMemo(() => {
    const text = message?.response;
    if(text?.separator === true) {
      if (text?.separatorText) {
        return <Fragment><span style={{ float: 'right' }}>{text.separatorText}</span><hr style={{
          background: 'rgba(0,0,0,0.5)',
          clear: 'both',
        }}/></Fragment>
      } else {
        return <hr style={{
          background: 'rgba(0,0,0,0.5)',
          clear: 'both',
        }}/>
      }
    }

    return;
  }, [message]);

  const { createdAt, updatedAt } = useMemo(() => {
    const createdAtDate = message?.createdAt instanceof Date ? message?.createdAt : new Date(message?.createdAt);
    const updatedAtDate = message?.updatedAt instanceof Date ? message?.updatedAt : new Date(message?.updatedAt);

    return {
      createdAt: `${formatDistanceToNow(createdAtDate, { addSuffix: true, locale: language === 'nl' ? nl : enUS })} - ${format(createdAtDate, 'HH:mm')}`,
      updatedAt: `${formatDistanceToNow(updatedAtDate, { addSuffix: true, locale: language === 'nl' ? nl : enUS })} - ${format(updatedAtDate, 'HH:mm')}`,
    }
  }, [message, language]);

  if(!userMessage && !chatBotMessage && !separator) {
    return null;
  }

  return (
    <Fragment>
      {userMessage && (
        <Box p={1} textAlign="right">
          <Grid container justifyContent="flex-end" spacing={2} wrap="nowrap">
            <Grid item display= "flex" flexDirection= "column" alignItems="flex-end" style={{ marginRight: -4 }}>
              <Box
                component={Paper}
                width="fit-content"
                p={1}
                sx={{
                  backgroundColor: partner?.messageUserBackgroundColor,
                  color: partner?.messageUserColor,
                  borderRadius: partner?.messageUserRadius,
                }}
              >
                <Typography
                  sx={{ fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily }}
                  variant="subtitle2"
                >
                  {userMessage}
                </Typography>
              </Box>
              { (!message?.nextMessage?.userMessage || chatBotMessage) &&
                <Typography
                  sx={{
                    fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily,
                    color: partner?.replyColor,
                    opacity: 0.4,
                    fontSize: 12,
                    margin: '4px',
                  }}
                >
                  {createdAt}
                </Typography>
              }
            </Grid>
            <Grid item>
              { !message?.nextMessage?.userMessage || message.nextMessage?.response
                ? <Avatar
                  sx={{
                    backgroundColor: partner?.chatBubbleBackgroundColor,
                    color: partner?.chatBubbleTextColor,
                    marginTop: '-2px',
                  }}
                />
                : <Avatar style={{ visibility: 'hidden', marginTop: -2 }}/>
              }
            </Grid>
          </Grid>
        </Box>
      )}
      {chatBotMessage && (
        <Box mb={2} textAlign="left">
          <Grid container justifyContent="flex-start" spacing={1} wrap="nowrap">
            <Grid item>
              {(!message?.nextMessage?.response || message?.nextMessage?.userMessage)
                ? <Avatar
                  src={digitalEmployee.profilePicture || avatar}
                  style={{ marginTop: -2 }}
                  sx={{ backgroundColor: partner?.chatBubbleBackgroundColor }}
                />
                : <Avatar src={digitalEmployee.profilePicture || avatar} style={{ marginTop: -2, visibility: 'hidden' }}/>
              }
            </Grid>
            <Grid item style={{ marginRight: 80 }} overflow="hidden">
              <Box
                component={Paper}
                p={1}
                sx={{
                  backgroundColor: partner?.messageBotBackgroundColor,
                  color: partner?.messageBotColor,
                  borderRadius: partner?.messageBotRadius,
                  border: partner?.messageBotBorder,
                  position: 'relative',
                  '& [data-hover]': {
                    visibility: 'hidden',
                  },
                  '&:hover [data-hover]': {
                    visibility: 'visible',
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily }}
                >
                  {chatBotMessage}
                </Typography>
                {(!message?.nextMessage?.response || message?.nextMessage?.userMessage) && message.task && (
                  <Box style={{ position: 'absolute', display: 'flex', flexDirection: 'row', top: '50%', transform: 'translateY(-50%)', left: '100%' }} data-hover>
                    <IconButton color="primary" style={{ marginLeft: 8, color: isSuccessful ? 'green' : 'inherit' }} onClick={() => onRateMessage(message, true)} >
                      <ThumbUpRounded fontSize="medium" />
                    </IconButton>
                    <IconButton color="primary" style={{ color: (isSuccessful !== null && !isSuccessful) ? 'red' : 'inherit', marginLeft: 8 }} onClick={() => onRateMessage(message, false)} >
                      <ThumbDownRounded fontSize="medium" />
                    </IconButton>
                  </Box>
                )}
              </Box>
              { (!message?.nextMessage?.response || message?.nextMessage?.userMessage) &&
                <Typography
                  sx={{
                    fontFamily: partner?.chatFont ? chatFont : theme.typography.fontFamily,
                    color: partner?.replyColor,
                    opacity: 0.4,
                    fontSize: 12,
                    margin: '4px',
                  }}
                  variant="subtitle2"
                >
                  {updatedAt}
                </Typography>
              }
            </Grid>
          </Grid>
        </Box>
      )}
      {(message?.isTemp || hasMessagesDebouced) && (
        <Box p={1} textAlign="left">
          <Grid container justifyContent="flex-start" spacing={2} wrap="nowrap">
            <Grid item>
              <Avatar
                src={digitalEmployee.profilePicture || avatar}
                style={{ marginTop: 8 }}
                sx={{
                  backgroundColor: partner?.chatBubbleBackgroundColor,
                  marginTop: '8px',
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: -20 }}>
              <Box p={2}>
                <PulseLoader color={theme.palette.primary.main}/>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {separator && (
        <Box p={4}>
          {separator}
        </Box>
      )}
    </Fragment>
  )
}
