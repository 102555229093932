import { AxiosError, AxiosResponse } from 'axios';
import { PaginatedResults } from 'types/Query';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';

import { ChatHistory, ChatHistoryApi, DigitalEmployee, DigitalEmployeeApi, GenericCallsCharts, GenericCallsChartsApi, GenericCharts, GenericChartsApi, GetChartsParams, GetEmployeeListParams, GetGenericChartsParams, GetTasksParams } from './digitalEmployeeClient.types';
import { reformatCallsCharts, reformatCharts } from './digitalEmployeeClient.formatters';

const digitalEmployeeApiBaseUrl = process.env.REACT_APP_DIGITAL_EMPLOYEE_API_URL

export type ChartFilters = {
  date_gte: string | Date;
  date_lte: string | Date;
  date_aggregation: 'day' | 'week' | 'month';
  chart_type: 'table' | 'chart';
}


export type TaskListFilters = {
  date_gte: string | Date;
  date_lte: string | Date;
}

const getAll = (filters: GetEmployeeListParams = {}): Promise<AxiosResponse<PaginatedResults<DigitalEmployee>, AxiosError>> => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/`,
      method: 'GET',
      params: {
        search: filters.search,
        limit: filters.limit,
        offset: filters.offset,
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<DigitalEmployeeApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(it => camelizeObject<DigitalEmployee>(it)),
    },
  }));
};

const getTasks = (filters: GetTasksParams = {}) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-task/`,
      method: 'GET',
      params: {
        description__icontains: filters.description__icontains,
        category: filters.category,
        title: filters.title,
        external_id: filters.external_id,
        external_id__isnull: filters.external_id__isnull,
        digital_employee: filters.digital_employee,
        sub_category__icontains: filters.sub_category__icontains,
        status: filters.status,
        parent: filters.parent,
        parent__isnull: filters.parent__isnull,
        started_at__gte: filters.started_at__gte,
        started_at__lte: filters.started_at__lte,
        search: filters.search,
        limit: filters.limit,
        offset: filters.offset,
      },
    },
  });
};

const rateTask = (taskId: any, isSuccessful: boolean) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-task/${taskId}/`,
      method: 'PATCH',
      data: { 'successful': isSuccessful, 'successful_classified_by_human': true },
    },
  });
};


const getChatMessages = (filters: any = {}, conversationId: string): Promise<AxiosResponse<ChatHistory[], AxiosError>> => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-chat-history/${conversationId}/`,
      method: 'GET',
      params: filters,
    },
  }).then((data: AxiosResponse<ChatHistoryApi[]>) => ({
    ...data,
    data: data.data.map(it => camelizeObject<ChatHistory>(it)),
  }));
};


const getEvolveIPChatMessages = (filters: any = {}, conversationId: string): Promise<AxiosResponse<ChatHistory[], AxiosError>> => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-chat-history/${conversationId}/`,
      method: 'GET',
      params: filters,
    },
  }).then((data: AxiosResponse<ChatHistoryApi[]>) => ({
    ...data,
    data: data.data.map(it => camelizeObject<ChatHistory>(it)),
  }));
};


const getBySlug = (slug: string) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<DigitalEmployeeApi>) => ({
    ...data,
    data: camelizeObject<DigitalEmployee>(data.data),
  }));
};

const getChart = (slug: string, id?: number | string, filters?: ChartFilters) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/chart/${id}/`,
      method: 'GET',
      params: filters,
    },
  });
};


const getCharts = ({ slug, ...filters }: GetGenericChartsParams): Promise<AxiosResponse<GenericCharts, AxiosError>> => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/generic-charts/`,
      method: 'GET',
      params: filters,
    },
  }).then((data: AxiosResponse<GenericChartsApi>) => ({
    ...data,
    data: reformatCharts(camelizeObject<GenericCharts>(data.data)),
  }));
};

const getCallsCharts = ({ slug, ...filters }: GetGenericChartsParams): Promise<AxiosResponse<GenericCallsCharts, AxiosError>> => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/generic-calls-charts/`,
      method: 'GET',
      params: filters,
    },
  }).then((data: AxiosResponse<GenericCallsChartsApi>) => {
    return ({
      ...data,
      data: reformatCallsCharts(camelizeObject<GenericCallsCharts>(data.data)),
    })},
  );
};


const getCustomizableCharts = ({ slug, ...filters }: GetChartsParams) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/charts/`,
      method: 'GET',
      params: filters,
    },
  });
};

const getResponseVariable = (slug: string, agent: string, name: string) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/agent/${agent}/response-variable/${name}/`,
      method: 'GET',
    },
  });
};

const getResponseVariables = (slug: string, agent: string) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/agent/${agent}/response-variable/`,
      method: 'GET',
    },
  });
};

const createResponseVariables = (slug: string, agent: string, data: { name: string, value: string | number }[]) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/agent/${agent}/response-variable/`,
      method: 'POST',
      data: data,
    },
  });
};

const updateResponseVariables = (slug: string, agent: string, data: { name: string, value: string | number }[]) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/${slug}/agent/${agent}/response-variable/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const requestDigitalEmployee = (data: any) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee/`,
      method: 'POST',
      data,
    },
  });
};

const saveWebChatMessage = (conversationId: any, data: any) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-chat-history/${conversationId}/`,
      method: 'POST',
      data,
    },
  });
};

const getTransactionsExport = (slug: string, startDate: string, endDate: string) => {
  return request({
    options: {
      url: `${digitalEmployeeApiBaseUrl}/digital-employee-task/xls-export/`,
      method: 'GET',
      params: {
        started_at__gte: startDate,
        started_at__lte: endDate,
        digital_employee: slug,
      },
      responseType: 'blob',
    },
  });
};

export const digitalEmployeeClient = {
  requestDigitalEmployee,
  getAll,
  getBySlug,
  getChart,
  getCharts,
  getResponseVariable,
  getResponseVariables,
  createResponseVariables,
  updateResponseVariables,
  getCustomizableCharts,
  getTasks,
  getChatMessages,
  getEvolveIPChatMessages,
  rateTask,
  saveWebChatMessage,
  getTransactionsExport,
  getCallsCharts,
};
