import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDigitalEmployeeTasks = (params: any, options: Partial<Options> = defaultOptions) => {

  const { status, data: { data: { results: conversations = [] } = {} } = {}, error } = useQuery(
    [cacheKeys.getTasks, params],
    () => digitalEmployeeClient.getTasks(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    conversations,
  };
};
