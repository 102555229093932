import { useState, useEffect, useCallback } from 'react';

import { Maybe } from 'types';

export const useWebSocket = (
  uri: string,
  readOnly: boolean,
  autoReconnect: boolean,
  onMessage: (event: MessageEvent<any>) => void,
  onError: (event: Event) => void,
  onClose: (event: Event) => void,
  onOpen: (event: Event) => void,
) => {
  const [ws, setWs] = useState<Maybe<WebSocket>>();
  const [retryCount, setRetryCount] = useState<number>(0);

  const retryConnect = useCallback(() => {
    setTimeout(function () {
      setRetryCount(prev => prev + 1);
    }, 1000);
  }, []);

  const onCloseHandler = useCallback((onClose: (event: CloseEvent) => void) => {
    return (event: CloseEvent) => {
      if (event.code !== 1005 && autoReconnect) {
        retryConnect();
      }
      onClose(event);
    }
  }, [autoReconnect, retryConnect]);

  useEffect(() => {
    if (ws) {
      ws.onerror = onError;
      ws.onmessage = onMessage;
      ws.onclose = onCloseHandler(onClose);
      ws.onopen = onOpen;
    }
  }, [ws, onCloseHandler, onError, onClose, onOpen, onMessage]);

  useEffect(() => {
    let websocket: Maybe<WebSocket>;

    if (uri && !readOnly) {
      websocket = new WebSocket(uri);
      websocket.onerror = () => {
      };
      websocket.onclose = () => {
        retryConnect();
      };
      setWs(websocket);
    }

    return () => {
      websocket?.close();
    }
  }, [uri, readOnly, retryCount, retryConnect]);

  useEffect(() => {
    const interval = setInterval(() => {
      ws?.send('ping');
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [ws]);

  return {
    ws,
  };
};
