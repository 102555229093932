import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import workforce from 'shared/img/illustrations/workforce.png'

export const RequestEmployeeConfirmation = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box m={5}>
      <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <img
            src={workforce}
            alt="Workforce"
            style={{
              width: '100%',
              height: 'auto',
              maxWidth: 200,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center" color="primary">{dictionary.thankYou}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="center">{dictionary.employeeRequestConfirmationText}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate(routes.home)}
            endIcon={<Home/>}
          >
            {dictionary.returnToHomePage}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )

}
