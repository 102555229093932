import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Badge, Box, IconButton, CardHeader, Chip, Grid, Paper, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { statusColors } from 'shared/styles/muiTheme';
import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import { GradientBox } from 'shared/components/layout/GradientBox/GradientBox';

export const EmployeeCard: FC<DigitalEmployee> = ({ slug, firstName, lastName, jobTitle, jobDescription, profilePicture, status }) => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const [ isOpen, setIsOpen ] = useState(false);

  const hasDescription = !!jobDescription;

  const handleOpenClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
    return false;
  };

  return (
    <Box
      component={Paper}
      sx={{
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0 0 10px 0px #00000080',
          transitionDuration: '200ms',
        },
      }}
      onClick={() => {
        navigate(routes.employee(slug))
      }}
    >
      <GradientBox p={{ lg: 2 }} pr={{ lg: hasDescription ? 0 : 2 }}>
        <CardHeader
          disableTypography
          avatar={
            <Badge
              badgeContent={
                <div style={{ background: statusColors[status as keyof typeof statusColors], border: '2px solid #ececec', borderRadius: '100%', width: 20, height: 20, marginBottom: -12, marginLeft: -12 }}/>
              }
            >
              <Avatar src={profilePicture} alt={`${firstName} ${lastName}`} style={{ width: 60, height: 60 }}/>
            </Badge>
          }
          title={<Typography variant="h6" noWrap={!isOpen}>{firstName} {lastName || ''}</Typography>}
          subheader={(
            <Typography variant="subtitle2" style={{ fontWeight: 300 }} noWrap={!isOpen}>{jobTitle}</Typography>
          )}
          action={
            hasDescription && (
              <IconButton onClick={handleOpenClick}>
                {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )
          }
          sx={{ 
            '& .MuiCardHeader-content': {
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
        {isOpen && (
          <Box px={2} pb={{ xs: 2, lg: 0 }}>
            <Typography variant="subtitle2" style={{ fontWeight: 300 }}>{jobDescription}</Typography>
          </Box>
        )}
      </GradientBox>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2} style={{ fontWeight: 300 }}>
          <Grid item xs={6}>{dictionary.status}:</Grid>
          <Grid item xs={6}>
            <Chip
              size="small"
              label={dictionary?.[status] || status}
              style={{
                borderRadius: 5,
                fontWeight: 'normal',
                background: statusColors[status],
                color: '#fff',
              }}
            />
          </Grid>
          <Grid item xs={6}>{dictionary.activity}:</Grid>
          <Grid item xs={6}>-</Grid>
          <Grid item xs={6}>{dictionary.completed}:</Grid>
          <Grid item xs={6}>-</Grid>
          <Grid item xs={6}>{dictionary.hoursSaved}:</Grid>
          <Grid item xs={6}>-</Grid>
        </Grid>
      </Box>
    </Box>
  );
}
