import React, { Fragment, FC, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { routes } from 'config';
import { LayoutContext, UserContext } from 'contexts';

export const Logo: FC<{ variant: 'primary' | 'secondary', icon?: boolean } & any> = ({ variant = 'primary', icon = false, style, ...props }) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const { mode, isMobile, sidebarOpen } = useContext(LayoutContext);
  const { partner } = useContext(UserContext);

  const [primaryColor, secondaryColor] = useMemo(() => {
    return [
      (variant === 'primary' && theme.palette.mode !== 'dark') ? theme.palette.primary.main : theme.palette.primary.main,
      theme.palette.secondary.main,
    ];
  }, [variant, theme]);


  const partnerLogo = useMemo(() => {
    if(partner) {
      if(mode === 'dark' && partner?.logoDarkMode) {
        return partner?.logoDarkMode
      }
      if(mode === 'light' && partner?.logoLightMode) {
        return partner?.logoLightMode
      }
    }
  }, [partner, mode])

  if(partnerLogo) {
    return (
      <img
        src={partnerLogo}
        alt="Logo"
        width={partnerLogo.includes('svg') ? 116: null}
        style={{
          maxHeight: (!sidebarOpen && isMobile)? 0: '60px',
          maxWidth: '10ch',
          height: 'auto',
          aspectRatio: 'attr(width) / attr(height)',
          transitionDelay: '0.075s',
          ...style,
        }}
        {...props}
      />
    )
  }

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116 35"
      width={116}
      height={35}
      onClick={() => navigate(routes.home)}
      style={{
        cursor: 'pointer',
        ...style,
      }}
      {...props}
    >
      {!icon && (
        <Fragment>
          <path fill={secondaryColor} d="M114.8,8.6c-1.2-0.5-2.5,0-3.1,1.2l-4.1,8.4l-2.6,5.4l2.5,5.5l2.6-5.4l5.9-11.9 c0.1-0.3,0.2-0.6,0.2-0.9C116.1,9.8,115.6,9,114.8,8.6z"/>
          <path fill={secondaryColor} d="M104.9,31.9c0.5,1,0.2,2.1-0.6,2.8l0,0l0,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1 s-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.6,0.2-1.2,0.2-1.8,0c-0.3-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.2-0.2-0.3 c-0.3-0.4-0.5-0.9-0.5-1.4c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.4l0-0.1l2.1-4.2L104.9,31.9z"/>
          <path fill={primaryColor} d="M40.8,20.7c-1.1,3.4-4.3,5.7-7.9,5.5c-4.7,0-8.9-3.4-8.9-9.2c0-5.5,4.1-9.1,8.5-9.1 c5.3,0,8.5,3.4,8.5,8.9c0,0.7-0.1,1.4-0.1,1.4H28.6c0.1,2.3,2.1,4,4.4,3.9c1.8,0.1,3.4-0.9,3.9-2.6L40.8,20.7z M36.4,15.1 c-0.1-1.7-1.2-3.4-3.9-3.4c-2-0.1-3.7,1.4-3.9,3.4H36.4z"/>
          <path fill={primaryColor} d="M59.6,20.7c-1.1,3.4-4.3,5.7-7.9,5.5c-4.7,0-8.9-3.4-8.9-9.2c0-5.5,4.1-9.1,8.5-9.1 c5.3,0,8.5,3.4,8.5,8.9c0,0.7-0.1,1.4-0.1,1.4H47.4c0.1,2.3,2.1,4,4.4,3.9c1.8,0.1,3.4-0.9,3.9-2.6L59.6,20.7z M55.3,15.1 c-0.1-1.7-1.2-3.4-3.9-3.4c-2-0.1-3.7,1.4-3.9,3.4H55.3z"/>
          <path fill={primaryColor} d="M79.4,22.5c0,1.2,0.1,2.4,0.1,3.1h-4.5c-0.1-0.7-0.2-1.3-0.2-2c-1.1,1.6-2.9,2.5-4.8,2.4 c-4.9,0-8.4-3.9-8.4-9.1c0-5.1,3.4-9,8.3-9c3,0,4.4,1.2,4.9,2.1V0.2h4.6L79.4,22.5z M70.6,21.9c2.4,0,4.2-1.8,4.2-4.9 c0-3.1-1.9-4.8-4.2-4.8s-4.3,1.8-4.3,4.8S68.2,21.9,70.6,21.9L70.6,21.9z"/>
          <path fill={primaryColor} d="M87.2,15.7l4.2-0.6c1-0.1,1.3-0.6,1.3-1.2c0-1.2-0.9-2.2-2.9-2.2c-1.7-0.1-3.2,1.1-3.3,2.8 l-4.1-0.9c0.3-2.7,2.8-5.7,7.4-5.7c5.5,0,7.5,3.1,7.5,6.6v8.5c0,0.9,0.1,1.8,0.2,2.7h-4.3C93.1,25,93,24.3,93,23.6 c-1.1,1.7-3.1,2.7-5.1,2.6c-3.7,0-5.9-2.5-5.9-5.2C82,17.9,84.3,16.1,87.2,15.7z M92.7,18.7v-0.8l-3.9,0.6c-1.2,0-2.1,1-2.1,2.2 c0,1.1,0.9,2,2,2c0.1,0,0.1,0,0.2,0C90.9,22.7,92.7,21.7,92.7,18.7z"/>
          <path fill={primaryColor} d="M107.6,18.2l-4-8.6c-0.4-0.8-1.2-1.3-2.1-1.3c-0.4,0-0.7,0.1-1,0.2c-1.1,0.6-1.6,2-1,3.1 c0,0,0,0,0,0l5.5,11.9l2.5,5.5l2.6-5.4L107.6,18.2z"/>
        </Fragment>
      )}
      <path fill={primaryColor} d="M20.8,8.4c-1.9,0-3.8,0.6-5.3,1.8c-0.7-1.1-2-1.8-3.3-1.8v0H4.7V6.5c-0.1-1.2,0.8-2.3,2-2.4c0.1,0,0.3,0,0.4,0 c0.5,0,1,0,1.5,0.2V0.4C7.9,0.1,7.1,0,6.3,0C2.5,0,0,2.5,0,6.4v1.9v4v13.3h4.7V12.4h5.1l0,0c0.9,0,2.4,0.2,2.4,1.5v2.6v0v9.2h4.7 v-8.6h0c0,0,0,0,0,0c0-2.2,1.8-4,4-4c0.6,0,1.3,0.2,1.9,0.5v-5C22.1,8.5,21.5,8.4,20.8,8.4z"/>
    </svg>
  )
}
