import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';


export const useIcons = ({ color }: any) => {

  const theme = useTheme();

  const iconColor = useMemo(() => {
    if(color) {
      if(color === 'primary') {
        return theme.palette.primary.main;
      }
      if(color === 'secondary') {
        return theme.palette.secondary.main;
      }
      if(color === 'success') {
        return theme.palette.success.main;
      }
      if(color === 'error') {
        return theme.palette.error.main;
      }
      return color;
    }
    return theme.palette.primary.light;
  }, [color, theme])

  return {
    iconColor,
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}
