import React, { Fragment, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import { routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { UserSignupWithEmailAndPassword } from 'clients/users/userClient.types';
import { useSignup } from 'shared/hooks/auth/useSignup';

import { Logo } from 'shared/components/layout/Logo/Logo';


export const SignUp = () => {

  const navigate = useNavigate();
  const { validationError, genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { control, handleSubmit, formState: { isSubmitting, errors }, watch } = useForm<UserSignupWithEmailAndPassword>({
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      jobTitle: '',
      phone: '',
      email: '',
      password: '',
      repeatedPassword: '',
    },
  });

  const { signupAndLogin, signupWithSSO } = useSignup();

  const password = watch('password');

  const onSSOLogin = useCallback(async (provider: string) => {
    try {
      await signupWithSSO(provider);
    } catch (e: any) {
      // @ts-ignore
      validationError(dictionary.firebase.errors[e.error] || e.message);
    }
  }, [signupWithSSO, validationError, dictionary]);

  const onSubmit = useCallback(async (data: UserSignupWithEmailAndPassword) => {
    try {
      await signupAndLogin(data);
      navigate(routes.home);
    } catch(e) {
      console.error(e);
      genericError();
    }
  }, [genericError, signupAndLogin, navigate]);

  return (
    <Fragment>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} style={{ maxWidth: 500 }}>
            <Grid item xs={12}>
              <Box m={2}>
                <Typography align="center"><Logo width={160} height={50}/></Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.firstName}
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.lastName}
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="company"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.company}
                    error={!!errors?.company}
                    helperText={errors?.company?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="jobTitle"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.jobTitle}
                    error={!!errors?.jobTitle}
                    helperText={errors?.jobTitle?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.phone}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                rules={{ required: dictionary.thisFieldIsRequired }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.email}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: dictionary.thisFieldIsRequired,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.password}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                    type="password"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="repeatedPassword"
                control={control}
                rules={{
                  required: dictionary.thisFieldIsRequired,
                  validate: repeatPassword => repeatPassword === password || dictionary.passwordsDoNotMatch,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={dictionary.repeatPassword}
                    error={!!errors?.repeatedPassword}
                    helperText={errors?.repeatedPassword?.message}
                    type="password"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item>
                  <Button size="large" variant="outlined" onClick={() => navigate(routes.login)}>
                    {dictionary.login}
                  </Button>
                </Grid>
                <Box ml="auto"/>
                <Grid item>
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ClipLoader color="#fff" size={24}/> : dictionary.signUp}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box
        sx={theme => ({
          marginTop: theme.spacing(4),
          textAlign: 'center',
          '& svg': {
            width: 40,
            height: 40,
          },
        })}
      >
        <Tooltip title={dictionary.loginWithGoogle}>
          <IconButton onClick={() => onSSOLogin('google')}>
            <img src="/google.svg" alt="Google" width={32} height={32} />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary.loginWithMicrosoft}>
          <IconButton onClick={() => onSSOLogin('microsoft')}>
            <img src="/microsoft.svg" alt="Microsoft" width={32} height={32} />
          </IconButton>
        </Tooltip>
      </Box>
    </Fragment>
  )
}
