import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const CompletedInteractionsIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.99994 5L7.1849 6.51049L9.5104 4.18499" stroke={iconColor} stroke-linecap="round" stroke-linejoin="round"/>
      <mask id="path-2-inside-1_221_1686" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00002 0C1.44774 0 1.00002 0.447715 1.00002 1V9C1.00002 9.03668 1.002 9.07291 1.00585 9.10857L0.967163 13L3.40989 11L4.82274 10H13C13.5523 10 14 9.55228 14 9V1C14 0.447715 13.5523 0 13 0H2.00002Z"/>
      </mask>
      <path d="M1.00585 9.10857L2.0058 9.11851L2.00638 9.0597L2.00007 9.00123L1.00585 9.10857ZM0.967163 13L-0.0327875 12.9901L-0.0540469 15.1286L1.60067 13.7737L0.967163 13ZM3.40989 11L2.83217 10.1838L2.80353 10.204L2.77638 10.2263L3.40989 11ZM4.82274 10V9H4.50465L4.24502 9.18377L4.82274 10ZM2.00002 1L2.00002 1V-1C0.895454 -1 2.39089e-05 -0.104569 2.39089e-05 1H2.00002ZM2.00002 9V1H2.39089e-05V9H2.00002ZM2.00007 9.00123C2.00006 9.00115 2.00002 9.00076 2.00002 9H2.39089e-05C2.39089e-05 9.07261 0.00393474 9.14466 0.0116264 9.2159L2.00007 9.00123ZM1.96711 13.0099L2.0058 9.11851L0.00589843 9.09863L-0.0327875 12.9901L1.96711 13.0099ZM2.77638 10.2263L0.333658 12.2263L1.60067 13.7737L4.04339 11.7737L2.77638 10.2263ZM4.24502 9.18377L2.83217 10.1838L3.98761 11.8162L5.40046 10.8162L4.24502 9.18377ZM13 9H4.82274V11H13V9ZM13 9V11C14.1046 11 15 10.1046 15 9H13ZM13 1V9H15V1H13ZM13 1H15C15 -0.104569 14.1046 -1 13 -1V1ZM2.00002 1H13V-1H2.00002V1Z" fill={iconColor} mask="url(#path-2-inside-1_221_1686)"/>
    </svg>
  )
}
