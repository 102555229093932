import { useCallback, useContext } from 'react';
import { trackSignUp } from 'tracking/tracking';
import { LayoutContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { UserSignupWithEmailAndPassword } from 'clients/users/userClient.types';


export const useSignup = () => {

  const { login, ssoLogin, logout } = useContext(UserContext);
  const { genericError } = useContext(LayoutContext);

  const signupAndLogin = useCallback(async (formData: UserSignupWithEmailAndPassword) => {
    try {
      await usersClient.signupWithEmailAndPassword(formData);
      trackSignUp();
      await login(formData?.email, formData?.password);
    } catch (e) {
      genericError();
      return Promise.reject();
    }
  }, [login, genericError]);

  const signupWithSSO = useCallback(async (provider: string) => {
    const result = await ssoLogin(provider);
    if(result !== true) {
      logout();
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      trackSignUp();
    }
  }, [ssoLogin, logout]);

  return {
    signupAndLogin,
    signupWithSSO,
  };
};
