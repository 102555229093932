import { AxiosError, AxiosResponse } from 'axios';

import { camelizeObject, snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';
import { ImpersonateUser, User, UserApi, UserCrudApi, UserEdit, UserSignupWithEmailAndPassword, UserSignupWithEmailAndPasswordApi } from './userClient.types';

const usersApiBaseUrl = process.env.REACT_APP_USERS_API_URL

const signupWithEmailAndPassword = (data: UserSignupWithEmailAndPassword) => {
  return request({
    authenticate: false,
    options: {
      url: `${usersApiBaseUrl}/signup/`,
      method: 'POST',
      data: snakizeObject<UserSignupWithEmailAndPasswordApi>(data),
    },
  });
};

const signinWithSSO = () => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/signup-with-sso/`,
      method: 'POST',
    },
  });
};

const updateUser = ({ id, ...data }: UserEdit) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user/${id}/`,
      method: 'PUT',
      data: snakizeObject<UserCrudApi>(data),
    },
  });
};

const partialUpdateUser = ({ id, ...data }: Partial<UserEdit>) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user/${id}/`,
      method: 'PATCH',
      data: snakizeObject<Partial<UserCrudApi>>(data),
    },
  });
};

const deleteUser = ({ id }: { id: number }) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user/${id}/`,
      method: 'DELETE',
    },
  });
};

const impersonateUser = (data: ImpersonateUser): Promise<AxiosResponse<{ token: string }>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/impersonate-user/`,
      method: 'POST',
      data: snakizeObject(data),
    },
  });
};

const logoutAllSessions = () => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/logout-all-sessions/`,
      method: 'POST',
    },
  });
};

const generateCustomToken = (data: ImpersonateUser, apiKey: string): Promise<AxiosResponse<{ token: string }, AxiosError>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/generate-custom-token/`,
      method: 'POST',
      params: {
        apiKey,
      },
      data: snakizeObject(data),
    },
  });
};

const getMe = (): Promise<AxiosResponse<User, AxiosError>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user/me`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: camelizeObject<User>(data.data),
  }));
};

export const usersClient = {
  signupWithEmailAndPassword,
  signinWithSSO,
  getMe,
  impersonateUser,
  generateCustomToken,
  updateUser,
  partialUpdateUser,
  deleteUser,
  logoutAllSessions,
};
