import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useMount } from 'react-use';

import { Maybe, MaybeNull } from 'types';
import { addQuery, parseQuery } from 'helpers/query';

export const useQueryState = <T = string>(name: string, defaultValue?: T): [Maybe<T>, (value?: MaybeNull<T>) => void] => {
  const navigate = useNavigate();
  const location = useLocation();
  const value = useMemo(() => parseQuery(location.search)[name] as unknown, [location.search, name]);

  const setValue = useCallback((value?: MaybeNull<T>) => {
    navigate({
      search: addQuery(window.location.search, {
        [name]: value,
      }),
    });
  }, [navigate, name]);

  useMount(() => {
    if (!value && defaultValue) {
      navigate({
        search: addQuery(window.location.search, {
          [name]: defaultValue,
        }),
      }, { replace: true });
    }
  });

  return [
    Array.isArray(value) ? value[0] : value,
    setValue,
  ];
};
