import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Chat as ChatComponent } from 'shared/components/chat/Chat/Chat';

export const Chat = () => {

  const { digitalEmployee, externalId } = useParams();

  return (
    <Box display="flex" flexDirection="column" width="100vw" height="100vh" overflow="hidden">
      <ChatComponent digitalEmployee={digitalEmployee} externalId={externalId}/>
    </Box>
  );
}
