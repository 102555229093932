import john from 'shared/img/avatars/john-small.png';
import johnFull from 'shared/img/avatars/john-full.png';
import jennifer from 'shared/img/avatars/jennifer-small.png';
import jenniferFull from 'shared/img/avatars/jennifer-full.png';
import jessy from 'shared/img/avatars/jessy-small.png';
import jessyFull from 'shared/img/avatars/jessy-full.png';
import richard from 'shared/img/avatars/richard-small.png';
import richardFull from 'shared/img/avatars/richard-full.png';

export const cacheKeys = {
  users: {
    getMe: 'getMe',
  },
  getTasks: 'getTasks',
  getChatMessages: 'getChatMessages',
  getDigitalEmployee: 'getDigitalEmployee',
  getDigitalEmployees: 'getDigitalEmployees',
  getDigitalEmployeeResponseVariable: 'getDigitalEmployeeResponseVariable',
  getDigitalEmployeeResponseVariables: 'getDigitalEmployeeResponseVariables',
  getChart: 'getChart',
  getCharts: 'getCharts',
  getCustomizableCharts: 'getCustomizableCharts',
  mutations: {
    chat: {
      sendMessage: 'sendMessage',
    },
  },
}

export const routes = {
  home: '/',
  login: '/login',
  notifications: '/notifications',
  invoices: '/invoices',
  reports: '/reports',
  employees: '/employees',
  requestEmployeeType: (type: string | number = ':type') => `/request-employee/${type}`,
  requestEmployeeConfirmation: '/thank-you',
  requestEmployee: '/request-employee',
  employee: (slug: string | number = ':slug') => `/employee/${slug}`,
  employeeWithTab: (slug: string | number = ':slug', tab: string | number = ':tab') => `/employee/${slug}/${tab}/`,
  employeeConversationTabWithId: (slug: string | number = ':slug', tab: string | number = ':tab', conversationId: string | number = ':conversationId') => `/employee/${slug}/${tab}/${conversationId}/`,
  resetPassword: '/reset-password',
  signup: '/signup',
  myAccount: '/my-account',
  impersonateUser: '/impersonate',
  digitalEmployeeChat: (digitalEmployee = ':digitalEmployee') => `/chat/${digitalEmployee}/`,
  digitalEmployeeChatExternalId: (digitalEmployee = ':digitalEmployee', externalId = ':externalId') => `/chat/${digitalEmployee}/${externalId}/`,
}

export const demoEmployees = [
  {
    name: 'John',
    slug: 'john',
    intro: 'A penny saved is a penny earned',
    avatar: john,
    promises: [
      'Your balance always up to date',
      'Automatic invoice processing',
    ],
    image: johnFull,
    status: 'Active',
    jobTitle: 'Financial Assistant',
    jobDescription: 'Handles invoices and checks their accuracy.',
    workingSince: '01-09-2020',
    traditionalEmployeeVolume: 50,
    capacityCap: 250,
    labourCost: '€2500,-',
    customerContactPerson: 'John Finance',
    accountManagerFreeday: 'Mathijs Gast',
    contractDuration: '24 months',
    department: 'Finance',
    activity: 'Handling Invoices',
    completed: 500,
    hoursSaved: 1120,
  },
  {
    name: 'Jennifer',
    slug: 'jennifer',
    intro: 'As right as rain',
    avatar: jennifer,
    promises: [
      'Incoming FAQ\'s processed in no time',
      'Process orders, forms and applications',
    ],
    image: jenniferFull,
    status: 'Active',
    jobTitle: 'Customer Service Expert',
    department: 'Service & Support',
  },
  {
    name: 'Jessy',
    slug: 'jessy',
    intro: 'Leave no stone unturned',
    avatar: jessy,
    promises: [
      'The digital source of HR information',
      'Arranges all your pre-employements verifications and onboarding',
    ],
    image: jessyFull,
    status: 'Not Activated',
    jobTitle: 'HR Super Star',
    department: 'Human Resources',
  },
  {
    name: 'Richard',
    slug: 'richard',
    intro: 'Knows more than google',
    avatar: richard,
    promises: [
      'Always available, 24/7 support',
      'Open, close and manage support tickets',
    ],
    image: richardFull,
    status: 'Not Activated',
    jobTitle: 'IT Support Star',
    department: 'Information Technology',
  },
].map((employee, id) => ({ ...employee, id }));

export const featureFlags = {
  customizableCharts: process.env.REACT_APP_CUSTOMIZABLE_CHARTS === 'true',
  isSlackIntegrationEnabled: process.env.REACT_APP_IS_SLACK_INTEGRATION_ENABLED === 'true',
  npsChart: process.env.REACT_APP_NPS_CHARTS === 'true',
  displayEmployeesSection: process.env.REACT_APP_SHOW_EMPLOYEES_SECTION === 'true',
  showConversations: process.env.REACT_APP_SHOW_EMPLOYEE_CONVERSATIONS === 'true',
}

export const chatFont = 'Chat'
