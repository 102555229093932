import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


import { muiTheme } from 'shared/styles/muiTheme';
import {
  LayoutContextProvider,
  LayoutContext,
  DialogContextProvider,
  LocalizationContextProvider,
  LocalizationContext,
  UserContextProvider,
} from 'contexts';
import { Root } from 'views/Root';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 360000, // 6 minutes
    },
  },
});

const AppWithTheme = () => {

  const { language } = useContext(LocalizationContext);
  const { mode, themeFromConfig } = useContext(LayoutContext);
  const theme = muiTheme(mode, language === 'nl' ? 'nlNL' : 'enUS', themeFromConfig);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <DialogContextProvider>
        <Root/>
      </DialogContextProvider>
    </ThemeProvider>
  )
}

const App = () => {

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <LocalizationContextProvider>
            <UserContextProvider>
              <LayoutContextProvider>
                <AppWithTheme/>
              </LayoutContextProvider>
            </UserContextProvider>
          </LocalizationContextProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
