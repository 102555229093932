import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const PrivacyIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.65638 2.47983C7.82524 2.8478 11.0668 0.908292 11.9754 0L12.8226 0.847433C11.6917 1.97791 8.33192 3.90601 3.20957 3.71004C3.18775 8.03768 3.20238 11.2146 3.21294 12.2965L3.21321 12.324L3.21095 12.3514C3.06019 14.185 4.548 19.2387 12.6463 22.9086L12.1517 24C3.77373 20.2034 1.82726 14.7788 2.01448 12.2817C2.00337 11.1148 1.98827 7.69141 2.01471 3.07401L2.01837 2.43441L2.65638 2.47983Z" fill={iconColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6412 2.47983C16.4724 2.8478 13.2308 0.908292 12.3222 0L11.475 0.847433C12.6059 1.97791 15.9657 3.90601 21.088 3.71004C21.1099 8.03768 21.0952 11.2146 21.0847 12.2965L21.0844 12.324L21.0867 12.3514C21.2374 14.185 19.7496 19.2387 11.6513 22.9086L12.1459 24C20.5239 20.2034 22.4703 14.7788 22.2831 12.2817C22.2942 11.1148 22.3093 7.69141 22.2829 3.07401L22.2792 2.43441L21.6412 2.47983Z" fill={iconColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3791 9.58611H7.99123L7.99123 15.5774H16.3791V9.58611ZM7.99123 8.38785C7.32945 8.38785 6.79297 8.92433 6.79297 9.58611V15.5774C6.79297 16.2392 7.32945 16.7757 7.99123 16.7757H16.3791C17.0409 16.7757 17.5773 16.2392 17.5773 15.5774V9.58611C17.5773 8.92433 17.0409 8.38785 16.3791 8.38785H7.99123Z" fill={iconColor}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9828 8.38785V7.78872C13.9828 6.79605 13.178 5.99132 12.1854 5.99132C11.1927 5.99132 10.388 6.79605 10.388 7.78872V8.38785H13.9828ZM12.1854 4.79306C10.5309 4.79306 9.1897 6.13426 9.1897 7.78872V9.58611H15.181V7.78872C15.181 6.13426 13.8398 4.79306 12.1854 4.79306Z" fill={iconColor}/>
      <circle cx="12.1853" cy="11.3835" r="0.599132" fill={iconColor}/>
      <rect x="11.5862" y="11.9826" width="1.19826" height="2.39653" fill={iconColor}/>
    </svg>
  )
}
