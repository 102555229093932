import React from 'react'
import { Box, Paper, Grid, Typography, Theme } from '@mui/material'
import { DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import { GrowthIcon, FallIcon } from 'shared/icons'

interface IEmployeeScorecards{
  digitalEmployee?: DigitalEmployee;
  theme: Theme;
  variationValue: any;
  isGrowth: boolean;
  variationPercentileValue: any;
  title: string;
  i: number;
  props?: object;
  Icon: any;
  percentage?: boolean;
}

const EmployeeScorecards: React.FC<IEmployeeScorecards> = (props) => {
  const { theme, i, title,  variationPercentileValue, isGrowth, variationValue, Icon, percentage } = props

  return (
    <Grid item {...props.props} key={i}>
      <Box component={Paper} p={2}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <span style={{ fontSize: 14 }}>{title}</span>
          </Grid>
          <Grid item>
            <Icon sx={{ fontSize: 30 }} color="primary" />
          </Grid>
          <Grid item xs={11} sm="auto" style={{ flexGrow: 1 }}>
            <span style={{ fontSize: 30 }}>{variationValue}{percentage ? <span style={{ fontSize: 15 }}>%</span> : ''}</span>
          </Grid>
          <Box ml="auto" />
          <Grid item>
            {isGrowth ? (
              <GrowthIcon color="success" />
            ) : (
              <FallIcon color="error" />
            )}
          </Grid>
          <Grid item xs={12} sm="auto" direction="row">
            <Typography
              variant="subtitle2"
              style={{
                color: isGrowth
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              }}
            >
              {variationPercentileValue}%
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default EmployeeScorecards
