import React, { FC, useContext, useMemo } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { FlagEN } from 'shared/icons/FlagEN/FlagEN';
import { FlagNL } from 'shared/icons/FlagNL/FlagNL';
import { WhiteTextField } from 'shared/components/form/WhiteTextField/WhiteTextField';

export const LanguageSelect: FC<{ variant?: 'default' | 'white' }> = ({ variant = 'default' }) => {

  const { language, setLanguage } = useContext(LocalizationContext);

  const TextFieldComponent = useMemo(() => {
    return variant === 'white' ? WhiteTextField : TextField;
  }, [variant])

  return (
    <TextFieldComponent
      style={{ minWidth: 180 }}
      variant="outlined"
      size="small"
      value={language}
      onChange={(e: any) => setLanguage(e.target.value)}
      fullWidth
      select
    >
      <MenuItem value="en">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item><FlagEN style={{ marginBottom: -4 }}/></Grid>
          <Grid item>English</Grid>
        </Grid>
      </MenuItem>
      <MenuItem value="nl">
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item><FlagNL style={{ marginBottom: -4 }}/></Grid>
          <Grid item>Nederlands</Grid>
        </Grid>
      </MenuItem>
    </TextFieldComponent>
  )
}
