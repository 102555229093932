import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const HelpIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke={iconColor}/>
      <path
        d="M10.9059 13.772H11.9419V13.17C11.9419 12.316 12.5439 11.728 13.1739 11.126C13.8459 10.482 14.5459 9.82401 14.5459 8.78801C14.5459 7.61201 13.7899 6.46401 11.8859 6.46401C10.1779 6.46401 9.0159 7.61201 8.9179 9.25001H10.0099C10.1079 8.14401 10.8219 7.43001 11.8719 7.43001C12.9079 7.43001 13.3699 8.00401 13.3699 8.83001C13.3699 9.50201 12.8939 10.006 12.3479 10.552C11.6479 11.238 10.9059 11.938 10.9059 13.072V13.772ZM11.3959 16.67C11.8859 16.67 12.2919 16.278 12.2919 15.774C12.2919 15.27 11.8859 14.878 11.3959 14.878C10.9059 14.878 10.4999 15.27 10.4999 15.774C10.4999 16.278 10.9059 16.67 11.3959 16.67Z"
        fill={iconColor}
      />
    </svg>
  )
}
