import React from 'react';
import { useIcons } from 'shared/hooks/useIcons';

export const InvoicesIcon = ({ color, ...props }: any) => {

  const { iconColor, width, height } = useIcons({ color });

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7143 4.42857V3.42857C14.7143 2.08731 13.627 1 12.2857 1C10.9445 1 9.85714 2.08731 9.85714 3.42857V4.42857H8.85714H4C3.44772 4.42857 3 4.87629 3 5.42857V22C3 22.5523 3.44772 23 4 23H20.5714C21.1237 23 21.5714 22.5523 21.5714 22V5.42857C21.5714 4.87629 21.1237 4.42857 20.5714 4.42857H15.7143H14.7143ZM16.7143 3.42857H20.5714C21.676 3.42857 22.5714 4.324 22.5714 5.42857V22C22.5714 23.1046 21.676 24 20.5714 24H4C2.89543 24 2 23.1046 2 22V5.42857C2 4.324 2.89543 3.42857 4 3.42857H7.85714H8.85714C8.85714 1.53502 10.3922 0 12.2857 0C14.1793 0 15.7143 1.53502 15.7143 3.42857H16.7143Z"
        fill={iconColor}
      />
      <rect x="8.85718" y="8" width="10.2857" height="1.14286" rx="0.571429" fill={iconColor}/>
      <rect x="8.85718" y="11.4286" width="6.85714" height="1.14286" rx="0.571429" fill={iconColor}/>
      <rect x="8.85718" y="14.8571" width="6.85714" height="1.14286" rx="0.571429" fill={iconColor}/>
      <rect x="8.85718" y="18.2857" width="4.57143" height="1.14286" rx="0.571429" fill={iconColor}/>
      <circle cx="12.2857" cy="3.42856" r="1.14286" fill={iconColor}/>
      <circle cx="5.9999" cy="8.57143" r="0.571429" fill={iconColor}/>
      <circle cx="5.9999" cy="12" r="0.571429" fill={iconColor}/>
      <circle cx="5.9999" cy="15.4286" r="0.571429" fill={iconColor}/>
      <circle cx="5.9999" cy="18.8571" r="0.571429" fill={iconColor}/>
    </svg>
  )
}
