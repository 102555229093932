import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { demoEmployees, routes } from 'config';
import { LocalizationContext } from 'contexts';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import hand from 'shared/img/illustrations/hand.png';
import { GradientBox } from 'shared/components/layout/GradientBox/GradientBox';


export const Employees = () => {

  useScrollTop();
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box m={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h4" color="primary">{dictionary.employees}</Typography>
            </Grid>
            <Box ml="auto"/>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Add/>}
                onClick={() => navigate(routes.requestEmployee)}
                fullWidth
              >
                {dictionary.buildYourOwn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {demoEmployees.map(({ id, name, jobTitle, intro, image, promises }) => (
          <Grid item key={id} xs={12} sm={4}>
            <Box
              component={Paper}
              sx={{
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '0 0 10px 0px #00000080',
                  transitionDuration: '200ms',
                },
              }}
              onClick={() => navigate(routes.requestEmployeeType(name.toLowerCase()))}
            >
              <Box p={2}>
                <Grid container direction="column" alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography variant="h5" color="primary" align="center">{jobTitle}</Typography>
                  </Grid>
                  <Grid item>
                    <img src={image} alt={name}/>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="primary" align="center">{dictionary.hiIAm(name)}</Typography>
                    <Typography style={{ fontWeight: 300 }} align="center">{intro}</Typography>
                  </Grid>
                  <Grid item style={{ width: '100%' }}>
                    <Typography style={{ fontWeight: 300 }}>
                      <ul>
                        {promises.map((promise, i) => (
                          <li key={i}>{promise}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <GradientBox
            component={Paper}
            sx={{
              height: '100%',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: '0 0 10px 0px #00000080',
                transitionDuration: '200ms',
              },
            }}
            onClick={() => navigate(routes.requestEmployee)}
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Box p={4} pb={0}>
                  <Typography variant="h4" color="primary"><b>{dictionary.buildYourOwnDigitalEmployee}</b></Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box p={4} pt={0}>
                  <Typography variant="body1">{dictionary.buildYourOwnDigitalEmployeeDescription}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <img src={hand} alt="Hand" style={{ width: '100%', marginRight: -32 }}/>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 'auto' }}>
                <Box p={4}>
                  <Button variant="contained" color="primary" size="large" fullWidth>
                    {dictionary.buildYourOwn}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </GradientBox>
        </Grid>
      </Grid>
    </Box>
  )
}
