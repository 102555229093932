import React, { FC, useContext, useState } from 'react';
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import { statusColors } from 'shared/styles/muiTheme';
import { LocalizationContext } from 'contexts';
import { DigitalEmployee } from 'clients/digitalEmployee/digitalEmployeeClient.types';
import avatar from 'shared/img/avatars/jessy-full.png';


export const EmployeeAttribute = ({ label, value }: any) => {
  if (!value) {
    return null;
  }
  return <Grid item xs={12} sm={4} lg={3} style={{ fontWeight: 300 }}>{label}:<br /><b>{value}</b></Grid>
}

export const EmployeeHeading: FC<DigitalEmployee> = ({
  status,
  firstName,
  lastName,
  profilePicture,
  jobTitle,
  jobDescription,
  capacityCap,
  digitalEmployeeType,
}) => {

  const { dictionary } = useContext(LocalizationContext);
  const [expanded] = useState(false);

  return (
    <Grid container alignItems="center" style={{ position: 'relative' }} spacing={2}>
      <Grid item>
        <Avatar src={profilePicture || avatar} alt={`${firstName} ${lastName}`} style={{ width: 105, height: 105, objectFit: 'contain' }} />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Box p={2} py={3} component={Paper} style={{ width: 'calc(100% + 70px)', marginLeft: -70, paddingLeft: 70, boxShadow: 'none' }}>
          <Typography variant="h5">{firstName} {lastName}</Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <div style={{ background: statusColors[status as keyof typeof statusColors], border: '2px solid #ececec', borderRadius: '100%', width: 16, height: 16 }} />
            </Grid>
            <Grid item>
              <Typography
                style={{ fontWeight: 300, textTransform: 'capitalize' }}
                color="primary"
              >
                {status}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12}>
        <Collapse in={expanded} collapsedSize={0}>
          <Box p={2} pb={4}>
            <Grid container spacing={2}>
              <EmployeeAttribute label={dictionary.name} value={`${firstName} ${lastName}`} />
              <EmployeeAttribute label={dictionary.status} value={dictionary?.[status as keyof typeof dictionary] || status} />
              <EmployeeAttribute label={dictionary.jobTitle} value={jobTitle} />
              <EmployeeAttribute label={dictionary.jobDescription} value={jobDescription} />
              {/* <EmployeeAttribute label={dictionary.workingSince} value={workingSince}/>
                <EmployeeAttribute label={dictionary.traditionalEmployeeVolume} value={traditionalEmployeeVolume}/> */}
              <EmployeeAttribute label={dictionary.capacityCap} value={capacityCap} />
              {/* <EmployeeAttribute label={dictionary.labourCost} value={labourCost}/>
                <EmployeeAttribute label={dictionary.customerContactPerson} value={customerContactPerson}/>
                <EmployeeAttribute label={dictionary.accountManagerFreeday} value={accountManagerFreeday}/>
                <EmployeeAttribute label={dictionary.contractDuration} value={contractDuration}/>
                <EmployeeAttribute label={dictionary.completedTasks} value={completed}/>
                <EmployeeAttribute label={dictionary.hoursSaved} value={hoursSaved}/> */}
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
}
