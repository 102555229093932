import React, { FC, useContext } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

import { LayoutContext } from 'contexts';

export const CustomLegend: FC<any> = ({ payload, onClick, selectedDimension, metricsMap }) => {

  const { isMobile } = useContext(LayoutContext);

  return (
    <Box
      position="absolute"
      width="100%"
      bottom={-50}
    >
      <Grid
        container
        alignItems="center"
        justifyContent={(payload?.length > 3 || isMobile) ? 'flex-start' : 'center'}
        wrap="nowrap"
        style={{ width: '100%', overflow: 'auto' }}
      >
        {
          payload.map((props: any, index: any) => {
            const { value, dataKey, color } = props;
            return (
              <Grid
                item
                key={`item-${index}`}
                style={{ color, padding: 10, cursor: 'pointer' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUnchecked style={{ color }}/>}
                      checkedIcon={<RadioButtonChecked style={{ color }}/>}
                      name={dataKey}
                      checked={payload.length === 1 || selectedDimension === dataKey}
                      onChange={() => onClick({ dataKey })}
                      disabled={payload.length === 1}
                    />
                  }
                  label={
                    <Typography variant="subtitle2" style={{ color }}>
                      {metricsMap[value].label}
                    </Typography>
                  }
                />
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  );
};
