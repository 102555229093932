import React, { useMemo, FC, useContext, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import workforce from 'shared/img/illustrations/workforce.png';
import john from 'shared/img/avatars/john-full.png';
import jennifer from 'shared/img/avatars/jennifer-full.png';
import jessy from 'shared/img/avatars/jessy-full.png';
import richard from 'shared/img/avatars/richard-full.png';


export const RequestEmployee: FC = () => {

  const navigate = useNavigate();
  const { type: _type = 'default' } = useParams();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const [isRequesting, setIsRequesting] = useState(false);

  const { title, subtitle, image, name, jobTitle, jobDescription } = useMemo(() => {
    const types = {
      default: {
        type: undefined,
        name: undefined,
        title: dictionary.buildYourOwn,
        subtitle: dictionary.happyToHelpYouOnYourWay,
        image: workforce,
        jobTitle: '',
        jobDescription: '',
      },
      john: {
        type: 'John',
        name: 'John',
        title: dictionary.hireEmployeeName('John'),
        subtitle: dictionary.hireEmployeeDescription,
        image: john,
        jobTitle: 'Financial Assistant',
        jobDescription: 'Finance',
      },
      jennifer: {
        type: 'Jennifer',
        name: 'Jennifer',
        title: dictionary.hireEmployeeName('Jennifer'),
        subtitle: dictionary.hireEmployeeDescription,
        image: jennifer,
        jobTitle: 'Customer Service Expert',
        jobDescription: 'Service & Support',
      },
      jessy: {
        type: 'Jessy',
        name: 'Jessy',
        title: dictionary.hireEmployeeName('Jessy'),
        subtitle: dictionary.hireEmployeeDescription,
        image: jessy,
        jobTitle: 'HR Super Star',
        jobDescription: 'Human Resources',
      },
      richard: {
        type: 'Richard',
        name: 'Richard',
        title: dictionary.hireEmployeeName('Richard'),
        subtitle: dictionary.hireEmployeeDescription,
        image: richard,
        jobTitle: 'IT Support Star',
        jobDescription: 'Information Technology',
      },
    };
    return types?.[_type as keyof typeof types] || types.default;
  }, [dictionary, _type]);

  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      name,
      job_title: jobTitle,
      job_description: jobDescription,
    },
  })

  const onSubmit = useCallback(async ({ name, ...data } : any) => {
    setIsRequesting(true);
    try {
      const [first_name, last_name] = name.split(' ');
      const mappedData = {
        ...data,
        first_name,
        last_name: last_name || '',
      }
      await digitalEmployeeClient.requestDigitalEmployee(mappedData);
      navigate(routes.requestEmployeeConfirmation);
    } catch(e) {
      console.error(e);
      genericError();
    }
    setIsRequesting(false);
  }, [navigate, genericError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box m={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
            <Typography variant="h4">{title}</Typography>
            <Typography style={{ fontWeight: 300 }}>{subtitle}</Typography>
            <Box m={6}>
              <img src={image} alt="Workforce" width="auto" height={300}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: dictionary.thisFieldIsRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={dictionary.name}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="job_title"
                  control={control}
                  rules={{ required: dictionary.thisFieldIsRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={dictionary.jobTitle}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.job_title}
                      helperText={errors?.job_title?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="job_description"
                  control={control}
                  rules={{ required: dictionary.thisFieldIsRequired }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      label={dictionary.jobDescription}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors?.job_description}
                      helperText={errors?.job_description?.message}
                      minRows={3}
                      multiline
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  endIcon={<ChevronRight/>}
                  disabled={isRequesting}
                >
                  {dictionary.submit}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}
