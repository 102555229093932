import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';

export const useDigitalEmployee = (slug: string) => {

  const { data: { data: digitalEmployee } = {}, status: digitalEmployeeStatus } = useQuery(
    [cacheKeys.getDigitalEmployee, slug],
    () => digitalEmployeeClient.getBySlug(slug),
  )

  return {
    digitalEmployee,
    digitalEmployeeStatus,
  }
}
