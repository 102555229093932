import ReactGA from 'react-ga4';


export const initializeTracking = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
};

export const trackPageview = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
}

export const trackSignUp = () => {
  ReactGA.event({
    category: 'User',
    action: 'Created an Account',
  });
}

export const trackLogIn = () => {
  ReactGA.event({
    category: 'User',
    action: 'Logged in',
  });
}
