import React, { FC, ReactNode, createContext, useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { Alert, Snackbar as MuiSnackbar, useTheme, useMediaQuery } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { ThemeOptions } from '@mui/material/styles';

import { chatFont } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { ContextProps, Mode } from './types';

//@ts-ignore
const GlobalFontFace = FontFace;

const defaultContext: ContextProps = {
  snackbarState: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
  },
  setSnackbarState: () => null,
  closeSnackbar: () => null,
  changesSaved: () => null,
  genericError: () => null,
  genericInfo: () => null,
  validationError: () => null,
  mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  setMode: () => null,
  toggleMode: () => null,
  ToggleModeIcon: Brightness4,
  isMobile: false,
  sidebarOpen: true,
  setSidebarOpen: () => null,
};


export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const [snackbarState, setSnackbarState] = useState(defaultContext.snackbarState);
  const [mode, setMode] = useState(localStorage.getItem('mode') as Mode || defaultContext.mode);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const { dictionary } = useContext(LocalizationContext);
  const { partner } = useContext(UserContext);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  }, [snackbarState]);

  const changesSaved = useCallback(() => {
    setSnackbarState({
      open: true,
      message: 'Changes saved successfully!',
      severity: 'success',
      autoHideDuration: 2000,
    });
  }, []);

  const validationError = useCallback((message: string = dictionary.oneOrMoreFieldsAreIncorrect) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'error',
      autoHideDuration: 3000,
    });
  }, [dictionary]);

  const genericError = useCallback(() => {
    setSnackbarState({
      open: true,
      message: dictionary.somethingWentWrong,
      severity: 'error',
      autoHideDuration: 2000,
    });
  }, [dictionary]);

  const genericInfo = useCallback((message: string = '') => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'success',
      autoHideDuration: 6000,
    });
  }, []);

  const toggleMode = useCallback(() => {
    setMode(mode === 'dark' ? 'light' : 'dark');
  }, [mode]);

  const ToggleModeIcon = mode === 'dark' ? Brightness7 : Brightness4;

  useEffect(() => {
    if(partner?.primaryFont) {
      const primaryFont = new GlobalFontFace('Primary', `url(${partner?.primaryFont})`);
      primaryFont.load().then((font: any) => {
        //@ts-ignore
        document.fonts.add(font);
        document.body.style.fontFamily = 'Primary';
      })
    }
    if(partner?.secondaryFont) {
      const secondaryFont = new GlobalFontFace('Secondary', `url(${partner?.secondaryFont})`);
      secondaryFont.load().then((font: any) => {
        //@ts-ignore
        document.fonts.add(font);
      })
    }
    if(partner?.chatFont) {
      const newChatFont = new GlobalFontFace(chatFont, `url(${partner?.chatFont})`);
      newChatFont.load().then((font: any) => {
        //@ts-ignore
        document.fonts.add(font);
      })
    }
  }, [partner?.primaryFont, partner?.secondaryFont, partner?.chatFont]);

  const themeFromConfig: ThemeOptions = useMemo(() => ({
    ...partner?.primaryColor && {
      palette: {
        primary: {
          main: partner?.primaryColor,
          light: partner?.primaryColor,
        },
      },
    },
    ...(partner?.primaryFont || partner?.secondaryFont) && {
      typography: {
        ...partner?.primaryFont && {
          fontFamily: 'Primary',
        },
        ...partner?.primaryFont && {
          ...['body1', 'subtitle1', 'subtitle2'].reduce((prev: any, next: any) => {
            prev[next] = { fontFamily: 'Primary' }
            return prev;
          }, {}),
        },
        ...partner?.secondaryFont && {
          ...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((prev: any, next: any) => {
            prev[next] = { fontFamily: 'Secondary' }
            return prev;
          }, {}),
        },
      },
    },
    ...partner?.primaryFont && {
      '@global': {
        body: {
          fontFamily: 'Primary',
        },
      },
    },
    ...!partner?.chatFont && {
      chatFont: theme.typography.fontFamily,
    },
  }), [partner?.primaryColor, partner?.primaryFont, partner?.secondaryFont, partner?.chatFont, theme.typography.fontFamily]);

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);



  useEffect(() => {
    if(partner?.title) {
      document.title = partner.title;
    }
  }, [partner?.title]);

  useEffect(() => {
    if(partner?.icon) {
      let icon: any = document.getElementById('icon');
      if (!icon) {
        icon = document.createElement('link');
        icon.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(icon);
      }
      icon.href = partner.icon;
      const appleIcon: any = document.getElementById('icon');
      if(appleIcon) {
        appleIcon.href = partner.icon;
      }
    }
  }, [partner?.icon])

  return (
    <LayoutContext.Provider
      value={{
        snackbarState,
        setSnackbarState,
        closeSnackbar,
        changesSaved,
        genericError,
        genericInfo,
        validationError,
        mode,
        setMode,
        toggleMode,
        ToggleModeIcon,
        isMobile,
        sidebarOpen,
        setSidebarOpen,
        themeFromConfig,
      }}
    >
      {children}
      <MuiSnackbar
        open={snackbarState.open}
        autoHideDuration={snackbarState.autoHideDuration}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
          {snackbarState.message}
        </Alert>
      </MuiSnackbar>
    </LayoutContext.Provider>
  );
};
