import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ListItemIcon,
  ListItemText,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  Grid,
  Typography,
  Tooltip,
  Badge,
  Switch,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft,
} from '@mui/icons-material';

import { featureFlags, routes } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import {
  WorkforceIcon,
  InvoicesIcon,
  HelpIcon,
  PrivacyIcon,
  OutsideIcon,
  NotificationsIcon,
  ReportsIcon,
} from 'shared/icons';
import { useDigitalEmployees } from 'shared/hooks/useDigitalEmployees';
import { Logo } from 'shared/components/layout/Logo/Logo';
import { UserMenu } from 'shared/components/layout/UserMenu/UserMenu';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { NavSearch } from './components/NavSearch/NavSearch';


const drawerWidth = '270px';

export const LoggedInLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {

  const navigate = useNavigate();
  const { mode, toggleMode, isMobile, sidebarOpen, setSidebarOpen } = useContext(LayoutContext);
  const { partner, isLoading } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const { digitalEmployees } = useDigitalEmployees();
  const [yScrollPosition, setYScrollPosition] = useState(0);

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const handleClick = useCallback((callback: any) => {
    return () => {
      callback();
      if(isMobile) {
        setSidebarOpen(false);
      }
    }
  }, [setSidebarOpen, isMobile]);

  useEffect(() => {
    const onScroll = () => {
      const position = document.body.scrollTop || document.documentElement.scrollTop;
      setYScrollPosition(position);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if(isLoading) {
    return <BodyLoading height="100vh"/>
  }

  return (
    <Box sx={{
      display: 'flex',
    }}>
      <AppBar
        position="fixed"
        sx={theme => ({
          height: '81px',
          paddingTop: '6px',
          background: 'transparent',
          boxShadow: 'none',
          color: theme.palette.mode === 'light' ? theme.palette.primary.main : '#ececec',
          [theme.breakpoints.up('sm')]: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            ...(sidebarOpen ? {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth})`,
            } : {}),
          },
        })}
      >
        <Toolbar>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: '36px',
                  marginBottom: '12px',
                  display: !isMobile && sidebarOpen ? 'none' : 'inline-flex',
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {(!sidebarOpen || isMobile) && (
                <Logo
                  style={{ marginTop: 8, marginLeft: 0 }}
                  {...isMobile && {
                    icon: true,
                    viewBox: '0 0 40 40',
                    width: '40px',
                    height: '40px',
                    style: {
                      marginTop: '16px',
                      marginLeft: '-32px',
                    },
                  }}
                />
              )}
            </Grid>
            {yScrollPosition < 50 && (
              <Grid item style={{ flexGrow: 1, textAlign: 'left' }}>
                <Box style={{ marginLeft: 16 }}>
                  <NavSearch/>
                </Box>
              </Grid>
            )}
            <Box ml="auto"/>
            {(!isMobile) && (
              <Grid item>
                <IconButton onClick={() => navigate(routes.notifications)} edge="end" style={{ width: 48, height: 48 }}>
                  <Badge color="secondary">
                    <NotificationsIcon color="primary" style={{ width: 18, height: 24 }}/>
                  </Badge>
                </IconButton>
              </Grid>
            )}
            {(!isMobile) && (
              <Grid item>
                <UserMenu/>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        {...isMobile ? {
          open: sidebarOpen,
          onClose: () => setSidebarOpen(false),
        } : {
          variant: 'permanent',
        }}
        color="primary"
        sx={theme => ({
          [theme.breakpoints.up('sm')]: {
            width: sidebarOpen ? drawerWidth : '69px',
          },
        })}
        PaperProps={{
          sx: theme => ({
            flexShrink: 0,
            whiteSpace: 'nowrap',
            background: theme.palette.mode === 'light' ? '#ffffff' : '#222222',
            color: theme.palette.primary.main,
            '& svg': {
              color: theme.palette.primary.light,
            },
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.up('sm')]: {
              width: sidebarOpen ? drawerWidth : '69px',
            },
          }),
        }}
      >
        <Box sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(2, 1, 2, 3),
          minHeight: '64px',
        })}>
          {sidebarOpen && <Logo variant="secondary"/>}
          {sidebarOpen && (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft/>
            </IconButton>
          )}
        </Box>
        <Divider style={{ marginBottom: 16, backgroundColor: '#ececec50' }}/>
        <List>
          {[
            <Tooltip title={!sidebarOpen ? dictionary.workforce : ''} key="workforce" placement="right">
              <ListItem button onClick={handleClick(() => navigate(routes.home))}>
                <ListItemIcon sx={{ marginRight: 2 }}><WorkforceIcon/></ListItemIcon>
                <ListItemText primary={dictionary.workforce}/>
              </ListItem>
            </Tooltip>,
            sidebarOpen && <Box key="digital-employees-list" maxHeight={200} overflow="auto">
              {digitalEmployees.map(({ slug, firstName }) => (
                <Tooltip title={!sidebarOpen ? firstName : ''} key={slug} placement="right">
                  <ListItem
                    button
                    key={slug}
                    onClick={handleClick(() => {
                      navigate(routes.employee(slug))
                    })}
                  >
                    <ListItemIcon sx={{ marginRight: 2 }}/>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">
                          {firstName}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Tooltip>
              ))}
            </Box>,
            (featureFlags.displayEmployeesSection && !partner) && (
              <Tooltip title={!sidebarOpen ? dictionary.employees : ''} key="employees" placement="right">
                <ListItem button onClick={handleClick(() => navigate(routes.employees))} style={{ marginTop: (digitalEmployees || []).length > 0 ? 8 : undefined }}>
                  <ListItemIcon sx={{ marginRight: 2 }}/>
                  <ListItemText primary={dictionary.employees}/>
                </ListItem>
              </Tooltip>
            ),
            <Tooltip title={!sidebarOpen ? dictionary.invoices : ''} key="invoices" placement="right">
              <ListItem button onClick={handleClick(() => navigate(routes.invoices))}>
                <ListItemIcon sx={{ marginRight: 2 }}><InvoicesIcon/></ListItemIcon>
                <ListItemText primary={dictionary.invoices}/>
              </ListItem>
            </Tooltip>,
            <Tooltip title={!sidebarOpen ? dictionary.reports : ''} key="reports" placement="right">
              <ListItem button onClick={handleClick(() => navigate(routes.reports))}>
                <ListItemIcon sx={{ marginRight: 2 }}><ReportsIcon/></ListItemIcon>
                <ListItemText primary={dictionary.reports}/>
              </ListItem>
            </Tooltip>,
          ]}
        </List>
        <Divider style={{ marginTop: 16, marginBottom: 16, backgroundColor: '#ececec50' }}/>
        <List>
          {[
            <Tooltip title={!sidebarOpen ? dictionary.help : ''} key="help" placement="right">
              <ListItem button onClick={() => window.open(partner?.help || 'https://freeday.ai/faq/', '_blank')}>
                <ListItemIcon sx={{ marginRight: 2 }}><HelpIcon/></ListItemIcon>
                <ListItemText primary={dictionary.help}/>
              </ListItem>
            </Tooltip>,
            <Tooltip title={!sidebarOpen ? dictionary.privacy : ''} key="privacy" placement="right">
              <ListItem button onClick={() => window.open(partner?.privacyPolicy || 'https://freeday.ai/privacy/', '_blank')}>
                <ListItemIcon sx={{ marginRight: 2 }}><PrivacyIcon/></ListItemIcon>
                <ListItemText primary={dictionary.privacy}/>
              </ListItem>
            </Tooltip>,
            <Tooltip title={!sidebarOpen ? (partner?.title || 'Freeday.ai') : ''} key="freeday.ai" placement="right">
              <ListItem button onClick={() => window.open(partner?.url || 'https://freeday.ai', '_blank')}>
                <ListItemIcon sx={{ marginRight: 2 }}><OutsideIcon/></ListItemIcon>
                <ListItemText primary={partner?.title || 'Freeday.ai'}/>
              </ListItem>
            </Tooltip>,
          ]}
        </List>
        <Box style={{ marginTop: 'auto' }}/>
        {sidebarOpen && (
          <Box py={2} px={2} textAlign="center">
            <Grid component="label" container alignItems="center" spacing={1} style={{ display: 'inline-flex', width: 'auto', margin: 'auto' }}>
              <Grid item><Typography variant="subtitle2">Dark</Typography></Grid>
              <Grid item>
                <Switch
                  checked={mode === 'light'}
                  onChange={toggleMode}
                  name="theme"
                  color="primary"
                />
              </Grid>
              <Grid item><Typography variant="subtitle2">Light</Typography></Grid>
            </Grid>
          </Box>
        )}
      </Drawer>
      <Box
        component="main"
        sx={theme => ({
          flexShrink: 1,
          flexGrow: 1,
          padding: theme.spacing(2),
          paddingTop: '81px',
        })}
      >
        {children}
      </Box>
    </Box>
  );
}
