import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';

import { cacheKeys } from 'config';
import { UserContext } from 'contexts';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { integrationsClient } from 'clients/integrations/integrationsClient';
import { useQueryState } from 'shared/hooks/useQueryState';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { ChatHeader } from './components/ChatHeader';
import { ChatMessages } from './components/ChatMessages';
import { ChatReply } from './components/ChatReply';

declare const navigator: any;  // This is the declaration you add


export const Chat = ({
  digitalEmployee,
  externalId,
  readOnly = false,
  showHeader = true,
}: any) => {
  const { user, partner } = useContext(UserContext);
  const [locale, setLocale] =
    useState<any>(2500);
  // @ts-ignore

  

  const [conversationsRefetchInterval, setConversationsRefetchInterval] =
    useState<any>(2500);

  useEffect(function(){
    const locale = (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator['userLanguage']
      || null;
    setLocale(locale);
  }, [])
  


  const userIdentifier = useMemo(() => {
    return externalId || user?.email || user?.firebaseUid;
  }, [externalId, user]);

  const conversationFilters = useMemo(
    () => ({
      digital_employee: digitalEmployee,
      limit: 100,
      external_id: userIdentifier,
    }),
    [digitalEmployee, userIdentifier],
  );

  const {
    data: { data: digitalEmployeeData } = {},
    isLoading: isLoadingDigitalEmployee,
  } = useQuery([cacheKeys.getDigitalEmployee, digitalEmployee], () =>
    digitalEmployeeClient.getBySlug(digitalEmployee),
  );

  const {
    data: { data: { results: conversations = [] } = {} } = {},
    isLoading: isLoadingConversations,
  } = useQuery(
    [cacheKeys.getTasks, conversationFilters],
    () => digitalEmployeeClient.getTasks(conversationFilters),
    {
      enabled: !!digitalEmployeeData?.webIntegration,
      ...(conversationsRefetchInterval && {
        refetchInterval: conversationsRefetchInterval,
      }),
    },
  );

  const [conversation = undefined] = conversations;
  const [freedayParams] = useQueryState('freeday-params');
  
  const restartConversation = useCallback(() => {
    integrationsClient.restartConversationInIframe({
      session_id: userIdentifier,
      digital_employee: digitalEmployee,
      webhook_integration_uuid: digitalEmployeeData?.webIntegration,
      locale: locale,
      extra_params: freedayParams,
    });
  }, [
    locale,
    digitalEmployee,
    userIdentifier,
    digitalEmployeeData?.webIntegration,
    freedayParams,
  ]);
  

  useEffect(() => {
    if (conversation?.id && conversationsRefetchInterval) {
      setConversationsRefetchInterval(undefined);
    }
  }, [conversation?.id, conversationsRefetchInterval]);

  if (isLoadingConversations || isLoadingDigitalEmployee) {
    return <BodyLoading height="100vh" />;
  }

  if (!digitalEmployeeData?.webIntegration) {
    return (
      <Box p={4}>
        <Typography align="center" variant="h6">
          Web Integration not Active
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flex="1 auto 1"
      height="100%"
      flexDirection="column"
      sx={{
        backgroundColor: partner?.chatBackgroundColor,
      }}
    >
      {showHeader && (
        <ChatHeader
          digitalEmployee={digitalEmployeeData}
          onRestartRequest={restartConversation}
        />
      )}
      <ChatMessages
        userIdentifier={userIdentifier}
        digitalEmployee={digitalEmployeeData}
        conversation={conversation}
        readOnly={readOnly}
        restartConversation={restartConversation}
        freedayParams={freedayParams}
      >
        {({ canReply, actions, sendMessage }) => (
          <ChatReply
            canReply={canReply}
            actions={actions}
            onSubmit={sendMessage}
          />
        )}
      </ChatMessages>
    </Box>
  );
};
