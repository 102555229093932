import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Alert,
  AlertTitle,
} from '@mui/material';

import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


import { EmployeeHeading } from './components/EmployeeHeading';

import VoiceBotEmployee from './views/VoiceBotEmployee/VoiceBotEmployee';
import EmailBotEmployee from './views/EmailBotEmployee/EmailBotEmployee';
import ChatBotEmployee from './views/ChatBotEmployee/ChatBotEmployee';


export const Employee: FC = () => {

  useScrollTop();

  // ** States
  const { slug = '' } = useParams();

  // ** Hooks
  const { digitalEmployee, digitalEmployeeStatus } = useDigitalEmployee(slug);

  const EmployeeComponent = useMemo(() => {

    if(!digitalEmployee)
      return;
      
    switch (digitalEmployee.digitalEmployeeType) {
    case 'chat_bot':
      return <ChatBotEmployee digitalEmployee={digitalEmployee} />;
    case 'email_bot':
      return <EmailBotEmployee digitalEmployee={digitalEmployee} />;
    case 'voice_bot':
      return <VoiceBotEmployee digitalEmployee={digitalEmployee} />;
  
    default:
      return null; // Something went wrong component
    }
  }, [digitalEmployee]);

  

  if (digitalEmployeeStatus === 'loading') {
    return <BodyLoading />
  }

  if (!digitalEmployee) {
    return (<Alert severity="error">
      <AlertTitle>Error</AlertTitle>Something went wrong
    </Alert>);
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item ml={1}>
        <EmployeeHeading {...digitalEmployee} />
      </Grid>
      <Grid item>
        {EmployeeComponent}
      </Grid>
    </Grid>
  )
}
