export const dictionary = {
  auth: {
    validations: {
      emailOrPasswordWrong: 'Email or password wrong',
    },

    impersonate: {
      noToken: 'Token is not present',
      invalidToken: 'Token is invalid or expired',
    },

    session: {
      logoutConfirmation: 'Are you sure you want to logout of all of your other browser sessions across all of your devices.',
      logoutConfirmationMessage: 'If you feel your account has been compromised, you should also update your password.',
    },
  },

  firebase: {
    errors: {
      'auth/account-exists-with-different-credential': 'This account already exists with a different login provider',
    },
  },

  chat: {
    header: {
      online: 'Online',
    },
  },

  dialogs: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    no: 'No',
    yes: 'Yes',
    cancel: 'Cancel',
    close: 'Close',
  },

  user: {
    delete: {
      deleteConfirmation: 'Are you sure you want to delete your account?.',
    },
  },

  home: 'Home',
  loading: 'Loading',
  results: 'Results',
  myProfile: 'My Profile',
  myAccount: 'My Account',
  subscriptions: 'Subscriptions',
  login: 'Log In',
  logOut: 'Log Out',
  signUp: 'Signup',
  fullName: 'Full Name',
  name: 'Name',
  surname: 'Surname',
  email: 'Email',
  password: 'Password',
  thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
  repeatPassword: 'Repeat Password',
  userWithThisEmailAlreadyExists: 'User with this email already exists',
  passwordsDoNotMatch: 'Passwords do not match',
  signUpWithGoogle: 'Signup with Google',
  loginWithGoogle: 'Login with Google',
  loginWithMicrosoft: 'Login with Microsoft',
  thisFieldIsRequired: 'This Field is Required',
  somethingWentWrong: 'Something went wrong!',
  oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
  open: 'Open',
  date: 'Date',
  dueDate: 'Due Date',
  dates: 'Dates',
  time: 'Time',
  duration: 'Duration',
  comingSoon: 'Coming Soon',
  toggleLighDarkMode: 'Toggle Light/Dark Mode',

  hour: 'Hour',
  hours: 'Hours',
  minutes: 'Minutes',

  resetPassword: 'Reset Password',
  reset: 'Reset',
  passwordResetEmailSent: 'Password Reset Email Sent',

  notFound: 'Not Found',

  return: 'Return',

  progress: 'Progress',
  complete: 'Complete',
  downloads: 'Downloads',


  switchToDarkMode: 'Switch to Dark Mode',
  switchToLightMode: 'Switch to Light Mode',

  tasks: 'Tasks',
  help: 'Help',

  download: 'Download',

  search: 'Search',
  notifications: 'Notifications',
  seeNotifications: 'See Notifications',

  hello: 'Hello',
  status: 'Status',

  all: 'All',
  submit: 'Submit',

  company: 'Company',
  position: 'Position',

  resetFilters: 'Reset Filters',
  type: 'Type',

  workforce: 'Workforce',
  employees: 'Employees',
  invoices: 'Invoices',
  reports: 'Reports',
  privacy: 'Privacy',

  activity: 'Activity',
  completed: 'Completed',
  hoursSaved: 'Hours Saved',

  buildYourOwn: 'Build Your Own',
  buildYourOwnDigitalEmployee: 'Build your own digital Employee',
  buildYourOwnDigitalEmployeeDescription: 'Utilize a digital employee for your own company or department.',
  activityInPeriod: (period: string) => `Activity in ${period}`,
  totalTasksCompleted: 'Total Tasks Completed',
  timeSavedInHours: 'Time Saved in Hours',
  id: 'ID',
  department: 'Department',
  jobTitle: 'Job Title',
  jobDescription: 'Job Description',
  workingSince: 'Working Since',
  traditionalEmployeeVolume: 'Traditional Employee Volume',
  capacityCap: 'Capacity Cap',
  labourCost: 'Labour Cost',
  customerContactPerson: 'Customer Contact Person',
  accountManagerFreeday: 'Account Manager Freeday',
  contractDuration: 'Contract Duration',
  completedTasks: 'Completed Tasks',
  hireMe: 'Hire Me',

  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  repeatNewPassword: 'Repeat New Password',
  logoutFromAllSessions: 'Logout from all Sessions',
  logoutFromAllSessionsDescription: 'If necessary, you may logout of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.',
  delete: 'Delete',
  accountDeleteDescription: 'Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.',

  profileInformation: 'Profile Information',
  profileInformationDescription: 'Update your account\'s profile information and email address.',

  updatePassword: 'Update Password',
  updatePasswordDescription: 'Ensure your account is using a long, random password to stay secure.',

  browserSessions: 'Browser Sessions',
  browserSessionsDescription: 'Manage and logout your active sessions on other browsers and devices.',

  deleteAccount: 'Delete Account',
  deleteAccountDescription: 'Permanently delete your account.',

  responseVariablesInformation: 'Response variables',
  responseVariablesInformationDescription: 'Update your response variables\'s information to have complete responses from bots.',
  responseVariablesEmptyInformationDescription: 'There are no variables requiring configuration',
  responseVariablesTooltip: 'Response variables',

  integrationsInformation: 'Connect',
  integrationsInformationDescription: 'Integrate your digital employee with desired platform.',
  integrationStatusConnected: 'Connected',
  integrationStatusDisconnected: 'Disconnected',
  integrationActionConnect: 'Connect',
  copyIntegrationScript: 'Copy Integration Script',

  hiIAm: (name: string) => `Hi, I'm ${name}`,
  hireEmployeeName: (name: string) => `Hire ${name}`,
  hireEmployeeDescription: 'We are happy to help you! We will prepare everything for you and contact you when John is ready.',
  happyToHelpYouOnYourWay: 'We are happy to help you on your way',

  yourAccountCannotBeDeletedAtTheMoment: 'Your account cannot be deleted at the moment. Please Contact Us.',
  comments: 'Comments',

  thankYou: 'Thank You',
  employeeRequestConfirmationText: 'We received your request. One of our agents will be reaching out to you soon with instructions on the next steps. You should have gotten a confirmation email, please remember to check your spam filter.',
  returnToHomePage: 'Return to Home Page',

  active: 'Active',
  disabled: 'Disabled',
  requested: 'Requested',
  error: 'Error',
  impersonate: {
    noToken: 'Token is not present',
    invalidToken: 'Token is invalid or expired',
  },

  analytics: 'Analytics',
  messages: 'Messages',
  conversationHistory: 'Conversation History',
  conversations: 'Conversations',
  calls: 'Calls',
  settings: 'Settings',
  integrations: 'Integrations',

  dateAgregation: 'Date Aggregation',
  exportTasks : 'Export tasks',

  showMore: 'Show more',
  showLess: 'Show less',
};
