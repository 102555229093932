import { dictionary as rootDictionary } from 'contexts/LocalizationContext/languages/en';


export const warnOnMissingTranslations = (dictionary: Partial<typeof rootDictionary>, language: string) => {
  let key: keyof typeof rootDictionary | keyof typeof dictionary;
  for(key in rootDictionary) {
    if(!dictionary.hasOwnProperty(key)) {
      console.warn(`${key} is not translated in ${language}. Will default to English.`);
    }
  }
}
