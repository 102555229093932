import React, { useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';

import { LayoutContext, LocalizationContext } from 'contexts';
import { firebaseClient } from 'clients/firebaseClient';

export const PasswordUpdateForm = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { changesSaved, genericError } = useContext(LayoutContext);

  const { handleSubmit, control, watch, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });

  const password = watch('password');

  const onSubmit = useCallback(async ({ password }: { password: string }) => {
    try {
      const user = firebaseClient.getAuth().currentUser;
      if(user) {
        await user.getIdToken(true);
        await firebaseClient.auth.updatePassword(password);
      }
      changesSaved();
    } catch(e) {
      genericError();
    }
  }, [changesSaved, genericError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="password"
            control={control}
            rules={{ required: dictionary.thisFieldIsRequired }}
            render={({ field }) =>
              <TextField
                {...field}
                error={!!errors?.repeatPassword}
                label={dictionary.newPassword}
                variant="outlined"
                type="password"
                InputLabelProps={{ shrink: true }}
                helperText={errors?.repeatPassword?.message}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="repeatPassword"
            control={control}
            rules={{
              required: dictionary.thisFieldIsRequired,
              validate: value => value !== password ? dictionary.passwordsDoNotMatch : true,
            }}
            render={({ field }) =>
              <TextField
                {...field}
                error={!!errors?.repeatPassword}
                label={dictionary.repeatNewPassword}
                variant="outlined"
                type="password"
                InputLabelProps={{ shrink: true }}
                helperText={errors?.repeatPassword?.message}
                fullWidth
              />
            }
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <LoadingButton type="submit" variant="contained" color="primary" endIcon={<Save/>} loading={isSubmitting}>
            {dictionary.submit}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}
