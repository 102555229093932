import React, { useContext } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { ProfileForm } from './components/ProfileForm';
import { PasswordUpdateForm } from './components/PasswordUpdateForm';
import { AccountDeleteForm } from './components/AccountDeleteForm';
import { BrowserSessionsForm } from './components/BrowserSessionsForm';

export const MyAccount = () => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box p={3}>
      <Grid container spacing={3}>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="span" color="primary">{dictionary.profileInformation}</Typography>
          <Typography variant="body1">{dictionary.profileInformationDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box p={4} component={Paper}>
            <ProfileForm />
          </Box>
        </Grid>

        <Grid item xs={12}><Divider /></Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="span" color="primary">{dictionary.updatePassword}</Typography>
          <Typography variant="body1">{dictionary.updatePasswordDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box p={4} component={Paper}>
            <PasswordUpdateForm />
          </Box>
        </Grid>

        <Grid item xs={12}><Divider /></Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="span" color="primary">{dictionary.browserSessions}</Typography>
          <Typography variant="body1">{dictionary.browserSessionsDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box p={4} component={Paper}>
            <BrowserSessionsForm />
          </Box>
        </Grid>

        <Grid item xs={12}><Divider /></Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="span" color="primary">{dictionary.deleteAccount}</Typography>
          <Typography variant="body1">{dictionary.deleteAccountDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box p={4} component={Paper}>
            <AccountDeleteForm />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
