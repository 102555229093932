import { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { format, isValid, startOfMonth } from 'date-fns';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { chartPalette, chartPaletteDarkMode, chartPaletteLightMode } from 'shared/styles/muiTheme';
import addDays from 'date-fns/addDays';
import { featureFlags } from 'config';
import { infiniteArrayIterator } from 'helpers/helpers';
import { ChartFilters, digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { CompletedInteractionsIcon, DropoutIcon } from 'shared/icons';
import { useDigitalEmployeeCharts } from 'shared/hooks/useDigitalEmployeeCharts';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { MultiAreaChart } from 'shared/components/charts/MultiAreaChart/MultiAreaChart';

import { pieChartData as pieChartMockData, interactionsChartData as interactionsChartMockData, categoryNames } from 'views/LoggedIn/Employee/chart.mock';
import EmployeeConversationsExport from 'views/LoggedIn/Employee/components/EmployeeConversationsExport';
import EmployeeScorecards from 'views/LoggedIn/Employee/components/EmployeeScorecards';


export const MessagesDashboard = ({ slug, chartType = 'chart' }: any) => {

  const theme = useTheme();
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [dateAggregation, setDateAggregation] = useState<ChartFilters['date_aggregation']>('day');
  const [hoveredReason, setHoveredReason] = useState<any>(undefined);
  const [isLoadingExport, setIsLoadingExport] = useState<any>(false);
  const { digitalEmployee } = useDigitalEmployee(slug);

  const chartFilters = useMemo(() => ({
    date_gte: isValid(startDate) ? format(startDate, 'yyyy-MM-dd') : '',
    date_lte: isValid(endDate) ? format(endDate, 'yyyy-MM-dd') : '',
    date_aggregation: dateAggregation,
    chart_type: chartType,
  }), [startDate, endDate, dateAggregation, chartType]);

  const { status: chartDataStatus, charts: chartData, refetch } = useDigitalEmployeeCharts({
    slug,
    ...chartFilters,
  });

  const pieChartData = useMemo(() => {
    return pieChartMockData && pieChartMockData?.values?.map(({ metrics, dimensions }: any) => ({
      value: metrics?.count,
      name: categoryNames[dimensions?.group - 1],
      scope: dimensions?.group,
      fill: chartPalette?.[(dimensions?.group - 1) as keyof typeof chartPalette],
    })).sort((prev: any, next: any) => {
      return prev?.scope > next?.scope ? 1 : -1;
    })
  }, []);

  const dropOutReasonsPieChart = useMemo(() => {
    const generator = infiniteArrayIterator(theme?.palette?.mode === 'dark' ? chartPaletteDarkMode : chartPaletteLightMode);
    return (chartData?.charts?.topDropOutReasons?.values || []).map((value: any) => ({
      ...value,
      color: generator.next().value,
    }));
  }, [theme, chartData?.charts?.topDropOutReasons]);

  const interactionsChartData = useMemo(() => {
    return {
      ...chartData?.charts?.interactions?.chart,
      values: (chartData?.charts?.interactions?.chart?.values || []).map((value: any) => ({
        ...value,
        color: 'red',
      })),
    };
  }, [chartData?.charts?.interactions?.chart]);

  const onTasksExport = useCallback(async (e: any) => {
    setIsLoadingExport(true)
    let actualEndDate = new Date();
    actualEndDate.setDate(endDate.getDate() + 1);
    const result = await digitalEmployeeClient.getTransactionsExport(
      slug,
      format(startDate, 'yyyy-MM-dd'),
      format(actualEndDate, 'yyyy-MM-dd'),
    );
    const fileURL = URL.createObjectURL(result.data);
    var fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.download = 'freeday_tasks_' + slug + '.xlsx';
    fileLink.click();
    setIsLoadingExport(false)
  }, [slug, startDate, endDate]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (chartDataStatus === 'loading') {
    return <BodyLoading />
  }


  return (
    <Grid container direction="column" spacing={2}>
      <EmployeeConversationsExport fromTab="messages" isLoadingExport={isLoadingExport} setIsLoadingExport={setIsLoadingExport} onTasksExport={onTasksExport} dateAggregation={dateAggregation} endDate={endDate} setDateAggregation={setDateAggregation} setEndDate={setEndDate} setStartDate={setStartDate} slug={slug} startDate={startDate} />
      <Grid item xs={12}>
        <Grid container spacing={2} columns={12}>
          {[
            {
              title: 'Successful Messages',
              key: 'completedInteractions',
              Icon: CompletedInteractionsIcon,
              props: { xs: 12, md: 6 },
            }, {
              title: 'Unsuccessful Messages',
              key: 'dropOutInteractions',
              Icon: DropoutIcon,
              props: { xs: 12, md: 6 },
            }].map(({ title, key, Icon, props }, i) => {

            // @ts-ignore
            const variationValue = chartData?.scorecards?.[key]?.currentPeriodValue || 0;
            // @ts-ignore
            const variationPercentileValue = chartData?.scorecards?.[key]?.changePercentage || 0;
            const isGrowth = variationValue >= 0;

            return (
              <EmployeeScorecards digitalEmployee={digitalEmployee} Icon={Icon} key={i} props={props} i={i} title={title} isGrowth={isGrowth} theme={theme} variationPercentileValue={variationPercentileValue} variationValue={variationValue} />
            )
          })}
          <Grid item xs={12} sm={6}>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardHeader
                title={
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                      Messages
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={2} wrap="nowrap">
                        {['Successful', 'Unsuccessful'].map((_type, idx) => (
                          <Grid item>
                            <Grid
                              container
                              wrap="nowrap"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <FiberManualRecord style={{ color: chartPalette[idx], width: 12, height: 12 }} />
                              </Grid>
                              <Grid item style={{ fontSize: 12, fontWeight: 400 }}>
                                {_type}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent style={{ width: '100%', minHeight: 350, height: '100%', minWidth: 200, marginTop: 'auto', marginBottom: -14 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <MultiAreaChart
                    data={interactionsChartData}
                    dateAggregation={dateAggregation}
                    showAxisX
                    showAxisY
                    showLegend={false}
                    id="InteractionsChart"
                  />
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card style={{ minHeight: '100%' }}>
              <CardHeader
                title="Drop out Reasons"
              />
              <CardContent>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={12} sm={5}>
                    <Grid container spacing={2} alignItems="center">
                      {dropOutReasonsPieChart.map(({ name, value, color }: any, i: number) => (
                        <Grid
                          item
                          xs={12}
                          key={i}
                          style={{
                            ...hoveredReason !== undefined && { opacity: hoveredReason === i ? 1 : 0.3 },
                          }}
                        >
                          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                            <Grid item>
                              <FiberManualRecord style={{ width: 12, height: 12, fill: color }} />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1" style={{ fontWeight: 400, color }}>{name}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm="auto" style={{ marginLeft: 'auto' }}>
                    {pieChartData && (
                      <ResponsiveContainer width={200} height={200}>
                        <PieChart>
                          <Pie
                            data={dropOutReasonsPieChart}
                            dataKey="value"
                            innerRadius="50%"
                            spacing={20}
                            strokeOpacity={0}
                            startAngle={90}
                            endAngle={-270}
                          >
                            {dropOutReasonsPieChart.map(({ name, value, color }: any, i: number) => (
                              <Cell
                                onMouseEnter={() => setHoveredReason(i)}
                                onMouseLeave={() => setHoveredReason(undefined)}
                                style={{
                                  cursor: 'pointer',
                                  ...hoveredReason !== undefined && { opacity: hoveredReason === i ? 1 : 0.3 },
                                }}
                                key={`cell-${i}`}
                                fill={color}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {featureFlags.npsChart && (
            <Grid item xs={12} md={6} lg={4}>
              <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardHeader
                  title={
                    <Grid container justifyContent="space-between" spacing={2}>
                      <Grid item>
                        NPS
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" spacing={2} wrap="nowrap">
                          {['Promoters', 'Detractors'].map((_type, idx) => (
                            <Grid item>
                              <Grid
                                container
                                wrap="nowrap"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <FiberManualRecord style={{ color: ['#32CD8C', '#CE4646'][idx], width: 12, height: 12 }} />
                                </Grid>
                                <Grid item style={{ fontSize: 12, fontWeight: 400 }}>
                                  {_type}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
                <CardContent style={{ width: '100%', minHeight: 200, height: '100%', minWidth: 200 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <MultiAreaChart
                      data={interactionsChartMockData}
                      dateAggregation={dateAggregation}
                      showAxisX
                      showAxisY
                      showLegend={false}
                      palette={['#32CD8C', '#CE4646']}
                      id="NPSChart"
                    />
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
