import React, { FC, useCallback, useContext, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { Save } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { dotToDash, dashToDot } from 'helpers/responseVariables';
import { digitalEmployeeClient } from 'clients/digitalEmployee/digitalEmployeeClient';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';
import { useDigitalEmployeeVariables } from 'shared/hooks/useDigitalEmployeeVariables';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

type Variable = {
  name: string;
  value: string | number;
};

const reduceVariables = (variables: Variable[]): Record<string, string | number> => variables.reduce((r, { name, value }) => {
  r[dotToDash(name)]= value;
  return r;
}, {} as Record<string, string | number>);

export const EmployeeResponseVariables: FC<any> = ({ slug, agent }) => {

  useScrollTop();
  const { genericError, changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { digitalEmployee, digitalEmployeeStatus } = useDigitalEmployee(slug);
  const { variables, variablesStatus } = useDigitalEmployeeVariables({
    slug,
    agent,
  }, {
    enabled: !!agent,
  });

  const fields: { name: string, label: string, isCreated: boolean }[] = useMemo(() => {
    const createdVariables = variables.map((v: Variable) => v.name);

    return digitalEmployee?.dialogflowCxAgent?.requiredResponseVariables.map((v) => ({
      name: dotToDash(v.name),
      label: v.displayName || v.name,
      isCreated: createdVariables.indexOf(v.name) > -1,
    })) || [];
  }, [variables, digitalEmployee])

  const { handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
    defaultValues: variables ? reduceVariables(variables) : {},
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (variables) {
      reset(reduceVariables(variables));
    }
  }, [variables, reset]);

  const onSubmit = useCallback(async (data: any) => {
    try {
      const results = await Promise.all([
        digitalEmployeeClient.updateResponseVariables(
          slug,
          agent,
          fields.filter(f => f.isCreated).map(f => ({ name: dashToDot(f.name), value: data[f.name] })),
        ),
        digitalEmployeeClient.createResponseVariables(
          slug,
          agent,
          fields.filter(f => !f.isCreated && !!data[f.name]).map(f => ({ name: dashToDot(f.name), value: data[f.name] })),
        ),
      ]);

      queryClient.invalidateQueries(cacheKeys.getDigitalEmployeeResponseVariables);
      changesSaved();
  
      return results;
    } catch(err) {
      genericError();
    }
  }, [queryClient, slug, agent, fields, changesSaved, genericError])

  if(digitalEmployeeStatus === 'loading' || variablesStatus === 'loading') {
    return <BodyLoading/>
  }

  if(!digitalEmployee || !variables) {
    return null;
  }

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      <Box component={Paper} p={2}>
        <Grid item xs={12} sm={10}>
          <Typography variant="h6" color="primary">{dictionary.responseVariablesInformation}</Typography>
          <Typography style={{ fontWeight: 300 }}>{dictionary.responseVariablesInformationDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box p={0} pt={4}>
            {!digitalEmployee.dialogflowCxAgent || digitalEmployee.dialogflowCxAgent?.requiredResponseVariables.length === 0 ? (
              <Box my={4}>
                <Typography component="span" variant="h4">{dictionary.responseVariablesEmptyInformationDescription}</Typography>
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column" spacing={2}>
                  {fields.map(({ name, label }) => (
                    <Grid item xs={12} key={name}>
                      <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label={label}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors?.[name as keyof typeof errors]}
                            helperText={errors?.[name as keyof typeof errors]?.message}
                            fullWidth
                            multiline={true}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={4} style={{ 'marginTop': '8px' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      endIcon={isSubmitting ? <CircularProgress style={{ width: 20, height: 20, color: '#fff' }}/> : <Save/>}
                      disabled={isSubmitting}
                    >
                      {dictionary.submit}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}
