import React, { useCallback, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';

import { DialogContext, LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';

export const AccountDeleteForm = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { user, logout } = useContext(UserContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { genericError, changesSaved } = useContext(LayoutContext);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      const userConfirmed = await asyncConfirmation({ title: dictionary.user.delete.deleteConfirmation });
      if (user?.id && userConfirmed) {
        await usersClient.deleteUser({
          id: user.id,
        });
        changesSaved();
        logout();
      }
    } catch(e) {
      console.error(e);
      genericError();
    }
    setIsDeleting(false);
  }, [dictionary, asyncConfirmation, changesSaved, genericError, logout, user]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 300 }}>
          {dictionary.accountDeleteDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <LoadingButton
          variant="contained"
          color="secondary"
          onClick={onDelete}
          endIcon={<Delete/>}
          loading={isDeleting}
        >
          {dictionary.delete}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
