import { warnOnMissingTranslations } from 'helpers/translationHelpers';
import { dictionary as dictionaryType } from './en';

export const nl: Partial<typeof dictionaryType> = {
  auth: {
    validations: {
      emailOrPasswordWrong: 'E-mailadres of wachtwoord verkeerd',
    },

    impersonate: {
      noToken: 'Token niet beschikbaar',
      invalidToken: 'Token is ongeldig of verlopen',
    },

    session: {
      logoutConfirmation: 'Weet je zeker dat je wilt uitloggen op al je andere browser sessie en al je apparaten?',
      logoutConfirmationMessage: 'Als je denkt dat uw account is gehackt, de moet je je wachtwoord veranderen.',
    },
  },

  firebase: {
    errors: {
      'auth/account-exists-with-different-credential': 'Dit account bestaat al bij een andere inlogprovider',
    },
  },

  chat: {
    header: {
      online: 'Online',
    },
  },

  dialogs: {
    defaultTitle: 'Weet je zeker dat je dit wilt doen?',
    defaultContent: 'Deze actie is onomkeerbar.',
    no: 'Nee',
    yes: 'Ja',
    cancel: 'Annuleren',
    close: 'Sluiten',
  },

  user: {
    delete: {
      deleteConfirmation: 'Weet je zeker dat je je account wilt verwijderen?.',
    },
  },

  home: 'Home',
  loading: 'wordt geladen',
  results: 'Resultaten',
  myProfile: 'Mijn profiel',
  myAccount: 'Mijn account',
  subscriptions: 'Abonnementen',
  login: 'Log In',
  logOut: 'Uitloggen',
  signUp: 'Aanmelden',
  fullName: 'Voor-en achternaam',
  name: 'Naam',
  surname: 'Achternaam',
  email: 'Email',
  password: 'Wachtwoord',
  thePasswordMustBeAtLeast8CharactersLong: 'Het wachtwoord moet minimaal 8 tekens lang zijn',
  repeatPassword: 'Herhaal wachtwoord',
  userWithThisEmailAlreadyExists: 'Gebruiker met dit e-mailadres bestaat al',
  passwordsDoNotMatch: 'Wachtwoorden komen niet overeen',
  signUpWithGoogle: 'Registreren met Google',
  loginWithGoogle: 'Aanmelden met Google',
  loginWithMicrosoft: 'Aanmelden met Microsoft',
  thisFieldIsRequired: 'Dit veld is verplicht',
  somethingWentWrong: 'Er is iets fout gegaan!',
  oneOrMoreFieldsAreIncorrect: 'Een of meer velden zijn onjuist.',
  open: 'Open',
  date: 'Datum',
  dueDate: 'Opleveringsdatum',
  dates: 'Data',
  time: 'Tijd',
  duration: 'Looptijd',
  comingSoon: 'Komt binnenkort',
  toggleLighDarkMode: 'Wisselen tussen licht-/donkermodus',

  hour: 'Uur',
  hours: 'Uren',
  minutes: 'Minuten',

  resetPassword: 'Wachtwoord opnieuw instellen',
  reset: 'Resetten',
  passwordResetEmailSent: 'Wachtwoord reset e-mail verzonden',

  notFound: 'Niet gevonden',

  return: 'Terug',

  progress: 'Voortgang',
  complete: 'Compleet',
  downloads: 'Downloads',


  switchToDarkMode: 'Overschakelen naar donkere modus',
  switchToLightMode: 'Overschakelen naar de lichtmodus',

  tasks: 'Taken',
  help: 'Help',

  download: 'Downloaden',

  search: 'Zoeken',
  notifications: 'Meldingen',
  seeNotifications: 'Zie meldingen',

  hello: 'Hallo',
  status: 'status',

  all: 'Alle',
  submit: 'Indienen',

  company: 'Bedrijf',
  position: 'Positie',

  resetFilters: 'Filters resetten',
  type: 'Type',

  workforce: 'Workforce',
  employees: 'Medewerkers',
  invoices: 'Facturen',
  reports: 'Rapporten',
  privacy: 'Privacy',

  activity: 'Werkzaamheid',
  completed: 'Voltooid',
  hoursSaved: 'Uren bespaard',

  buildYourOwn: 'Bouw je eigen',
  buildYourOwnDigitalEmployee: 'Bouw je eigen Digitale Medewerker',
  buildYourOwnDigitalEmployeeDescription: 'Zet een Digitale Medewerker in voor je eigen bedrijf of afdeling.',
  activityInPeriod: (period: string) => `Activiteit in ${period}`,
  totalTasksCompleted: 'Totaal aantal voltooide taken',
  timeSavedInHours: 'Tijdsbesparing in uren',
  id: 'ID',
  department: 'Afdeling',
  jobTitle: 'Functietitel',
  jobDescription: 'Functieomschrijving',
  workingSince: 'Werken sinds',
  traditionalEmployeeVolume: 'Traditioneel werknemersvolume',
  capacityCap: 'Capaciteitsdop',
  labourCost: 'Loonkosten',
  customerContactPerson: 'Klant contactpersoon',
  accountManagerFreeday: 'Accountmanager Freeday',
  contractDuration: 'Contractduur',
  completedTasks: 'Voltooide taken',
  hireMe: 'Huur me in',

  firstName: 'Voornaam',
  lastName: 'Achternaam',
  phone: 'Telefoon',
  currentPassword: 'Huidig Wachtwoord',
  newPassword: 'Nieuw Wachtwoord',
  repeatNewPassword: 'Herhaal nieuw Wachtwoord',
  logoutFromAllSessions: 'Uitloggen bij alle sessies',
  logoutFromAllSessionsDescription: 'Indien nodig kunt u op al uw apparaten uitloggen bij al uw andere browsersessies. Enkele van uw recente sessies staan hieronder vermeld; deze lijst is echter mogelijk niet volledig. Als u denkt dat uw account is gehackt, moet u ook uw wachtwoord bijwerken.',
  delete: 'Verwijderen',
  accountDeleteDescription: 'Zodra uw account is verwijderd, worden alle bronnen en gegevens permanent verwijderd. Voordat u uw account verwijdert, moet u alle gegevens of informatie downloaden die u wilt behouden.',

  profileInformation: 'Profiel informatie',
  profileInformationDescription: 'Werk de profielgegevens en het e-mailadres van uw account bij.',

  updatePassword: 'Vernieuw wachtwoord',
  updatePasswordDescription: 'Zorg ervoor dat uw account een lang, willekeurig wachtwoord gebruikt om veilig te blijven.',

  browserSessions: 'Browsersessies',
  browserSessionsDescription: 'Beheer en log uit uw actieve sessies op andere browsers en apparaten.',

  deleteAccount: 'Account verwijderen',
  deleteAccountDescription: 'Verwijder uw account definitief.',

  responseVariablesInformation: 'responsvariabele',
  responseVariablesInformationDescription: 'Pas jouw responsvariabele aan om complete antwoorden te hebben',
  responseVariablesEmptyInformationDescription: 'Er zijn geen responsvariabele die configuratie vereisen',
  responseVariablesTooltip: 'responsvariabelen',

  integrationsInformation: 'Verbind',
  integrationsInformationDescription: 'Integreer jouw digitale medewerker met een platform naar keuzen.',
  integrationStatusConnected: 'Verbonden',
  integrationStatusDisconnected: 'Verbinding verbroken',
  integrationActionConnect: 'Verbind',
  copyIntegrationScript: 'Kopieer integratie script',

  hiIAm: (name: string) => `Hallo, ik ben ${name}`,
  hireEmployeeName: (name: string) => `Huur ${name} in`,
  hireEmployeeDescription: 'Wij helpen u graag! Wij zetten alles voor je klaar en nemen contact met je op als John er klaar voor is.',
  happyToHelpYouOnYourWay: 'We helpen je graag op weg',

  yourAccountCannotBeDeletedAtTheMoment: 'Uw account kan momenteel niet worden verwijderd. Gelieve ons te contacteren',
  comments: 'Opmerkingen',

  thankYou: 'Bedankt',
  employeeRequestConfirmationText: 'We hebben uw verzoek ontvangen. Een van onze agenten zal binnenkort contact met u opnemen met instructies voor de volgende stappen. U zou een bevestigingsmail moeten hebben ontvangen, vergeet niet uw spamfilter te controleren.',
  returnToHomePage: 'Keer terug naar de start pagina',

  active: 'Actief',
  disabled: 'Uitgeschakeld',
  requested: 'Aangevraagd',
  error: 'Error',
  impersonate: {
    noToken: 'Token is niet aanwezig',
    invalidToken: 'Token is ongeldig of verlopen',
  },

  analytics: 'Analytics',
  messages: 'Berichten',
  conversationHistory: 'Converstatie Geschiedenis',
  conversations: 'Conversaties',
  calls: 'Telefoon gesprekken',
  settings: 'Instellingen',
  integrations: 'Integraties',

  dateAgregation: 'Datum aggregatie',
  exportTasks : 'Taken exporteren',

  showMore: 'Meer opties',
  showLess: 'Minder opties',
};


export const dictionary = {
  ...dictionaryType,
  ...nl,
}


warnOnMissingTranslations(nl, 'nl');
