import { MaybeNull } from 'types';

export enum Dialog {};

export type DialogOptions = {};

export type ContextProps = {
  openDialog: (dialog: Dialog, options?: DialogOptions) => any;
  closeDialog: (dialog: Dialog) => any;
  asyncConfirmation: AsyncConfirmation;
};

export type AsyncDialogProps = {
  show?: boolean;
  title?: MaybeNull<React.ReactNode>;
  content?: MaybeNull<React.ReactNode>;
  confirmLabel?: MaybeNull<React.ReactNode>;
  cancelLabel?: MaybeNull<React.ReactNode>;
}

export type AsyncConfirmation = (props: AsyncDialogProps) => Promise<boolean>;
 