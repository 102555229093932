export function* infiniteArrayIterator(array: any[]) {
  let n = 0;
  while (true) {
    if(n < array.length) {
      n++;
      yield array[n - 1];
    } else {
      n = 0;
    }
  }
}

export const percentageChange = (a: number, b: number) => {
  let percent;
  if(a !== 0) {
    percent = (a - b) / a * 100;
  } else {
    if (b !== 0) {
      percent = -100;            
    } else {
      percent = 0;
    }
  }       
  return percent;
}
