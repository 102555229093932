import React, { FC, useCallback, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Code, FileCopy } from '@mui/icons-material';

import { featureFlags } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { integrationsClient } from 'clients/integrations/integrationsClient';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useDigitalEmployee } from 'shared/hooks/useDitigalEmployee';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const EmployeeIntegrations: FC<any> = ({ slug }) => {

  useScrollTop();
  const theme = useTheme();
  const { dictionary } = useContext(LocalizationContext);
  const { setSnackbarState } = useContext(LayoutContext);
  const { digitalEmployee, digitalEmployeeStatus } = useDigitalEmployee(slug);

  const iconColor = useMemo(() => {
    return theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black;
  }, [theme])

  const isSlackConnected = useMemo(() => {
    return digitalEmployee?.activeIntegrations.includes('slack')
  }, [digitalEmployee])

  const onIntegrationConnect = useCallback(async (integration: any) => {
    if(digitalEmployee?.slug) {
      const integrationLinkResponse = await integrationsClient.getDigitalEmployeeIntegrationLink(digitalEmployee.slug, integration)
      window.open(integrationLinkResponse.data.redirect_uri, '_blank')
    }
  }, [digitalEmployee]);

  const webWidgetIntegrationScript = useMemo(() => {
    if(digitalEmployee?.webIntegration) {
      return `<script id="freeday-script" src="${window.location.origin}/chat-widget.js?employee=${slug}" async></script>`;
    }
  }, [slug, digitalEmployee]);

  const onCopy = useCallback(() => {
    if(webWidgetIntegrationScript) {
      navigator.clipboard.writeText(webWidgetIntegrationScript).then(function() {
        setSnackbarState({
          open: true,
          message: 'Integration Script copied to Clipboard',
          severity: 'success',
          autoHideDuration: 2000,
        });
      }, (e: any) => {
        console.error(e);
        setSnackbarState({
          open: true,
          message: 'Could not copy script to clipboard',
          severity: 'error',
          autoHideDuration: 2000,
        });
      });
    }
  }, [webWidgetIntegrationScript, setSnackbarState]);

  if(digitalEmployeeStatus === 'loading') {
    return <BodyLoading/>
  }

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      <Box component={Paper} p={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">{dictionary.integrationsInformation} {digitalEmployee?.firstName}</Typography>
          <Typography style={{ fontWeight: 300 }}><small>{dictionary.integrationsInformationDescription}</small></Typography>
          <br/>
          {featureFlags.isSlackIntegrationEnabled &&
            <Paper style={{ borderColor: isSlackConnected ? '#4DA83F' : '' }}  variant="outlined" elevation={0}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <img src="/slack.svg" alt="Slack" width={35} height={35} />
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        Slack
                      </Grid>
                      <Grid item xs={12}>
                        <span style={{ color: isSlackConnected ? '#4DA83F' : iconColor }}>{ isSlackConnected ? dictionary.integrationStatusConnected : dictionary.integrationStatusDisconnected }</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box ml="auto"/>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onIntegrationConnect('slack')}
                      disabled={isSlackConnected}
                    >
                      {dictionary.integrationActionConnect}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          }
          {digitalEmployee?.webIntegration && (
            <Paper style={{ borderColor: '#4DA83F', marginTop: 16 }}  variant="outlined" elevation={0}>
              <Box p={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Code fontSize="large"/>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        Web Widget
                      </Grid>
                      <Grid item xs={12}>
                        <span style={{ color: '#4DA83F' }}>{dictionary.integrationStatusConnected}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box ml="auto"/>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onCopy}
                      endIcon={<FileCopy/>}
                    >
                      {dictionary.copyIntegrationScript}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}
