import { camelCase } from 'lodash';
import { GenericCallsCharts, GenericCharts } from './digitalEmployeeClient.types';

export const reformatCharts = (chart: GenericCharts): GenericCharts => ({
  ...chart,
  charts: {
    ...chart.charts,
    interactions: {
      ...chart.charts.interactions,
      chart: {
        ...chart.charts.interactions.chart,
        dimensions: chart.charts.interactions.chart.dimensions.map((d: any) => ({
          ...d,
          key: camelCase(d.key),
        })),
      },
    },
    totalSavings: {
      ...chart.charts.totalSavings,
      chart: {
        ...chart.charts.totalSavings.chart,
        dimensions: chart.charts.totalSavings.chart.dimensions.map((d: any) => ({
          ...d,
          key: camelCase(d.key),
        })),
      },
    },
  },
});

export const reformatCallsCharts = (chart: GenericCallsCharts): GenericCallsCharts => { 
  return ({
    ...chart,
    charts: {
      ...chart.charts,
      accumulativeConversations: {
        ...chart.charts.accumulativeConversations,
        chart: {
          ...chart.charts.accumulativeConversations?.chart,
          dimensions: chart?.charts.accumulativeConversations.chart?.dimensions.map((d: any) => ({
            ...d,
            key: camelCase(d.key),
          })),
          values: chart.charts.accumulativeConversations.chart.values,
        },
      },
      statusAccumulativeInteractions: {
        ...chart.charts.statusAccumulativeInteractions,
        chart: {
          ...chart.charts.statusAccumulativeInteractions?.chart,
          dimensions: chart.charts.statusAccumulativeInteractions.chart.dimensions.map((d: any) => ({
            ...d,
            key: camelCase(d.key),
          })),
          values: chart.charts.statusAccumulativeInteractions.chart.values,
        },
      },
    },
  })
};
