import { Maybe } from 'types';
import { request } from 'clients/baseClient';

const integrationsApiBaseUrl = process.env.REACT_APP_INTEGRATIONS_API_URL

const getDigitalEmployeeIntegrationLink = (slug: string, integration: string) => {
  return request({
    options: {
      url: `${integrationsApiBaseUrl}/${integration}/install/`,
      method: 'GET',
      maxRedirects: 0,
      withCredentials: true,
      params: {
        'digital_employee': slug,
      },
    },
  });
};

const sendWebChatMessage = (webhookIntegrationId: any, freedayParams: Maybe<string>, data: any) => {
  return request({
    options: {
      url: `${integrationsApiBaseUrl}/webhooks/${webhookIntegrationId}/`,
      method: 'POST',
      data,
      headers: {
        'freeday-params': freedayParams ? freedayParams : '{}',
      },
    },
  });
};


const getMemberInfo = (externalChatMeta: any, memberId: any) => {
  return request({
    options: {
      url: `${integrationsApiBaseUrl}/genesys/get-member`,
      method: 'POST',
      data: { external_chat_meta: externalChatMeta, member_id: memberId },
    },
  });
};

const restartConversationInIframe = (data: any) => {
  return request({
    options:{
      url: `${integrationsApiBaseUrl}/restart-conversation/`,
      method:'POST',
      data,
    },
  })
}

const getCallsExport = (params: { slug: string; startDate: string; endDate: string }) => {
  return request({
    options: {
      url: `${integrationsApiBaseUrl}/twilio/export-logs/`,
      method: 'GET',
      params: {
        started_at__gte: params.startDate,
        started_at__lte: params.endDate,
        digital_employee: params.slug,
      },
      responseType: 'blob',
    },
  });
};

export const integrationsClient = {
  getDigitalEmployeeIntegrationLink,
  sendWebChatMessage,
  getMemberInfo,
  restartConversationInIframe,
  getCallsExport,
};
