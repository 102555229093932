import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip as MuiTooltip, useTheme } from '@mui/material';
import { LayersClear, ZoomOutMap } from '@mui/icons-material';
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { format, set } from 'date-fns';

import { chartPalette } from 'shared/styles/muiTheme';
import { LayoutContext } from 'contexts';

import { Tooltip } from 'shared/components/charts/ChartTooltip/ChartTooltip';
import { CustomLegend } from 'shared/components/charts/ChartLegend/ChartLegend';

const defaultZoomState = {
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax+1',
  bottom: 'dataMin-1',
  animation: true,
};

export const MultiAreaChart: FC<any> = (props) => {

  const theme = useTheme();
  const {
    id = 'Chart',
    data: { values = [], metrics = [], dimensions = [], series = [] } = {},
    showLegend = true,
    showAxisX,
    showAxisY,
    enableZoom,
    highlightedDimension = undefined,
    setHighlightedDimension = (value: any) => value,
    setDimension = (value: any) => value,
    onZoomChange = () => null,
    yAxisLabelFormatter = (value: any, index: number) => value,
    xAxisLabelFormatter = (value: any, index: number) => {
      if(value === 'dataMax' || value === 'dataMin') {
        return '';
      }
      try {
        const [year, month = 1, day = 1] = value.split('-');
        const date = set(new Date(), { year: parseInt(year), month: parseInt(month) - 1, date: parseInt(day) });
        if(props?.dateAggregation === 'day') {
          return format(date, ' dd MMM yy ');
        }
        if(props?.dateAggregation === 'month') {
          return format(date, ' MMM yy ');
        }
        if(props?.dateAggregation === 'year') {
          return format(date, ' yyyy ');
        }
      } catch(e) {
        console.error(e);
      }
      return value;
    },
    enableClick = true,
    showGrid = true,
    palette = chartPalette,
  } = props || {};

  const { mode } = useContext(LayoutContext);

  const [selectedDimension, setSelectedDimension] = useState<any>(null);
  const [zoomState, setZoomState] = useState<any>(defaultZoomState);
  const [zoomStart, setZoomStart] = useState<number|null>(null);
  const [zoomEnd, setZoomEnd] = useState<number|null>(null);

  useEffect(() => {
    setSelectedDimension(null)
  }, [values]);

  const {
    left,
    right,
    refAreaLeft,
    refAreaRight,
  } = zoomState;

  const zoomOut = useCallback(() => {
    setZoomStart(null);
    setZoomEnd(null);
    setZoomState(defaultZoomState);
    onZoomChange({ from: null, to: null })
  }, [onZoomChange]);

  const zoom = useCallback(() => {
    const { start, end, refAreaLeft, refAreaRight } = zoomState;
    if(start && end) {
      if(onZoomChange) {
        onZoomChange({ from: refAreaLeft , to: refAreaRight });
        setZoomStart(null);
        setZoomEnd(null);
        setZoomState(defaultZoomState);
      } else {
        setZoomStart(start < end ? start : end);
        setZoomEnd((start < end ? end : start) + 1);
        setZoomState(((prevState: any) => ({
          ...prevState,
          refAreaLeft: '',
          refAreaRight: '',
          left: refAreaLeft,
          right: refAreaRight,
        })));
      }
    }
  }, [zoomState, onZoomChange]);

  const onLegendClick = useCallback((e: any) => {
    const { dataKey } = e;
    setSelectedDimension((prevState: any) => prevState === dataKey ? null : dataKey);
  }, []);

  const opacity = useCallback((key: any) => {
    return selectedDimension === key || selectedDimension === null ? 1 : 1;
  }, [selectedDimension]);

  const aggregateBy = useMemo(() => {
    return dimensions.find((dimension: any) => !!dimension?.aggregation?.key)
  }, [dimensions]);

  const seriesMap = useMemo(() => series.reduce((prev: any, next: any) => {
    prev[next.db_value] = next;
    return prev;
  }, {} as any), [series])

  const mappedMetrics = useMemo(() => {
    let _metrics = metrics;
    if(aggregateBy) {
      _metrics = [
        // @ts-ignore
        ...[...new Set(values.map(({ dimensions }: any) => dimensions[aggregateBy.aggregation.key]))].map((key: any) => ({
          key: key,
          label: `${metrics[0].label} ${seriesMap[key]?.label || key}`,
          // @ts-ignore
          chart: aggregateBy.aggregation.chart,
          opacity: highlightedDimension ? (highlightedDimension === key ? 1 : 0.3) : 1,
          ...(metrics[0].stack_id && { stack_id: metrics[0].stack_id }),
          color: seriesMap[key]?.color,
        })),
      ];
    }
    return _metrics
      .map((metric: any) => {
        const mappedMetric = {
          color: palette?.[(metric?.key - 1) as keyof typeof palette],
          ...metric,
        }
        return mappedMetric;
      })
      .sort((a: any, b: any) => parseInt(a.key) > parseInt(b.key) ? 1 : -1 )
  }, [metrics, aggregateBy, values, seriesMap, highlightedDimension, palette]);

  const dimensionsMap = useMemo(() => {
    return dimensions.reduce((map: any, dimension: any) => { map[dimension.key] = dimension; return map; }, {} as any);
  }, [dimensions]);

  const metricsMap = useMemo(() => {
    return mappedMetrics.reduce((map: any, metric: any) => { map[metric.key] = metric; return map; }, {} as any);
  }, [mappedMetrics]);

  const { xAxis, yAxis } = useMemo(() => {
    type axisProps = {
      key: string;
      type: 'category' | 'number'
    }
    let xAxis: axisProps = { key: dimensions?.[0]?.key, type: 'category' };
    let yAxis: undefined | axisProps = undefined;
    Object.keys(dimensionsMap).forEach(key => {
      if(dimensionsMap[key]?.type === 'date') {
        xAxis = { key, type: 'category' };
      }
      if(dimensionsMap[key]?.axis) {
        if(dimensionsMap[key]?.axis === 'x') {
          xAxis = { key, type: 'category' };
        }
        if(dimensionsMap[key]?.axis === 'y') {
          yAxis = { key, type: 'category' };
        }
      }
    });
    Object.keys(metricsMap).forEach(key => {
      if(metricsMap[key]?.axis) {
        if(metricsMap[key]?.axis === 'x') {
          xAxis = { key, type: 'number' };
        }
        if(metricsMap[key]?.axis === 'y') {
          yAxis = { key, type: 'number' };
        }
      }
    });
    return { xAxis, yAxis }
  }, [dimensionsMap, metricsMap, dimensions]);

  const mappedData = useMemo(() => {
    let _values = values.map((value: any) => value);
    if(aggregateBy) {
      const aggregateValueKeys: any = [...new Set(values.map((value: any) => value.dimensions[aggregateBy.key]))].reduce((obj: any, key: any) => {
        obj[key] = {};
        return obj;
      }, {});
      values.forEach((value: any) => {
        // @ts-ignore
        aggregateValueKeys[value.dimensions[aggregateBy.key]][value.dimensions[aggregateBy.aggregation.key]] = value.metrics[metrics[0].key]
      })
      _values = Object.keys(aggregateValueKeys).map((key: string) => ({
        dimensions: {
          [aggregateBy.key]: key,
        },
        metrics: aggregateValueKeys[key],
      }))
    }
    const data = _values.map(({ metrics, dimensions }: any) => ({
      ...Object.keys(metrics).reduce((filteredMetrics, key) => {
        // TODO: Handle String Integers
        // eslint-disable-next-line
        if(selectedDimension === null || selectedDimension == key) {
          filteredMetrics[key] = (typeof metrics[key] === 'number') ? Math.trunc(metrics[key]) : metrics[key];
        }
        return filteredMetrics;
      }, {} as any),
      ...dimensions,
    }));
    if(zoomStart !== null && zoomEnd !== null) {
      return data.slice(zoomStart, zoomEnd);
    }
    return data;
  }, [zoomStart, zoomEnd, selectedDimension, values, aggregateBy, metrics]);

  return (
    <div key={mode} style={{ position: 'relative', width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          margin={{ top: 0, left: 0, right: -15, bottom: -10 }}
          style={{ userSelect: 'none' }}
          data={mappedData}
          onMouseDown={enableZoom ? (e: any) => {
            if(enableZoom && e) {
              setZoomState((prevState: any) => ({
                ...prevState,
                start: e.activeTooltipIndex,
                refAreaLeft: e.activeLabel,
              }))
            }
          } : undefined}
          onMouseMove={enableZoom ? (e: any) => {
            if(enableZoom && e && zoomState.refAreaLeft) {
              setZoomState((prevState: any) => ({
                ...prevState,
                end: e.activeTooltipIndex,
                refAreaRight: e.activeLabel,
              }));
            }
          } : undefined}
          onMouseUp={enableZoom ? zoom : undefined}
        >
          <defs>
            {((mappedMetrics || []).length > 0 ? mappedMetrics : [{}]).map(({ color = theme.palette.primary.light }: any, i: number) => {
              return (
                <linearGradient key={i} id={`${id}-color${i}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={color} stopOpacity={1}/>
                  <stop offset="100%" stopColor={color} stopOpacity={0.1}/>
                </linearGradient>
              )
            })}
          </defs>
          {showGrid && <CartesianGrid horizontal={true} vertical={false} strokeOpacity={0.5}/>}
          <XAxis
            domain={[left, right]}
            dataKey={xAxis.key}
            type={xAxis.type as 'number' | 'category'}
            allowDataOverflow={true}
            hide={!showAxisX}
            tickFormatter={xAxisLabelFormatter}
          />
          <ReTooltip
            content={(props: any) => (
              <Tooltip
                {...props}
                dimensionsMap={dimensionsMap}
                metricsMap={metricsMap}
                xAxisLabelFormatter={xAxisLabelFormatter}
              />
            )}
          />
          {mappedMetrics.map(({ chart, key, color = theme.palette.primary.light, stack_id, opacity: _opacity }: any, i: number) => {
            if(chart === 'bar') {
              return (
                <Bar
                  style={{ cursor: enableClick ? 'pointer' : undefined }}
                  yAxisId="1"
                  key={i}
                  dataKey={key}
                  stroke={color}
                  strokeOpacity={0}
                  fillOpacity={_opacity || opacity(key)}
                  fill={!stack_id ? `url(#${id}-color${i})` : color}
                  radius={(i === (mappedMetrics?.length - 1)) ? [4,4,0,0] : undefined}
                  animationDuration={300}
                  stackId={stack_id}
                  onMouseEnter={() => setHighlightedDimension(key)}
                  onMouseLeave={() => setHighlightedDimension(undefined)}
                  onClick={() => {
                    setDimension(selectedDimension?.toString() === key?.toString() ? undefined : key);
                    setHighlightedDimension(undefined);
                  }}
                />
              )
            } else if(chart === 'line' || chart.includes('line-')) {
              return (
                <Line
                  yAxisId="1"
                  key={i}
                  type="monotone"
                  dataKey={key}
                  stroke={color}
                  strokeDasharray={chart === 'line-dashed' ? 3 : undefined}
                  animationDuration={300}
                  dot={chart === 'line-dashed' ? false : undefined }
                  connectNulls
                />
              )
            } else if(chart === 'area') {
              return (
                <Area
                  yAxisId="1"
                  key={i}
                  type="monotone"
                  dataKey={key}
                  stroke={color || theme.palette.primary.main}
                  strokeOpacity={opacity(key)}
                  fillOpacity={opacity(key)}
                  fill={`url(#${id}-color${i})`}
                  animationDuration={300}
                  stackId={stack_id}
                  connectNulls
                />
              )
            } else {
              return null;
            }
          })}
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
          {showLegend && (
            <Legend
              verticalAlign="top"
              onClick={onLegendClick}
              content={(
                <CustomLegend
                  selectedDimension={selectedDimension}
                  metricsMap={metricsMap}
                />
              )}
            />
          )}
          <YAxis
            yAxisId="1"
            orientation="right"
            allowDataOverflow={true}
            hide={!showAxisY}
            style={{ pointerEvents: 'none' }}
            tickFormatter={yAxisLabelFormatter}
            tickLine={false}
            axisLine={false}
            // mirror
            // @ts-ignore
            enableBackground
            {...(yAxis ? {
              // @ts-ignore
              dataKey: yAxis.key,
              // @ts-ignore
              type: yAxis.type as 'number' | 'category',
            } : { })}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div style={{
        position: 'absolute',
        zIndex: 1000,
        ...(showLegend ? ({
          top: 12,
          right: 12,
        }) : ({
          top: 12,
          right: 12,
        })),
      }}>
        {zoomStart !== null && (
          <MuiTooltip title="Clear Zoom">
            <IconButton color="primary" onClick={() => zoomOut()} style={{ margin: 10 }}>
              <ZoomOutMap/>
            </IconButton>
          </MuiTooltip>
        )}
        {selectedDimension !== null && (
          <MuiTooltip title="Clear Filters">
            <IconButton color="primary" onClick={() => setSelectedDimension(null)} style={{ margin: 10 }}>
              <LayersClear/>
            </IconButton>
          </MuiTooltip>
        )}
      </div>
    </div>
  );
}
